.bd-header-list {
    display: none;
    background: #ffff;
    padding: 20px;
    color: #000;
    margin-left: 20px;
}

.bd {
    margin-left: 20px;
}