.userList_wrapper {
    overflow-y: scroll;
    overflow-x: hidden;
    // height: calc(100vh - );

    &::-webkit-scrollbar {
        display: none;
    }

    h3 {
        font-size: 19px;
        color: #000;
        padding: 0px 16px 10px 16px;
        margin: 0;
    }

    // .ant-tabs .ant-tabs-content-holder {
    //     flex: auto;
    //     min-width: 0;
    //     min-height: 0;
    //     height: 80vh;
    //     overflow: scroll;
    // }

    .ant-collapse {
        background-color: #fff !important;
        border: none !important;
        border-bottom: 0;
        border-radius: 0 !important;
    }

    .ant-collapse-header {
        border-radius: 0 !important;

        .ant-collapse-expand-icon {
            padding-inline-end: 0 !important;
        }

        .ant-collapse-arrow {
            position: absolute;
            right: 30px;
        }
    }

    .ant-collapse-item {
        border-bottom: 0 !important;
    }

    .ant-collapse-header-text {
        font-size: 19px;
    }

    .ant-collapse-content-box {
        padding: 5px !important;
        margin-top: 10px !important;
    }

    .ant-divider {
        border-block-start: 2px solid rgba(5, 5, 5, 0.06);
    }

    .ant-divider-horizontal {
        margin: 0 0 22px 0 !important;
    }

    .userItem {
        display: flex;
        align-items: center;
        gap: 12px;
        padding: 15px;
        border-bottom: 1px solid $border;
        cursor: pointer;
        transition: .3s ease-in-out;
        position: relative;

        .pointOnline {
            position: absolute;
            width: 10px;
            height: 10px;
            border-radius: 50px;
            background: green;
            right: -1px;
            top: 30px;
        }

        &:hover {
            background: rgba(239, 239, 239, 0.31);
        }

        &.active {
            border-left: 3px solid $primary;
            background: rgb(249 233 222 / 31%);
        }

        img {
            width: 45px;
            height: 45px;
            border-radius: 50%;
            object-fit: cover;
        }

        .online_profile {
            padding: 3px;
            border: 2px solid green;
        }

        .det {
            flex: 1;
        }

        .name {
            margin-bottom: 8px;
            display: flex;
            align-items: center;
            justify-content: space-between;

            span {
                font-size: 15px;
                font-weight: 600;
                color: $Text-dark;
            }


            .date_time {
                font-size: 11px;
                font-weight: 400;
                margin-right: 10px;
                color: $text-light;
            }
        }

        .last_msg_tym {
            display: flex;
            justify-content: space-between;
            align-items: center;

            span {
                font-size: 15px;
                font-weight: 600;
                color: $Text-dark;
            }

            .time {
                color: $white;
                font-size: 11px;
                width: 20px;
                height: 20px;
                border-radius: 50px;
                background: #ff7043;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-right: 11px;
            }
        }

        .last_group_tym {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            gap: 7px;

            span {
                font-size: 15px;
                font-weight: 600;
                color: $Text-dark;
            }

            .date_time {
                font-size: 13px;
                font-weight: 400;
                margin-right: 8px;
                color: #717171;
            }
        }

        .lastMesg {
            font-size: 13px;
            color: $text-light;
            font-weight: 400;
        }
    }

    .lastItem {
        border-bottom: none;
    }
}

// .pointOnline {
//     position: absolute;
//     width: 10px;
//     height: 10px;
//     border-radius: 50px;
//     background: green;
//     right: 1px;
//     top: 25px;
// }


.ant-tabs .ant-tabs-content-holder {
    flex: auto;
    min-width: 0;
    min-height: 0;
    height: 80vh;
    overflow: scroll;
}