@use "../../variables" as var;

.header {
    background-color: white;
    // border-bottom: 1px solid var.$secondary-200;
    height: 80px;
    display: flex;
    position: sticky;
    padding: 0px 22px !important;
    top: 0;
    left: 0;
    align-items: center;
    z-index: 999;

    .collapsedBtn {
        cursor: pointer;
        transition: all .3s;
        margin-right: 25px;
    }

    .bid-avatar {
        img {
            // object-fit: contain;
        }
    }

    .ot-btn {
        border: 1px solid black;
        border-radius: 6px;
        padding: 6px;
        display: flex;
        gap: 8px;
        align-items: center;
        cursor: pointer;

        span {
            font-size: 22px;
            font-weight: 300;
        }
    }

    .headerDeyt {
        display: flex;
        align-items: center;
        gap: 20px;

        small {
            font-size: 15px;
            color: var.$secondary-600;
            font-weight: 400;
        }

        h4 {
            margin-top: 5px;
            display: flex;
            align-items: center;
            gap: 10px;
            font-size: 20px;
            padding-right: 20px;
            border-right: 1px solid var.$secondary-200;

            span {
                font-size: 15px;
                color: var.$secondary-600;
            }
        }
    }

    .pageSearch {
        .ant-select {
            height: 52px;

            * {
                height: 100%;
            }
        }

        .ant-select-selector {
            padding: 0 !important;
        }

        button {
            color: black;
            background: white;
        }

        input {
            border: none !important;
            padding-left: 16px;
        }
    }
}

.new-breadcrumb {
    background-color: white;
    position: sticky;
    top: 80px;
    left: 0px;
    z-index: 99;
    border-bottom: 1px solid var.$secondary-200;

    .breadcrumb {
        margin-top: 0px !important;
        background-color: white !important;

    }
}