.bd_profile_main {
    background: $background;
    // padding: 30px 60px;

    p {
        margin-bottom: 0 !important;
    }

    .bd_main_profile_headings {
        color: var(--black, #000);
        font-size: 26px;
        font-style: normal;
        font-weight: 600;
        line-height: 100%;
        margin-bottom: 30px;
    }

    .bd_main_add {
        width: 100%;
        display: flex;
        background: #fff;
        // border-radius: 8px;
        // box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.07);

        .bd_main_sub_add_profile {
            width: 40%;
            display: flex;
            padding: 30px;
            padding-bottom: 20px;
            align-items: center;
            gap: 40px;
            position: relative;

            .line_through {
                position: absolute;
                left: 0;
                right: 0;
                width: 100%;
                height: 1px;
                background: $border;
                top: 46%;
            }

            .bd_main_sub_image {
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 20px;
                position: relative;
                z-index: 1;


                .input_update_file {
                    display: none;
                    cursor: pointer;
                    opacity: 0;
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                }

                .bd_main_sub_s_img {
                    width: 140px;
                    height: 140px;
                    border-radius: 200px;
                    border: 3px solid var(--border, #DADADA);
                    background-color: white;
                }

                .bd_main_update_profile_btn {
                    font-size: 14px;
                    padding: 15px 15px;
                    border-radius: 8px;
                    background: var(--white, #FFF);
                    box-shadow: 0px 1px 0px 0px #E3E3E3 inset, 0px -2px 0px 0px #E3E3E3 inset, 1px 0px 0px 0px #E3E3E3 inset, -1px 0px 0px 0px #E3E3E3 inset, 0px -1px 0px 0px #B5B5B5 inset;
                    cursor: pointer;
                    transition: .2s ease-in-out;

                    &:hover {
                        background: $background;
                    }
                }
            }

            .bd_main_sub_info {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: 30px;
                width: 100%;


                .sub_emp_id {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    gap: 16px;

                    .sub_emp_head {
                        color: var(--black, #000);
                        font-size: 18px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 100%;
                    }

                    .sub_id {
                        color: var(--text-grey, #636363);
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 100%;
                    }
                }

                .sub_contact {
                    display: flex;
                    padding-top: 30px;
                    flex-direction: column;
                    align-items: flex-start;
                    gap: 15px;
                    align-self: stretch;

                    .sub_pera {
                        color: var(--text-grey, #303030);
                        font-size: 15px;
                        font-weight: 400;
                        line-height: 100%;
                        display: flex;
                        width: 100%;
                        gap: 20px;
                        align-items: center;
                    }

                    .sub_span {
                        color: var(--Text-dark, #636363);
                        font-size: 15px;
                        font-weight: 400;
                        display: block;
                        width: 100px;
                    }
                }
            }
        }

        .bd_main_sub_add_details {
            width: 60%;
            border-left: 1px solid var(--border-color, #F2F2F2);
            display: flex;
            flex-direction: column;

            .add_details_headings {
                display: flex;
                padding: 18px 20px;
                justify-content: space-between;
                align-items: flex-start;
                align-self: stretch;
                border-bottom: 1px solid var(--border-color, #F2F2F2);
                background: var(--background, #FCFCFC);

                .add_details_p {
                    color: var(--Text-dark, #303030);
                    font-size: 15px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 17px;
                    letter-spacing: 0.6px;
                    text-transform: uppercase;
                    margin-bottom: 0 !important;
                }

                .add_details_span {
                    color: var(--text-grey, #636363);
                    font-size: 15px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 16px;
                    letter-spacing: 1px;
                }
            }

            .bd_main_sub_add_company {
                display: flex;
                padding: 40px 35px;
                align-items: center;
                gap: 30px;
                align-self: stretch;
                border-bottom: 1px solid var(--border-color, #F2F2F2);
            }

            .bd_main_company_logo Img {
                width: 80px;
                height: 78.537px;
                background-size: cover;
            }

            // <----------------tabs css ------------------->
            .ant-tabs-tab {
                padding: 20px 26px !important;
            }

            #rc-tabs-0-tab-2 {
                color: var(--text-grey, #636363);
                font-size: 20px;
                font-style: normal;
                font-weight: 500;
                line-height: 100%;
            }

            .bd_main_sub_add_company_name {

                h3 {
                    color: var(--black, #000);
                    font-size: 18px;
                    font-weight: 500;
                    margin-bottom: 15px;
                }

                p {
                    color: var(--text-grey, #636363);
                    font-size: 16px;
                    line-height: 100%;
                    margin-bottom: 10px !important;
                }

                span {
                    color: var(--Text-dark, #303030);
                    font-size: 17px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 100%;
                }

            }

            .bd_main_subscription {
                width: 100% !important;
                display: flex;
                justify-content: space-between;
                align-items: center;
                background: $background;

                &>* {
                    flex: 1;
                }

                .start_date {
                    display: flex;
                    padding: 25px;
                    flex-direction: column;
                    align-items: center;
                    gap: 12px;
                    border-right: 1px solid var(--border-color, #F2F2F2);
                }

                .end_date {
                    display: flex;
                    padding: 25px;
                    flex-direction: column;
                    align-items: center;
                    gap: 12px;
                    border-right: 1px solid var(--border-color, #F2F2F2);
                }

                .contact_person {
                    display: flex;
                    padding: 25px;
                    flex-direction: column;
                    align-items: center;
                    gap: 12px;
                }

                span {
                    color: var(--text-grey, #636363);
                    font-feature-settings: 'clig' off, 'liga' off;
                    font-size: 15px;
                }

                p {
                    color: var(--Text-dark, #303030);
                    font-size: 15px;
                    font-weight: 500;
                }
            }

        }
    }


    .subTitle {
        font-size: 28px;
        text-transform: uppercase;
        margin-bottom: 66px;
        color: black;
        font-weight: 500;
    }

    .ant-row.ant-form-item-row.css-dev-only-do-not-override-3mqfnx {
        flex-direction: column;
    }

    @media screen and (max-width: 992px) {
        .bd_main_add {
            flex-direction: column;
        }

        .bd_main_sub_add_profile {
            width: 100% !important;
            border-bottom: 2px solid var(--border, #DADADA);
        }

        .bd_main_sub_add_details {
            width: 100% !important;
        }
    }

    @media screen and (max-width: 767px) {
        .bd_main_sub_add_company_name h3 {
            font-size: 18px !important;
        }

        .bd_main_sub_add_company_name p {
            font-size: 16px !important;
        }

        .bd_main_sub_add_company_name span {
            font-size: 15px !important;
        }

        .start_date.end_date.contact_person span {
            font-size: 15px !important;
        }

        .profile_button_sec {
            margin: auto;
        }
    }

    @media screen and (max-width: 576px) {
        .bd_main_subscription {
            width: 100%;
            flex-direction: column;
            align-items: flex-start;
        }

        .start_date {
            width: 100%;
            padding: 10px !important;
        }

        .end_date {
            width: 100%;
            padding: 10px !important;
        }

        .contact_person {
            width: 100%;
            padding: 10px !important;
        }
    }

}

.bd_main_profile_tabs {
    width: 100%;

    .profile_tabs_heading {


        .profile_detail_info_box {
            padding: 30px;
            background: #fff;
            border-radius: 8px;
            box-shadow: $box-shadow;

            input[disabled],
            .ant-select-disabled .ant-select-selector {
                background: $background !important;
            }

            .ant-input-affix-wrapper {
                border: 1px solid $secondary-300;
                padding-block: 0;

                input {
                    border: none;
                    height: 50px !important;

                    &[disabled] {
                        background: $background !important;
                    }
                }
            }
        }

        .profile_button_sec {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            gap: 25px;
            margin-left: auto;
            border-top: 1px solid $border;
            margin-top: 15px;
            padding-top: 30px;
        }

        .ant-tabs-nav-wrap {
            // padding-inline: 10px;
        }

        .ant-tabs-nav {
            // border-bottom: 2px solid $primary-200;
            margin: 0;
            padding-inline: 40px;
            background: $primary-100;

            &::before {
                display: none;
            }

            .ant-tabs-tab-btn {
                font-size: 17px;
                font-weight: 400;

                @media (max-width: 1900px) {
                    font-size: 15px;
                }

            }

            .ant-tabs-tab {
                padding: 20px 16px;
                margin: 0;
                color: #636363;
                position: relative;
                z-index: 1;

                .ant-tabs-tab-icon {
                    margin-right: 8px;
                }

                &.ant-tabs-tab-active .ant-tabs-tab-btn {
                    color: $primary-500;

                    svg>* {
                        stroke: $primary-500;
                    }
                }
            }
        }

        .ant-tabs-content-holder {
            padding: 40px;
        }

        .ant-tabs-ink-bar.ant-tabs-ink-bar-animated {
            width: 59px;
            border-radius: 6px 6px 0 0;
            background: $secondary;
            height: 5px;
        }
    }
}


// storage css
.storage_wrapper {
    padding: 14px 40px;
    background: $warning-100;
    display: flex;
    align-items: center;
    gap: 20px;
    justify-content: space-between;

    .storage_stats {
        width: 40%;

        .ant-progress {
            margin-bottom: 5px;

            .ant-progress-bg {
                background: $warning-500;
            }
        }

        h5 {
            font-size: 14px;
            font-weight: 400;
            color: $secondary-600;
            margin: 0;
        }
    }

    .upgrade_btn {
        padding: 14px 18px;
        font-weight: 400;

        &:hover {
            background: $primary-500 !important;
            color: #fff !important;
        }
    }
}

.profile__wrapper {
    input[type="file"] {
        display: none;
    }

    .custom-file-upload {
        border-radius: 50%;
        display: inline-block;
        position: relative;
        padding: 6px;
        cursor: pointer;
        background: linear-gradient(270deg, #3fa1a9, #79f1a4);
        margin-bottom: 25px;
    }

    .img-wrap {
        position: relative;
        width: 130px;
        height: 130px;
        overflow: hidden;
        border-radius: 50%;
    }

    .img-upload:before {
        content: "\f093";
        font-size: 90px;
        position: absolute;
        padding-top: 80px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
        color: #63d3a6;
        width: 200px;
        height: 200px;
        border-radius: 50%;
        opacity: 0;
        transition: .5s ease;
        background-color: #fff;
    }

    .img-upload:hover:before {
        opacity: 1;
    }

    img {
        width: auto;
        height: 100%;
    }
}


// <-----------------------------css-------------------------------->

.profile__wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
}

.edit-section-name {
    margin-bottom: 3rem;
}

.name-sec {
    margin-bottom: 2rem;
}

.edit-input {
    display: flex;
    flex-direction: column;
}

.input-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 15px;
}

.input-1 {
    position: relative;
    width: 100%;
}

#count {
    position: absolute;
    top: 0;
    right: 0;
}

.check-icon {
    font-size: 20px;
    margin-left: 14px;
}

.edit-input input {
    width: 100%;
    border: none;
    outline: none;
    border-bottom: 1px solid #44a8a8 !important;
    padding-bottom: 4px;
}

.edit-name {
    display: flex;
    align-items: center;
    justify-content: space-between;
}