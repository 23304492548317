.chat__wrapper {
    &:before {
        content: "";
        background-image: url('../../img/bg\ print.png');
        background-attachment: fixed;
        opacity: 0.25;
        position: absolute;
        background-color: #fff1db;
        height: 100%;
        top: 0;
        bottom: 0;
        right: 0;
        z-index: -1;
        left: 0;
    }
}

.msg__block {
    padding: 40px 40px 120px 40px;
    height: calc(100vh - 300px);
    overflow: auto;
    position: relative;
    overflow-y: auto;



    .meg__item {
        display: flex;
        gap: 20px;
        margin-bottom: 30px;

        &:last-child {
            margin-bottom: 0;
        }

        .text {
            font-size: 13px;
            font-weight: 500;
            background: #d1e7fbc7;
            max-width: 60%;
            padding: 12px 15px;
            border-radius: 12px;
            border-top-left-radius: 0;
            line-height: 140%;
            color: $Text-dark;
        }

        .uploaded-image {
            width: 60%;
            max-width: 300px;
            display: flex;
            padding: 6px 12px 6px 6px;
            border-radius: 16px;
            background-color: white;
            border-top-left-radius: 0px;
            gap: 20px;
            align-items: center;

            position: relative;

            .doc-img {
                border-radius: 10px;
                width: 100%;
                padding: 10px;
                background-color: rgba(255, 255, 255, 0.5);
                display: flex;
                gap: 12px;
                align-items: center;

                img {
                    width: 25px;
                }

                span {
                    font-size: 14px;
                    font-weight: 400;
                    color: #000;
                }
            }

            .file-details_image {
                position: absolute;
                top: 10px;
                right: 10px;

                button {
                    border: none;
                    background: #eee;
                    border-radius: 20px;
                    padding: 6px 9px;
                    cursor: pointer;

                    svg {
                        font-size: 21px;
                    }
                }
            }

            .file-details {
                button {
                    border: none;
                    background: #eee;
                    border-radius: 20px;
                    padding: 6px 9px;
                    cursor: pointer;

                    svg {
                        font-size: 21px;
                    }
                }
            }

            .imgGroup {

                img {
                    height: 100px;
                    object-fit: cover;
                }

                .overlap {
                    width: 100%;
                    height: 100%;
                    background-color: rgba(0, 0, 0, 0.5);
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    position: absolute;
                    left: 0;
                    top: 0;
                    z-index: 1;
                    border-radius: 10px;

                    p {
                        font-size: 28px;
                        color: white;
                    }
                }
            }

            .file-details {
                // margin: 8px 0px;
                // padding: 13px;
                // border-radius: 10px;
                // background-color: rgb(124 124 124 / 10%);
                // color: #0000006b;
            }

            .image-des {
                color: black;
                padding: 10px 6px;
                font-weight: 400;
                line-height: 130%;
            }
        }

        .time {
            font-size: 12px;
            line-height: 200%;
            white-space: nowrap;
            // color: $grey;
        }



        &.myChat {
            flex-direction: row-reverse;

            .uploaded-image {
                border-top-left-radius: 16px;
                border-top-right-radius: 0px;
                background-color: rgba(233, 142, 60, 0.18);
            }

            .text {
                border-top-left-radius: 12px;
                border-top-right-radius: 0;
                background: rgb(233 142 60 / 18%);
                ;
            }
        }
    }
}

.date_frameChat {
    position: absolute;
    margin: auto;
    background: #e8e8e8;
    width: fit-content;
    padding: 5px;
    border: none;
    border-radius: 10px;
    right: 49%;
    margin-top: 15px;

    span {
        font-size: 12px;
        font-weight: 400;
        color: #000;
    }
}

.chatAnother_image {
    display: flex;
    flex-direction: column;
    padding-inline: 100px;
    justify-content: center;
    align-items: center;
    height: calc(100vh - (78px + 64px));
    position: relative;

    &::before {
        content: "";
        background-image: url('../../img/bg print.png');
        opacity: 0.3;
        z-index: -1;
        position: absolute;
        background-color: #fff1db;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
    }


    img {
        width: 140px;
        height: 140px;
        object-fit: cover;
        margin-bottom: 10px;
    }

    .chatgrid_para {
        display: flex;
        flex-direction: column;
        gap: 20px;
        text-align: center;
        align-items: center;

        h5 {
            font-size: 18px;
            font-weight: 600;
            color: #000;
        }

        span {
            font-size: 14px;
            font-weight: 400;
            color: #636363;
            line-height: 140%;
            letter-spacing: 1px;
        }
    }
}

.chatAnother_image_sec {
    display: flex;
    flex-direction: column;
    padding-inline: 100px;
    justify-content: center;
    align-items: center;
    height: calc(100vh - (78px + 64px));
    position: relative;

    &::before {
        content: "";
        background-image: url('../../img/bg print.png');
        opacity: 0.3;
        z-index: -1;
        position: absolute;
        background-color: #fff1db;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
    }


    img {
        width: 140px;
        height: 140px;
        object-fit: cover;
        margin-bottom: 10px;
    }

    .chatgrid_para {
        display: flex;
        flex-direction: column;
        gap: 20px;
        text-align: center;
        align-items: center;

        h5 {
            font-size: 18px;
            font-weight: 600;
            color: #000;
        }

        span {
            font-size: 14px;
            font-weight: 400;
            color: #636363;
            line-height: 140%;
            letter-spacing: 1px;
        }
    }
}

.images-group-wrapper {
    max-height: 70vh;
    overflow-y: auto;
}

.image-modal {
    .ant-modal-footer {
        justify-content: flex-start !important;
    }
}