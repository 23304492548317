/* body{
	overflow: auto !important;
} */
.modal-open {
	overflow: auto !important;
}

.main {
	position: relative;
	width: 1000px;
	min-width: 1000px;
	min-height: 600px;
	height: 600px;
	padding: 25px;
	background-color: #ecf0f3;
	box-shadow: 10px 10px 10px #d1d9e6, -10px -10px 10px #f9f9f9;
	border-radius: 12px;
	overflow: hidden;
}

.login_body {
	width: 100%;
	height: 100vh;
	/* display: grid;
	grid-template-columns: 1.5fr 1fr; */
	display: flex !important;
	justify-content: center;
	align-items: center;
	font-size: 12px;
	background-color: #fff;
	overflow: auto !important;
}

.loginBanner {
	position: relative;
	background: black;
}

.loginBanner img {
	width: 100%;
	height: 100vh;
	object-fit: cover;
	opacity: 0.8;
}

.container_login {
	display: flex;
	justify-content: center;
	align-items: center;
	position: absolute;
	top: 0;
	width: 600px;
	height: 100%;
	padding: 25px;
	background-color: #ecf0f3;
	transition: 1.25s;
}

.form {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	width: 100%;
	height: 100%;
}

.form__icon {
	justify-content: center;
	align-items: center;
	font-size: 16px;
	object-fit: contain !important;
	width: 30px !important;
	height: 30px !important;
	margin: 0 5px !important;
	opacity: 0.5 !important;
	transition: 0.15s !important;
	border: none !important;
}

.my-facebook-icon {
	border: none !important;
	opacity: 0.3 !important;
	width: 50px !important;
	height: 50px !important;
}

.my-facebook-icon:hover {
	opacity: 0.5 !important;
	transition: 0.15s !important;
	cursor: pointer !important;
}

.form__icon:hover {
	opacity: 1 !important;
	transition: 0.15s !important;
	cursor: pointer !important;
}

.form__input {
	width: 100%;
	height: 56px;
	margin: 0;
	padding-left: 20px;
	font-size: 14px;
	letter-spacing: 0.15px;
	background: #fff;
	border: 1px solid #979393;
	transition: 0.25s ease;
	border-radius: 4px;
}

.form__input:last-child {
	margin-bottom: 10px;
}

.form__input:focus {
	box-shadow: none !important;
	outline: none !important;
	border-color: var(--primary) !important;
}

.form__span {
	margin-top: 30px;
	margin-bottom: 12px;
}

.forgotPassword {
	color: #444444;
	font-size: 14px;
	font-weight: 500;
	margin-top: 5px;
	cursor: pointer;
	text-align: right;
	width: 100%;
}

.forgotPassword a {
	color: #444444;
}

.forgotPassword:hover a {
	color: var(--bs-orange);
}

.title {
	font-size: 34px;
	font-weight: 700;
	line-height: 3;
	color: #181818;
}

.description {
	font-size: 14px;
	letter-spacing: 0.25px;
	text-align: center;
	line-height: 1.6;
}

.button {
	width: 180px;
	height: 50px;
	border-radius: 25px;
	margin-top: 50px;
	font-weight: 700;
	font-size: 14px;
	letter-spacing: 1.15px;
	background-color: #4B70E2;
	color: #f9f9f9;
	box-shadow: 8px 8px 16px #d1d9e6, -8px -8px 16px #f9f9f9;
	border: none;
	outline: none;
}

.button:hover {
	background: var(--bs-orange);
}


/**/
.is-txr {
	left: calc(100% - 400px);
	transition: 1.25s;
	transform-origin: left;
}

.is-txl {
	left: 0;
	transition: 1.25s;
	transform-origin: right;
}

.is-z200 {
	z-index: 200;
	transition: 1.25s;
}

.is-hidden {
	visibility: hidden;
	opacity: 0;
	position: absolute;
	transition: 1.25s;
}

.is-gx {
	animation: is-gx 1.25s;
}

@keyframes is-gx {

	0%,
	10%,
	100% {
		width: 400px;
	}

	30%,
	50% {
		width: 500px;
	}
}

.f-password {
	width: 400px;
	min-width: 400px;
}

.f-password .a-container {
	left: calc(100% - 500px);
}

.Sign-Up .a-container {
	z-index: 100;
	left: calc(100% - 100%);
	width: 100%;
}

.Sign-Up .form {
	width: 100%;
}

/* ===================== */
.formWarpper {
	padding: 0px 50px;
}

.formWarpper .section-title h1 {
	font-size: 22px;
}

.formWarpper .section-title p {
	margin-top: 10px;
	width: 100%;
	font-size: 14px;
}

.formWarpper .form__icons {
	width: 100%;
	margin-block: 30px 40px;
	display: flex;
	gap: 20px;
}

.formWarpper .form__icons>span {
	width: 100%;
	transition: none !important;

}

.formWarpper .socailLogin {
	padding: 16px 50px;
	margin-inline: auto;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 6px;
	background: #fff;
	border: 1px solid var(--primary) !important;
	font-size: 14px;
	color: #000;
	font-weight: 500;
	gap: 15px;
	/* width: 100%; */
	/* margin-bottom: 20px; */
	cursor: pointer;
	border: none;
}

.socailfacebook {
	padding: 16px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 6px;
	background: #fff;
	border: 1px solid var(--primary) !important;
	font-size: 14px;
	color: #000;
	font-weight: 500;
	gap: 15px;
	width: 100%;
	margin-bottom: 0px;
	border: none;
}

.socailfacebook:hover {
	color: var(--bs-orange);
}

.socailfacebook .fa {
	color: #4080ff;
	font-size: 18px;
}

.formWarpper .socailLogin img {
	width: 18px;
}

.formWarpper .orItem {
	position: relative;
	width: 100%;
}

.formWarpper .orItem .iier {
	margin-inline: auto;
	width: 30px;
	height: 30px;
	border-radius: 50%;
	position: relative;
	background: var(--bodyNew);
	font-size: 14px;
	font-weight: 400;
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 1;
}

.formWarpper .orItem::after {
	position: absolute;
	content: '';
	background: var(--bodyNew);
	width: 100%;
	height: 1.5px;
	left: 0;
	top: 0;
	bottom: 0;
	margin: auto;
}

.formWarpper .inputGrp {
	margin-top: 40px;
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	gap: 9px;
	/* align-items: center; */
}

.formWarpper .mainButton {
	margin-block: 30px;
	border-radius: 4px;
	width: 60%;
	justify-content: center;
}

.formWarpper .mainButton:disabled {
	opacity: .4;
}

.signUpLink {
	font-size: 16px;
	font-weight: 500;
	color: #6e6e6e;
	margin: 15px;
}

.signUpLink a {
	cursor: pointer;
	color: var(--primary);
}

.signUpLink a:hover {
	color: var(--bs-orange);
}

/* ================= */

.signup_body {
	grid-template-columns: 1fr 1fr;
}

.signup_body .form__icons {
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 20px;
}

.signup_body .formWarpper .socailLogin {
	margin-bottom: 0;
}

.signup_body .formWarpper .socailLogin:hover {
	color: var(--bs-orange);
}

.signup_body .form__input:last-child {
	margin-bottom: 15px;
}

/* ================ */

.css-bdhsul-MuiTypography-root-MuiDialogTitle-root {
	font-size: 19px !important;
	color: #000;
	font-weight: 500 !important;
	background: var(--bodyNew);
	padding: 20px !important;
	font-family: inherit !important;
	text-align: center;
}

.bodyModal {
	padding: 30px;
}

.otpBox {
	margin-bottom: 15px;
}

.otpBox>div {
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 20px;
}

.otpBox * input {
	width: 50px !important;
	height: 50px !important;
	margin: 0 !important;
	border-radius: 4px;
	border: 1px solid #ccc;
}

.bodyModal .mainButton {
	margin-inline: auto;
	/* margin-top: 25px;	 */
	border-radius: 4px;
	margin: 0;
}

.bodyModal .mainButton:focus {
	outline: 2px solid var(--third);
	outline-offset: 2px;
	background: inherit;
}

.queries_mail {
	font-size: 14px;
	color: #5b5b5b;
	font-weight: 500;
	position: absolute;
	right: 40px;
	bottom: 40px;
	background: white;
	padding: 15px 20px;
	border-radius: 100px;
}

.queries_mail a {
	color: var(--primary);
	text-decoration: underline;
}

.errormessagename {
	margin-top: 20px;
	color: red;
	display: flex;
	text-align: center;
	align-items: center;
	justify-content: center;
	font-size: 14px;
}




/* ============ */

.loginLogo {
	text-align: center;
	margin-bottom: 30px;
}

.boBack_button {
	position: absolute;
	top: 30px;
	left: 30px;
	width: 40px;
	height: 40px;
	background: white;
	z-index: 1;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
}

.eye_position {
	position: relative;
}

.eye_visible_signup {
	position: absolute;
	margin-top: 16px;
	right: 25px;
	cursor: pointer;
}

.errore_poppp {
	font-size: 13px !important;
	margin-top: 8px !important;
}

.pay_what_wrapper {
	margin-top: 40px;
	text-align: center;
}

.pay_what_wrapper h3 span {
	font-weight: 600;
	color: var(--bs-orange);
}

.pay_what_wrapper h6 {
	margin-top: 30px;
	font-size: 17px;
	width: 70%;
	margin-inline: auto;
	line-height: 140%;
	font-weight: 400;
}