.notification_wrapper {
    background-color: #f8f8f8;
    padding: 40px;

    .page__title {
        margin-bottom: 60px;
        font-size: 26px;
    }

    .noti__block {
        height: 500px;
        overflow: auto;

        h5 {
            font-size: 24px;
            margin-bottom: 20px;
        }

        .noti__item {
            display: flex;
            align-items: flex-start;
            gap: 15px;
            padding: 20px;
            border-bottom: 1px solid $border;
            &:hover{
                background-color: #f8f8f8;
            }

            .ant-avatar {
                width: 38px;
                height: 38px;
                border-radius: 50%;
            }

            .comments_img {
                width: 80px;
            }

            .text__det {
                font-size: 13px;
                color: $secondary-600;
                font-weight: 400;
                line-height: 140%;

                span {
                    font-size: 14px;
                    color: $dark-500;
                    font-weight: 500;
                    text-decoration: underline;
                }
            }

            .text_mention {
                margin-top: 5px;
                margin-bottom: 8px;
                color: $dark-400;
                font-weight: 500;
            }

            .text_desclimer {
                margin-bottom: 8px;
                color: $primary-400;
                font-weight: 500;
                font-size: 13px;
            }

            .chips_time {
                display: flex;
                justify-content: space-between;
                margin-top: 13px;
                align-items: center;

                .date_text {
                    font-size: 12px;
                    color: $secondary-600;
                    font-weight: 400;
                }

                .text_chips {
                    border: 1px solid hsl(251, 64%, 78%);
                    padding: 5px 10px;
                    width: -moz-fit-content;
                    width: fit-content;
                    border-radius: 20px;
                    font-size: 12px;
                    cursor: pointer;
                    font-weight: 400;
                }
            }

            .comment_wrapper {
                display: flex;
                align-items: center;
                gap: 10px;
                margin: 10px 0px;

                .ant-upload-wrapper {
                    width: auto;

                    .ant-upload {
                        .anticon-upload {
                            font-size: 20px;
                            color: $secondary;
                        }
                    }

                    .ant-upload,
                    .ant-upload-list-item-container {
                        width: 40px;
                        height: 40px;
                        border-radius: 6px;
                        margin: 0;
                    }

                    .ant-upload-list-item {
                        width: auto;
                        height: auto;
                        padding: 0;

                        &::before {
                            width: calc(100% - 10px);
                            height: calc(100% - 10px);
                            left: 5px;
                        }
                    }

                    .ant-btn-icon {
                        span {
                            margin: 0;
                            padding: 0;
                        }
                    }

                }

                .anticon-file {
                    font-size: 26px;
                }

                .ant-upload-list-item-name {
                    bottom: 3px;
                    width: 100%;
                    font-size: 10px;
                }
            }

            .comment_box {
                border-radius: 6px;
                padding-right: 15px;
                background: #f8f8f8;
                display: flex;
                align-items: center;
                gap: 10px;
                flex: 1;
                border: 1px solid $border;

                .ant-mentions {
                    background: transparent;
                    border: none;

                    textarea {
                        padding: 15px;
                        font-family: Poppins;
                        border: none;
                        height: 55px;
                    }
                }


                .reply_icons {
                    display: flex;
                    align-items: center;
                    gap: 12px;
                }

                svg {
                    fill: #6c6c6c;
                    width: 20px;
                }

                .anticon-send svg {
                    width: 22px;
                    height: 22px;
                    cursor: pointer;
                    fill: #6c6c6c;

                }
            }
        }

        .unSeen_bg {
            background: $primary-100;
        }
    }

    .box__title {
        margin-bottom: 30px;
        font-size: 16px;
        font-weight: 500;
        color: $secondary-700;
        display: flex;
        align-items: center;
        gap: 15px;

        span {
            color: $secondary-600;
            font-size: 15px;
        }
    }

    .bg__noti {
        background: white;
        border-radius: 8px;
        border-top: 3px solid $primary-500;
    }

}