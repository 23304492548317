.setting-container {
    background: $background;
    display: flex;
    position: relative;
    height: 100%;

    .fixed_wrap {
        position: sticky;
        top: 80px;
        overflow: auto;
        height: calc(100vh - 80px);
    }

    .seeting_tabsMenu {
        background: white;
        border-right: 1px solid $secondary-200;
        width: 350px;


        .headding-title {
            padding: 20px 25px;
            position: sticky;
            top: 0px;
            background: white;
            border-bottom: 1px solid $secondary-200;

            h2 {
                color: #000;
                font-size: 22px;
                font-weight: 500;
            }
        }

        .bd_seacrh_box {
            display: flex;
            position: relative;
            margin-top: 16px;
            border: 1px solid $secondary-300;
            border-radius: 6px;
            overflow: hidden;

            input {
                height: 44px;
                border: none;
            }

            .search_icon {
                position: absolute;
                right: 0;
                top: 0;

                button {
                    background: none;
                    border: none;
                    padding: 5px 12px;
                    border-top-right-radius: 10px;
                    border-bottom-right-radius: 10px;

                    svg {
                        opacity: 1;
                        font-size: 18px;

                        &:hover {
                            color: $primary;
                        }
                    }
                }
            }
        }

        ul {
            li a {
                padding: 20px 30px;
                border-bottom: 1px solid $secondary-200;
                color: $secondary-700;
                font-weight: 400;
                display: block;

                &:hover {
                    background: $primary-100;
                    color: $primary-500 !important;
                }
            }
        }


    }
}

.setting_masterTabs {
    background: $light-background;
    border: none;
    border-radius: 6px;
    padding: 12px 18px;
    width: fit-content;

    .ant-tabs {

        .ant-tabs-tab {
            padding: 10px 15px !important;
            font-size: 16px !important;
            margin: 0;

            &:hover {
                color: $primary !important;
            }

            .anticon {
                margin-right: 8px !important;
            }

            .anticon-ellipsis {
                display: none !important;
            }
        }

        .ant-tabs-tab-active {
            color: $primary !important;
            background: #fff !important;
            text-shadow: 0 0 0.25px currentcolor;
            border-radius: 8px;

            transition: color 0.10s ease-in, transform 0.10s ease-in;

            .ant-tabs-tab-btn {
                color: $primary !important;
            }
        }

        .ant-tabs-tab-btn {

            &:active {
                color: $primary !important;
                transform: scale(1);
            }

            &:hover {
                color: $primary !important;
                transform: scale(1);
            }
        }

        .ant-tabs-nav {
            margin: 0 !important;

            &::before {
                border-bottom: 0 !important;
            }

            .ant-tabs-nav-list {
                gap: 28px !important;
            }
        }

        .ant-tabs-ink-bar {
            background: none !important;
        }

        .ant-tabs-nav-operations {
            display: none !important;
        }

    }
}

.setting_model {

    .table__wrapper {
        border-radius: 10px;
        overflow: hidden;
        border: 2px solid $border;

        table {
            margin: 0;
            font-size: 14px;

            th,
            td {
                padding: 12px 15px;
                border-top: none;
                vertical-align: middle;
                font-weight: 500;
            }

            tbody tr {
                border-top: 2px solid $border;
            }

            thead {
                background: #f9fafc;

                th {
                    border: none;
                    color: #636363;
                    font-weight: 400;

                    &:last-child {
                        text-align: right;
                    }
                }
            }

            tr {
                td {
                    text-transform: capitalize;
                    color: #636363;

                    &:last-child {
                        text-align: right;
                    }
                }
            }

            // action dropdown
            .action--dropdown {
                button {
                    display: flex;
                    align-items: center;
                    gap: 15px;
                    background: none;
                    border: none;

                    img {
                        width: 15px;
                        opacity: .5;
                    }


                    &::after {
                        display: none;
                    }
                }

                .dropdown-menu {
                    border-radius: 6px;
                    top: 1.5rem !important;

                    a {
                        color: rgb(88, 88, 88);
                        font-size: 13px;
                        font-weight: 400;
                        padding-block: 8px;
                    }
                }
            }

        }
    }
}

// --------------

.roleManagement_wrapper {
    padding: 40px;

    .heading_flex {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 40px;

        .head_title {
            font-size: 24px;
            font-weight: 600;
        }
    }

    .manage_btn {
        display: flex;
        justify-content: end;
        align-items: center;
    }

    .tenderCycle_main {
        width: 100%;

        .ant-tabs-nav {
            border: none !important;
            display: block;
        }

        .ant-tabs-nav-wrap {
            overflow: visible;
            position: sticky;
            top: 100px;
        }

        .ant-tabs-left>.ant-tabs-content-holder,
        :where(.css-dev-only-do-not-override-nllxry).ant-tabs-left>div>.ant-tabs-content-holder {
            // margin-left: 40px;
            border: none !important;
        }

        .ant-tabs-nav-list {
            border-radius: 10px;
            overflow: hidden;
            box-shadow: $box-shadow;
            background: white;
        }

        .ant-tabs-ink-bar {
            display: none;
        }


        .ant-tabs-left>.ant-tabs-nav .ant-tabs-tab {
            padding: 0;
            margin: 0;
        }

        .ant-tabs-tab-btn .tab-item {
            font-weight: 400;
            font-size: 15px;
            text-align: start;
            padding: 16px 20px;
            line-height: 140%;
            width: 250px;
            display: block;
            border-bottom: 1px solid $secondary-200;
            margin: 0;

            span {
                color: $secondary-600;
            }
        }

        .ant-tabs-tab-active {
            position: relative;
            z-index: 99;
            border-left: 4px solid $primary-500;

            span {
                color: $primary-500 !important;
            }
        }


        .permissions_access_wrap {
            padding-left: 20px;

            .page_permission {
                margin-bottom: 60px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                // border-bottom: 2px solid $border;
                // padding-bottom: 30px;

                .title {
                    font-size: 16px;
                    font-weight: 500;
                    line-height: 100%;
                }

                // .multi-title {
                //     font-size: 16px;
                //     font-weight: 500;
                //     margin-top: -38px;
                //     color: #6a6868;
                // }

                .switched_grp {
                    display: grid;
                    gap: 40px;
                    grid-template-columns: 1fr 1fr 1fr 1fr;
                    width: 50%;

                    .swtich {
                        display: flex;
                        gap: 15px;
                        align-items: center;

                        label {
                            color: $secondary-600;
                            font-weight: 400;
                        }

                        .lable_switch {
                            display: flex;
                            align-items: center;
                            gap: 5px;

                            img {
                                opacity: 0.6;
                            }

                            span {
                                font-size: 15px;
                                color: #79868e;
                                opacity: 1;
                            }
                        }

                        .ant-switch.ant-switch-checked {
                            background: $primary !important;

                            // .ant-switch-handle {
                            //     inset-inline-start: calc(100% - 24px);
                            // }
                        }

                        // .ant-switch-handle {
                        //     top: 3px !important;
                        //     width: 20px !important;
                        //     left: 3px;
                        //     height: 20px !important;
                        // }

                        // .ant-switch {
                        //     min-width: 48px !important;
                        //     height: 26px !important;
                        //     background: #ddd !important;
                        // }
                    }
                }

                .switched_grp_cycle {
                    display: flex;
                    flex-wrap: wrap;
                    gap: 32px;

                    .swtich {
                        display: flex;
                        gap: 25px;
                        align-items: center;
                        border: 1px solid $border;
                        padding: 20px 25px;
                        border-radius: 7px;
                        background: #fff;
                        box-shadow: 2px 2px 10px 0px rgba(0, 0, 0, 0.05);

                        .lable_switch {
                            display: flex;
                            align-items: center;
                            gap: 5px;

                            img {
                                opacity: 0.6;
                            }

                            span {
                                font-size: 15px;
                                color: #79868e;
                            }
                        }

                        .ant-switch.ant-switch-checked {
                            background: $primary !important;

                            .ant-switch-handle {
                                inset-inline-start: calc(100% - 24px);
                            }
                        }

                        .ant-switch-handle {
                            top: 3px !important;
                            width: 20px !important;
                            left: 3px;
                            height: 20px !important;
                        }

                        .ant-switch {
                            min-width: 48px !important;
                            height: 26px !important;
                            background: #ddd !important;
                        }

                        .ant-switch-disabled {
                            background-color: #8b8686 !important;
                        }
                    }
                }


                .multi-switched_grp {
                    display: flex;
                    flex-wrap: wrap;
                    gap: 160px;
                    border-bottom: 2px solid $border;
                    padding-bottom: 40px;
                    margin-left: 23px;

                    .swtich {
                        display: flex;
                        gap: 15px;
                        align-items: center;

                        .lable_switch {
                            display: flex;
                            align-items: center;
                            gap: 5px;

                            img {
                                opacity: 0.6;
                            }

                            span {
                                font-size: 15px;
                                color: #79868e;
                                opacity: 1;
                            }
                        }

                        .ant-switch.ant-switch-checked {
                            background: $primary !important;

                            .ant-switch-handle {
                                inset-inline-start: calc(100% - 24px);
                            }
                        }

                        .ant-switch-handle {
                            top: 3px !important;
                            width: 20px !important;
                            left: 3px;
                            height: 20px !important;
                        }

                        .ant-switch {
                            min-width: 48px !important;
                            height: 26px !important;
                            background: #ddd !important;
                        }
                    }
                }
            }
        }

        .permissions_access_widget {
            // padding-left: 20px;
            display: flex;
            flex-wrap: wrap;
            gap: 25px;
            align-items: center;

            .widgets_cards {
                line-height: 100%;
                padding: 20px 25px;
                background: #fff;
                border: 1px solid $secondary-200;
                border-radius: 10px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                // box-shadow: $box-shadow;
                cursor: pointer;
                gap: 30px;

                span {
                    font-size: 15px;
                    font-weight: 400;
                    color: $secondary-600;
                }

                .ant-switch.ant-switch-checked {
                    background: $primary !important;

                    .ant-switch-handle {
                        inset-inline-start: calc(100% - 24px);
                    }
                }

                .ant-switch-handle {
                    top: 3px !important;
                    width: 20px !important;
                    left: 3px;
                    height: 20px !important;
                }

                .ant-switch {
                    min-width: 48px !important;
                    height: 26px !important;
                    background: #ddd !important;
                }
            }
        }

        .ant-table-cell {
            .ant-space-item {
                svg {
                    font-size: 18px !important;
                }
            }

            .anticon-close {
                &:hover {
                    color: red;
                }
            }

            .anticon-check {
                &:hover {
                    color: $secondary !important;
                }
            }
        }

        .ant-spin-container {
            background: var(--border-color, #F2F2F2) !important;
            backdrop-filter: blur(4px);
            border-radius: 6px !important;
        }

        .ant-pagination.ant-table-pagination.ant-table-pagination-right {
            display: flex;
            padding: 16px 30px;
            gap: 0;
            margin: 0;
            justify-content: flex-start;

            .ant-pagination-total-text {
                font-size: 14px;
                color: var(--text-grey, #636363);
                font-weight: 500;
                font-family: 'Poppins', sans-serif !important;
            }

            .ant-pagination-prev {
                margin-left: auto;
            }

            .ant-pagination-item-link {
                border-radius: 8px;
                border: 1px solid var(--border, #DADADA);
                background: var(--white, #FFF);
                padding: 8px 12px;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            .ant-pagination-item-active {
                background: $background;
                border-color: $secondary;
            }
        }

        .ant-table-thead {
            background: var(--border-color, #F2F2F2) !important;
            padding: 15px 30px;
            gap: 30px;
        }
    }

    .ant-collapse {
        border: none;
        border-bottom: 1px solid $secondary-200;
        overflow: hidden;
        border-radius: 0;
        background: none;

        .ant-collapse-content,
        .ant-collapse-item-active,
        .ant-collapse-item {
            // border-color: $border !important;
            margin-bottom: 0 !important;
            border: none;
        }
    }

    .ant-collapse-arrow {
        svg {
            display: none;
        }
    }

    .ant-collapse-header {
        padding: 20px 0px;
        border-color: $border;
        border-radius: 0;
        background: transparent;
    }

    .ant-collapse-content {
        background: transparent;
    }

    .ant-collapse-content-box {
        padding: 30px 10px;
        padding-right: 0px;
        padding-top: 20px;
    }

    .collapse_flex {
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: $grey;
        font-size: 18px;
        text-transform: uppercase;
        font-weight: 600;

        .d-flex {
            align-items: center;
            gap: 15px;
        }

        .iconBox {
            width: 45px;
            height: 45px;
            border-radius: 50%;
            border: 1px solid $secondary-200;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    .ant-collapse-item-active {
        .collapse_flex {
            // color: $secondary-900;

            .iconBox {
                border: 1px solid $primary-500;
                background: $primary-100;

                svg>* {
                    stroke: $primary-500;
                }
            }
        }
    }

    .ant-collapse-expand-icon {
        padding: 0 !important;
    }
}