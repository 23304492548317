// $header: 8rem;
// $topNav: 7rem;

// $menu-collapse-time: 300ms;
// $animation-time-long: 1000ms;
// $animation-time-short: 200ms;

// $border-radius: 0.1rem;
// $border-radius-rounded: 0.75rem;

// $primary-400: #fff;
// $primary: #ff7043;
// $secondary: #41A6EC;
$text-light: #939393;
// $acsend: #2e66f6;
// $grey: #78889b;

// $background: #FCFCFC;
// $light-background: #eaeef6;
// $light-blue: #f6f6fe;

// $black: #000;
// $Text-dark: #303030;
// $white: #fff;

// // $border: #f6f7f8;
// $border: #F2F2F2;
// $dark-border: #F2F2F2;
// $warning-color: #b69329;
// $error-color: #c43d4b;


// $tab-color: rgb(0 0 0 / 48%);


// $header: 8rem;
// $topNav: 7rem;

// $menu-collapse-time: 300ms;
// $animation-time-long: 1000ms;
// $animation-time-short: 200ms;

// $border-radius: 0.1rem;
// $border-radius-rounded: 0.75rem;


$primary: #ff7043;
$secondary: #6d2edc;
$acsend: #2e66f6;
$grey: #78889b;

$background: #f8f8f8;
$dark-bg: #e8eef4;
$light-background: #eaeef6;
$light-blue: #f6f6fe;
$light-color: #636363;
$black: #000;
$Text-dark: #303030;
$white: #fff;

$border: #e8eef4;
$dark-border: #F2F2F2;
$warning-color: #b69329;
$error-color: #c43d4b;

$tab-color: rgb(0 0 0 / 48%);

// ----


$primary-100: hsl(251, 64%, 98%);
$primary-200: hsl(250, 100%, 95%);
$primary-300: hsl(251, 64%, 78%);
$primary-400: hsl(251, 64%, 68%);
$primary-500: hsl(251, 64%, 58%);

$secondary-100: hsl(0, 0%, 100%);
$secondary-200: hsl(0, 0%, 90%);
$secondary-300: hsl(0, 0%, 80%);
$secondary-400: hsl(0, 0%, 70%);
$secondary-500: hsl(0, 0%, 60%);
$secondary-600: hsl(0, 0%, 40%);
$secondary-700: hsl(0, 0%, 30%);
$secondary-800: hsl(0, 0%, 20%);
$secondary-900: hsl(0, 0%, 10%);

$info-100: hsl(191, 100%, 98%);
$info-200: hsl(191, 100%, 87%);
$info-300: hsl(191, 100%, 77%);
$info-400: hsl(191, 100%, 67%);
$info-500: hsl(191, 100%, 57%);
$info-600: hsl(191, 100%, 47%);

$warning-100: hsl(22, 90%, 98%);
$warning-200: hsl(22, 90%, 85%);
$warning-300: hsl(22, 90%, 75%);
$warning-400: hsl(22, 90%, 65%);
$warning-500: rgb(244, 113, 37);

$danger-100: hsl(354, 53%, 98%);
$danger-200: hsl(354, 53%, 80%);
$danger-300: hsl(354, 53%, 70%);
$danger-400: hsl(354, 53%, 60%);
$danger-500: hsl(354, 53%, 50%);

$success-100: hsl(138, 52%, 98%);
$success-200: hsl(138, 52%, 88%);
$success-300: hsl(138, 52%, 78%);
$success-400: hsl(138, 52%, 68%);
$success-500: hsl(138, 52%, 58%);

$dark-100: hsl(0, 0%, 40%);
$dark-200: hsl(0, 0%, 30%);
$dark-300: hsl(0, 0%, 20%);
$dark-400: hsl(0, 0%, 10%);
$dark-500: hsl(0, 0%, 0%);


$box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
$primary-gradient: linear-gradient(180deg, hsl(251, 64%, 68%), hsl(251, 64%, 58%));