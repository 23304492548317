:root {
  --second: #767676;
  --third: #973e97;
  --primary: #292240;
  --bs-orange: #cd1f7c;
  --greyBg: #b8b8b8;
  --hover: rgb(116 1 18);
  --facebook: #3b5998;
  --twitter: #00acee;
  --linkedin: #0077b5;
  --bodyNew: #fafafa;
  --greyDark: #9baacf;
  --greyLight-2: #c8d0e7;
  --colorMain: #c0c0c0;
  --brShadow: -1px 2px 3px rgba(0, 0, 0, 0.3);
  --gradient-one: radial-gradient(108.3% 186.75% at -4.62% 9.78%,
      rgb(2 27 177) 0%,
      #fd7e14 100%);
  --tlShadow: 2px -1px 3px rgba(255, 255, 255, 0.8);
}

/* @import url('https://fonts.cdnfonts.com/css/optima'); */

body.tender-grid-module {
  position: static !important;
  font-size: 15px;
  line-height: 30px;
  font-weight: 400;
  color: #828282;
  padding: 0;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  word-break: break-word;
  line-height: 100%;
  letter-spacing: 0.4px;
  /* overflow: auto !important; */
  padding-right: 0 !important;
  /* font-family: 'Optima', sans-serif; */
}

.bg-bodyNew,
.bg-bodyNew .card {
  background: var(--bodyNew) !important;
}

.mdl-button--fab.mdl-button--colored {
  background: var(--bodyNew);
  color: #000;
}

/* -- */
.mainButton {
  border: none;
  display: block;
  width: fit-content;
  cursor: pointer;
  background: var(--primary) !important;
  padding: 20px 25px;
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 0.4px;
  color: white;
  display: flex;
  align-items: center;
  transition: 0.3s ease-in-out;
  border-radius: 0;
  border-radius: 6px;
}

.mainButton.danger {
  background: rgb(191, 37, 37) !important;
}

.mainButton:hover {
  background: var(--bs-orange) !important;
  color: white !important;
}

.mainButton.danger:hover {
  background: #d21111 !important;
  color: white !important;
  opacity: 1 !important;
}

.mainButton .icon {
  font-size: 15px;
}

.subTitle {
  font-size: 16px;
  text-transform: uppercase;
  margin-bottom: 10px;
}

/* -- */

.mdl-button--fab {
  border-radius: 50%;
  padding: 0.8rem 1rem 0.6rem 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 1rem;
  box-shadow: 0.3rem 0.3rem 0.6rem var(--greyLight-2),
    -0.2rem -0.2rem 0.5rem #fff;
  transition: all 0.5s ease;
  background: var(--bodyNew);
}

.mdl-button--fab.mdl-button--colored {
  background: var(--bodyNew);
  color: #000;
}

.mdl-button--fab .material-icons {
  font-weight: bold;
}

.mdl-button--fab.mdl-button--colored:hover,
.mdl-button:hover,
.mdl-button--fab.mdl-button--colored:active,
.mdl-button--fab.mdl-button--colored:focus:not(:active) {
  box-shadow: inset 0.2rem 0.2rem 0.5rem var(--greyLight-2),
    inset -0.2rem -0.2rem 0.5rem #fff;
  background: var(--bodyNew);
}

.bg-bodyNew .card {
  box-shadow: 0.3rem 0.3rem 0.6rem #c8d0e7, -0.2rem -0.2rem 0.5rem #ffffff;
  border: none;
}

a:hover {
  color: var(--bs-orange) !important;
}

.actionButtons a {
  color: var(--primary);
}

.navbar-brand {
  width: 120px;
  margin-right: 0px;
  text-align: center;
}

.navbar-expand-lg .navbar-collapse {
  padding-left: 0;
}

.top-menu {
  padding: 10px 50px;
  border-bottom: 1px solid #ededed;
}

.contact-item {
  font-size: 13px;
  color: #5f6168;
  font-weight: 400;
  display: flex;
  justify-content: space-between;
}

.social-link a {
  font-size: 18px;
  color: #001c47;
}

.social-link a:hover,
.main-menu-right a:hover {
  color: var(--primary);
}

ul.main-menu>li:not(:last-child) {
  margin-right: 0px;
}

ul.main-menu>li>span {
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  color: var(--primary);
  /* letter-spacing: 1px; */
  margin: 0 !important;
}

ul.main-menu>li>span:first-child {
  margin-left: 25px;
}

ul.main-menu>li:hover>span {
  color: var(--primary) !important;
  -webkit-transition: color 0.3s ease-in-out;
  transition: color 0.3s ease-in-out;
}

.main-menu-right {
  position: relative;
  border-left: 1px solid #fff;
  padding-left: 30px;
  gap: 40px;
}

.main-menu-right .social-link {
  display: flex;
}

.nav-link {
  padding: 0px;
  padding-right: 0rem !important;
  padding-left: 0rem !important;
  cursor: pointer;
}

.navbar.navbar-expand-lg.navbar-light {
  background-color: #ffffff;
  transition: all 0.1s ease 0s;
}

.main-menu-right a:after {
  display: none;
}

.main-menu-right a {
  font-size: 18px;
  color: #001c47;
  cursor: pointer;
  margin-right: 0;
}

ul.main-menu>li>.dropdown-menu,
.main-menu-right .dropdown-menu {
  min-width: -webkit-max-content;
  min-width: -moz-max-content;
  min-width: max-content;
  left: 0;
  top: 100%;
  z-index: 999;
  -webkit-box-shadow: 0 13px 35px -12px rgb(35 35 35 / 15%);
  box-shadow: 0 13px 35px -12px rgb(35 35 35 / 15%);
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  padding: 0;
  border: none;
  border-radius: 0px;
  left: inherit;
  background-color: #fff;
  overflow: hidden;
  display: none;
}

ul.main-menu>li:hover>.dropdown-menu,
.main-menu-right .dropdown:hover .dropdown-menu {
  display: block;
  width: 100%;
}

ul.main-menu>li>.dropdown-menu li a {
  display: block;
  color: #000;
  padding: 12px 0px;
  font-weight: 500;
  font-size: 13px;
  text-transform: capitalize;
  line-height: normal;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.main-menu-right input {
  min-width: 300px;
  border: 2px solid #f5bf23;
  padding: 0px 10px;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  line-height: 50px;
}

.main-menu-right .dropdown-menu {
  padding: 0px 0px;
}

.main-menu-right .dropdown-menu .btn-outline-success {
  width: 60px;
  height: 60px;
  background-color: #f5bf23;
  color: #ffffff;
  margin-left: -50px;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  border: none;
}

.hide--desktop {
  display: none !important;
}

.hide--mobile {
  display: block !important;
}

header {
  padding: 0 !important;
}

header.fixed-top {
  box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.04);
}

.header {
  display: block;
}

.topHeader {
  padding: 12px 30px;
  background: white;
  border-bottom: 1px solid #e7e7e7;
}

.topHeader .contact-item {
  display: flex;
  align-items: center;
}

.topHeader .contact-item .d-flex {
  display: flex;
  align-items: center;
  gap: 25px;
}

.topHeader .contact-item a {
  margin-left: 5px;
  color: #000;
}

.topHeader .contact-item a:hover {
  color: var(--primary) !important;
}

.header .mainButton {
  padding: 14px 15px;
  border-radius: 4px;
}

.menuNavigation {
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap !important;
}

.innerMenu {
  padding: 0 20px 0 90px;
  display: flex;
  align-items: center;
  width: 100%;
}

.navbar-nav {
  align-items: center;
}

.nav-item {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dropdown-item {
  padding: 12px 10px;
  font-size: 14px;
  font-weight: 500;
  color: #7e7e7e;
  cursor: pointer;
  white-space: normal;
}

.dropdown-item:focus,
.dropdown-item:hover {
  background-color: #e9ecef !important;
}

.subscribe_menu {
  flex-direction: row;
}

.get_trail_btn {
  border-bottom: 1px solid var(--bs-orange);
  padding-bottom: 0.5px;
}

.btn_getTrail {
  display: flex;
  align-items: center;
  margin-left: 30px;
}

.main-menu-lefts {
  position: relative;
  border-right: 1px solid #fff;
  gap: 40px;
}

.profile_fullname {
  display: flex;
  align-items: center;
  text-align: center;
  font-family: 'Poppins', sans-serif !important;
  margin: 0 !important;
  font-weight: 500 !important;
  color: #000;
}

.social_subscribe {
  display: flex;
  gap: 30px;
  margin-left: auto;
}

.main-menu-left {
  position: relative;
  border-right: 1px solid #ededed;
  gap: 40px;
}

.mega-menu .dropdown-item:focus svg,
.mega-menu .dropdown-item:hover svg {
  color: var(--primary);
  border-color: var(--primary);
}

.mega-menu .menuHeading {
  font-weight: 500;
  text-transform: uppercase;
  margin-bottom: 30px;
  font-size: 15px;
}

.mega-menu .row .row>*:first-child {
  padding-left: 0;
}

.mega-menu .row .row>*:last-child {
  border: none;
}

.mega-menu .row .row>* {
  padding-left: 30px;
  border-right: 1px solid #ececec;
}

.viewMore_button {
  cursor: pointer;
  border-top: 1px solid #e9e9e9;
  padding-top: 20px;
  padding-right: 20px;
  font-size: 13px;
  display: flex;
  align-items: center;
  gap: 10px;
  border-radius: 0;
  margin-top: 15px;
  font-weight: 500;
  color: var(--primary);
}

.viewMore_button:hover {
  color: var(--bs-orange);
}

.mianMenu_full {
  cursor: pointer;
  background: whitesmoke;
  height: 100%;
  padding: 40px 20px;
  font-size: 16px;
  font-weight: 500;
  border-radius: 5px;
  margin-right: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  gap: 30px;
  color: var(--primary);
}

.mianMenu_full span {
  display: flex;
  align-items: center;
  gap: 10px;
}

.mianMenu_full img {
  opacity: .9;
}

.mianMenu_full:hover {
  color: var(--bs-orange);
}

/* === Module header === */


/* .header .innerMenu {
  padding-left: 80px;
} */

.header .innerMenu .navbar-brand {
  position: absolute;
  left: 0;
  z-index: 991;
  top: 0;
  background: white;
  width: auto;
  padding: 20px;
  height: 115px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.module_header {
  position: relative;
  z-index: 99;
  /* background-color: #f4f4f4c2; */
  background-color: #fff;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  padding-inline: 0 0;
  border-top: 1px solid #f5f5f5;
}

.module_header .innerMenu {
  gap: 20px;
}

.module_header .navbar-brand {
  width: auto;
}

.module_header .main-menu {
  display: flex;
  align-items: center;
}

.module_header .nav-item {
  padding: 20px 15px;
}

.module_header .nav-item>span {
  color: #2c2c2c;
  text-transform: none;
  font-size: 15px;
  text-transform: none;
  text-transform: capitalize;
}

.module_header .nav-item:hover>span {
  color: var(--bs-orange) !important;
}

.module_header .mega-menu {
  min-width: auto !important;
  position: fixed !important;
  left: 0 !important;
  right: 0;
  padding: 40px 30px !important;
  transform: none !important;
  top: 100% !important;
  height: 315px !important;
}


/* == */

.subMenu_grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
}

.subMenu_grid .sb_menu {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 30px;
  color: #545454;
  cursor: pointer;
  font-size: 15px;
  font-weight: 500;
  line-height: 150%;
  border-bottom: 1px solid #f1f1f1;
  padding-bottom: 20px;
}

.subMenu_grid .sb_menu:hover {
  color: var(--bs-orange);
}

.subMenu_grid .sb_menu svg {
  color: #afafaf;
  font-size: 14px;
}

/* ========== Header Css End ========== */

.carousel {
  position: relative;
  z-index: 10;
  overflow: visible;
}

.carousel-inner {
  overflow: hidden;
  bottom: 70px;
  height: 100vh;
}

.content-carousel {
  margin-top: 65px;
}

.content-carousel .mainButton {
  margin-top: 40px;
}

.about_data {
  position: relative;
  bottom: 150px;
}

.content-carousel h2.alternate {
  font-size: 45px;
  color: #ffffff;
  font-weight: 600;
}

.content-carousel h2.alternate span {
  display: block;
}

.content-carousel .text {
  transition: none 0s ease 0s;
  text-align: inherit;
  line-height: 32px;
  border-width: 0px;
  margin: 0px;
  padding: 0px;
  letter-spacing: 0px;
  font-weight: 400;
  font-size: 18px;
  color: #ffffffdf;
}

.content-carousel {
  padding-top: 190px;
  padding-bottom: 190px;
}

.btn-style-one {
  position: relative;
  padding: 16px 45px 15px;
  line-height: 24px;
  text-transform: capitalize;
  background: var(--primary);
  color: #fff;
  border: 2px solid var(--primary);
  font-size: 18px;
  font-weight: 500;
  margin-top: 50px;
  display: inline-block;
}

.btn-style-one:hover {
  background: var(--second);
  color: #ffffff;
  border-color: var(--second);
}

a {
  text-decoration: none;
}

.carousel-control-next,
.carousel-control-prev {
  z-index: 99;
  opacity: 1;
  width: auto;
  height: fit-content;
  bottom: 0;
  top: inherit;
  background: #7777776b;
  color: #000;
  padding: 10px;
}

.carousel-control-next:hover,
.carousel-control-prev:hover {
  background: #777777;
}

.carousel-control-prev {
  left: inherit;
  right: 55px;
}

p {
  position: relative;
  color: #666666;
  font-size: 14px;
}

section {
  position: relative;
  padding: 80px 0;
}

.bg-cover {
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  background-position: center;
}

.section-title.text-center {
  text-align: center;
  background-position: top center;
}

.section-title h1 {
  font-weight: 500;
  font-size: 30px;
  margin: 0;
  color: #000;
}

.section-title h2 {
  font-weight: 500;
  font-size: 21px;
  margin: 0;
  color: #000;
}

.section-title.text-center p {
  margin-left: auto;
  margin-right: auto;
}

.section-title p {
  margin: auto;
  margin-top: 15px;
  width: 100%;
  font-size: 15px;
  line-height: 140%;
}

.one-text {
  color: var(--primary);

}

.one-text_date {
  color: var(--primary);
  font-size: 19px;
  font-weight: 500;
}


.tenderList_wrapper .asideWrapper {
  padding: 0 40px 40px 40px !important;
  position: relative;
}

.features-tab .nav-tabs a.active {
  background-color: var(--primary);
  color: #fff;
}

.features-tab .nav-tabs a.active:after {
  position: absolute;
  content: "";
  width: 15px;
  height: 15px;
  background-color: var(--primary);
  right: 0px;
  left: 0px;
  margin: auto;
  bottom: -6px;
  transform: rotate(45deg);
}

.features-tab .nav-tabs a {
  border: 1px solid #f5f5f5;
  border-radius: 0px;
  padding: 25px;
  color: #333;
  position: relative;
  text-transform: capitalize;
  background: #eeeeee;
  font-size: 15px;
}

.features-tab .nav-tabs a:hover {
  background-color: var(--primary) !important;
  color: #fff !important;
}

.features-tab .tab-content {
  padding: 40px 0 0;
}

.features-tab .innerTabContent {
  padding-right: 40px;
}

.features-tab p {
  font-size: 16px;
  line-height: 140%;
  margin-bottom: 40px;
}

.features-tab ul li {
  font-size: 16px;
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #d8d8d8;
  line-height: 140%;
}

.features-tab ul li:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
  border-bottom: none;
}

ul,
li {
  margin: 0px;
  padding: 0px;
  list-style: none;
  padding-inline-start: 0px;
}

.new-accordion .accordion-header {
  position: relative;
  padding: 15px 25px;
  margin-bottom: 20px;
  border: 2px solid #f8f8f8;
  background-color: #f8f8f8;
  margin-bottom: 0px;
}

.new-accordion .accordion-button {
  position: relative;
  font-size: 16px;
  cursor: pointer !important;
  line-height: 30px;
  color: #222222 !important;
  font-weight: 500;
  padding: 0;
  transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -webkit-transition: all 500ms ease;
  background-color: transparent !important;
  box-shadow: inset 0 0px 0 rgb(0 0 0 / 13%) !important;
}

.new-accordion .accordion-item {
  background-color: #fff;
  border: 0px solid rgba(0, 0, 0, 0.125);
  margin-bottom: 30px;
}

.new-accordion .accordion-item::last-child {
  margin-bottom: 0px;
}

.new-accordion .accordion-collapse.collapse.show {
  border: 2px solid #f8f8f8 !important;
  border-top: 0px solid !important;
}

.new-accordion .accordion-body {
  padding: 20px;
  margin-bottom: 0;
  font-size: 14px;
  line-height: 160%;
  color: #666666;
}

.owl-carousel .owl-item img {
  display: block;
  width: 100%;
  -webkit-transform-style: preserve-3d;
}

.gray-bg {
  background-color: #f5f5f5;
}

.list-style-three {
  position: relative;
}

.list-style-three li {
  position: relative;
  color: #ffffff;
  font-size: 16px;
  padding-left: 55px;
  font-weight: 400;
  display: inline-block;
  margin-right: 140px;
  margin-bottom: 20px;
}

.list-style-three li .icon {
  color: #ffffff;
  font-size: 26px;
}

.footer-style-two {
  position: relative;
  padding: 60px 0 60px;
  background: linear-gradient(60deg, rgb(82, 67, 170), rgb(237, 80, 180)) 0% 0%/auto repeat scroll padding-box border-box rgb(82, 67, 170);
}

.footer-style-two .upper-box {
  position: relative;
  padding: 40px;
  margin-bottom: 60px;
  background: #3c35522e;
}

.footer-style-two .upper-box ul {
  padding: 0;
  display: flex;
  align-items: center;
  gap: 30px;
}

.footer-style-two .upper-box ul li {
  margin: 0;
  flex: 1;
  display: flex;
  gap: 20px;
  padding: 0;
  padding-left: 30px;
  border-right: 1px solid #ffffff3d;
}

.footer-style-two .upper-box ul li:first-child {
  padding-left: 0;
}

.footer-style-two .upper-box ul li:last-child {
  border-right: none;
}

.footer-style-two .upper-box ul li .textHead {
  color: #ffffff9c;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 400;
}

.footer-style-two .upper-box ul li .address {
  margin-block: 12px 5px;
  font-size: 15px;
  line-height: 140%;
  font-weight: 400;
}

.footer-style-two .upper-box ul li .location {
  font-size: 15px;
  font-weight: 400;
}

.footer-style-two .widgets-section {
  position: relative;
}

.footer-style-two .widgets-section .footer-widget {
  position: relative;
  margin-bottom: 30px;
  padding-right: 40px;
}

.footer-style-two .widgets-section h2 {
  position: relative;
  color: #ffffff8f;
  font-size: 18px;
  margin-bottom: 30px;
  font-weight: 500;
  text-transform: uppercase;
}

.footer-style-two .widgets-section .about-widget .text {
  position: relative;
  color: #fff;
  font-size: 14px;
  line-height: 160%;
}

.nav-list {
  position: relative;
}

.footer-style-two .nav-list li {
  position: relative;
  margin-bottom: 15px;
  line-height: 140%;
  color: #fff;
  font-size: 15px;
  text-transform: capitalize;
}

.footer-style-two .nav-list li a {
  position: relative;
  color: #fff;
  font-size: 15px;
  transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -webkit-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
}

.footer-style-two .nav-list li a:hover {
  color: var(--third) !important;
}

.footer-style-two .lower-box {
  position: relative;
  padding: 30px;
  margin-top: 30px;
  margin-bottom: 50px;
  background: #3c35522e;
}

.social-icon-three {
  position: relative;
  display: flex;
  align-items: center;
  right: 10px;
}

.social-column .titleHead {
  width: 100%;
  color: #ffffffbd;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 16px;
}

.social-icon-three li {
  position: relative;
  color: white;
  cursor: pointer;
}

.social-icon-three li span {
  font-size: 16px;
}

.social-icon-three li span:hover {
  color: var(--bs-orange);
}

.social-icon-three li a {
  position: relative;
  width: 36px;
  height: 36px;
  margin-left: 0 !important;
  font-size: 14px;
  /* color: #626262999; */
  text-align: center;
  line-height: 36px;
  border-radius: 50%;
  display: inline-block;
  transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
}

.social-icon-three li a:hover {
  color: var(--primary);
}

.icons_back {
  color: var(--primary);
}

.footer-style-two .subscriber-column h3 {
  position: relative;
  color: #fff;
  font-size: 16px;
  font-weight: 400;
  line-height: 140%;
}

.subscribe-form {
  margin-top: 2px;
}

.subscribe-form .form-group {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0px;
  width: 100%;
  z-index: 1;
}

.subscribe-form .form-group input {
  position: relative;
  display: block;
  height: 56px;
  font-size: 16px;
  color: #000;
  padding: 15px;
  background: none;
  border: none;
  -webkit-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  transition: all 300ms ease;
  border-radius: 0;
  background: #fff;
}

.subscribe-form .form-group .input-group {
  flex: 4;
}

.subscribe-form .theme-btn {
  flex: 1;
  height: 56px;
  background: var(--bs-orange);
  color: white;
  border-radius: 0;
  border: none;
  border-left: 0;
  font-weight: 500;
  transition: .2s ease-in-out;
}

.subscribe-form .theme-btn:hover {
  background: var(--third)
}

.footer-style-two .copyright {
  position: relative;
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  text-align: center;
}

ul.main-menu>li>.dropdown-menu li a:hover {
  color: var(--primary);
  background-color: transparent;
}

.navbar-light .navbar-nav {
  gap: 30px;
}

.navbar-light .navbar-nav .nav-link {
  color: #717171;
  padding-block: 20px !important;
}

.navbar-light .navbar-nav .nav-link.active {
  position: relative;
  color: var(--bs-orange);
}

.navbar-light .navbar-nav .nav-link.active::after {
  position: absolute;
  bottom: -9px;
  left: 50%;
  transform: translate(-50%, 0);
  content: "";
  border-top: 10px solid #fff;
  border-right: 10px solid transparent;
  border-bottom: 0;
  border-left: 10px solid transparent;
  z-index: 991;
  opacity: 0;
}

.btn-get {
  background-color: var(--primary);
  color: #fff !important;
  padding: 3px 12px !important;
  border-radius: 50px;
  font-weight: bold;
  font-size: 13px !important;
  letter-spacing: 1px;
}

.btn-one {
  background-color: var(--primary);
  color: #fff;
  padding: 14px 1.5rem;
  font-weight: 500;
  font-size: 13px;
  letter-spacing: 1px;
  position: relative;
  z-index: 99;
}

.btn-outline-primary {
  border-color: var(--primary);
  color: var(--primary);
  padding: 12px 1.5rem;
}

.btn-get:hover,
.btn-outline-primary:hover,
.btn-one:hover {
  background-color: var(--hover);
  border-color: var(--hover);
  color: #fff !important;
}

.silder-videos {
  position: relative;
}

.videos-content {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

.video_sec {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: relative;
  object-fit: cover;
}

.silder-videos {
  padding-block: 110px;
  margin-top: 115px;
  position: relative;
}

.all-min-100 {
  min-height: 100vh;
  max-height: 100vh;
  height: 100vh;
}

.hero_banner {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  margin: 0 auto;
  z-index: 99;
}

.hero_banner img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.videos-from {
  position: relative;
  z-index: 999;
}

.videos-from .container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 40px;
}

.videos-from .container .banner_content h3 {
  color: white;
  font-size: 2.5rem;
  line-height: 140%;
  margin-bottom: 20px;
}

.videos-from .container .banner_content h2 {
  color: white;
  font-size: 2rem;
  font-weight: 400;
  line-height: 140%;
}

.videos-from .container .from-bg {
  box-shadow: 0 0 5px #00000030;
  background-color: #fff;
  padding: 30px;
  border-radius: 0;
  border-top: 3px solid var(--third);
  position: relative;
}

.videos-from .container .from-bg h4 {
  font-size: 1.2rem;
  color: #3f3f3f;
  margin-bottom: 20px;
}

.videos-from .container .from-bg .input-group {
  margin-bottom: 20px;
}

.from-bg .row {
  align-items: center;
}

.from-bg input,
.from-bg select {
  border-radius: 0;
  background-color: transparent;
  border: 1px solid #ccc;
  color: #000;
  font-size: 16px;
  padding: 0 15px;
  height: 56px;
}

.showOptions .css-ttwr4n {
  background-color: none !important;
}

/* .from-bg .css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {
  border-radius: 0;
  height: 60px;
} */

.from-bg .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root,
.css-p0rm37 {
  transform: translate(14px, 20px) scale(1);
  font-family: 'Poppins' !important;
}

.css-gdh49b-MuiAutocomplete-listbox .MuiAutocomplete-option,
.css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root,
.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root {
  font-family: 'Poppins' !important;
}

.from-bg .css-1d3z3hw-MuiOutlinedInput-notchedOutline,
.css-igs3ac {
  /* border: none !important; */
}

/* .from-bg .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select {
  height: 60px;
  line-height: 60px;
} */

.from-bg input::placeholder {
  color: #8b8b8b;
}

.form-input .css-1sumxir-MuiFormLabel-root-MuiInputLabel-root.Mui-focused,
.form-input .css-1sumxir-MuiFormLabel-root-MuiInputLabel-root,
.inputS-lable.MuiFormLabel-filled,
.inputS-lable.Mui-focused,
.form-input .css-u9osun {
  display: none !important;
}

/* .lable {
  width: fit-content !important;
  background: white;
  border: 1px solid #d2d2d2;
  padding: 8px 20px;
  border-radius: 50px;
  cursor: pointer;
  margin: 10px 0;
  transition: 0.3s ease-in-out;

  .d-flex {
    align-items: center !important;
  }

} */

.from-bg select {
  color: #6d6d6d;
  font-size: 16px;
}

input:focus-visible,
select:focus-visible {
  outline: none;
}

.search-bar-buttons-section {
  display: flex;
  align-items: center;
  gap: 20px;
  justify-content: center;
  margin-top: 30px;
}

.from-bg .mainButton {
  flex: 1;
  border: none;
  justify-content: center;
  padding: 0px 15px;
  height: 60px;
  border-radius: 4px;
}

.ghostButton {
  background: var(--bs-orange) !important;
  border: none;
}

.ghostButton:hover {
  background: var(--primary) !important;
}

input:focus,
select:focus {
  box-shadow: none;
}

.content-ts {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
  background-color: #fff;
}

.about-content-two h3 {
  font-size: 24px;
  color: #000;
}

.about-content-two h1 {
  font-weight: 400;
  margin-bottom: 15px;
  font-size: 27px;
  color: #000;
}

.Technical-Solutions {
  padding-bottom: 0;
}

.Technical-Solutions .section-title {
  margin-bottom: 80px;
}

.Technical-Solutions .section-title p {
  margin-left: 0;
}

.Technical-Solutions .rowFlex {
  display: flex;
  height: 630px;
}

.Technical-Solutions .rowFlex>* {
  flex: 1;
}

.Technical-Solutions .inner-right .item-title {
  margin: 0;
  margin-bottom: 25px;
  font-size: 24px;
}

.Technical-Solutions .mainButton {
  margin-top: 40px;
}

#TechnicalSolutions .owl-nav,
#sectorsDetails .owl-nav {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: max-content;
  margin: 0 auto;
}

#TechnicalSolutions .owl-nav button,
#sectorsDetails .owl-nav button {
  display: -webkit-inline-flex;
  display: -moz-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  width: 60px;
  height: 60px;
  line-height: 60px;
  justify-content: center;
  color: #fff;
  cursor: pointer;
  transition: all 250ms linear 0ms;
  font-size: 60px;
  margin: 0;
  border-radius: 0px;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  -ms-border-radius: 0px;
  -o-border-radius: 0px;
}

#TechnicalSolutions .owl-next,
#sectorsDetails .owl-next,
#Responsibility .owl-next {
  background-color: var(--primary);
}

#TechnicalSolutions button.owl-prev,
#sectorsDetails button.owl-prev {
  background-color: #ffffff;
  color: var(--primary);
}

.t-Solutions {
  background-color: var(--primary);
  padding: 60px;
  height: 100%;
  gap: 30px;
}

.t-Solutions i {
  color: white;
}

.Technical-Solutions .inner-right .item-title {
  color: #ffffff;
  font-size: 24px;
  letter-spacing: 0.03em;
}

.Technical-Solutions .inner-right li {
  font-size: 15px;
  margin-bottom: 18px;
  padding-bottom: 15px;
  border-bottom: 1px solid #ffffff57;
  display: flex;
  gap: 10px;
  line-height: 140%;
}

.Technical-Solutions .inner-right li:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
  border: none;
}

.item-description {
  font-size: 15px;
  color: #666;
  margin-top: 13px;
  margin-bottom: 10px;
  line-height: 1.7;
}

.Technical-Solutions .slick-slide img {
  height: 100%;
}

.Technical-Solutions .inner-right .item-description {
  color: #f9f9f9;
  font-size: 15px;
  margin-top: 0;
  line-height: 140%;
  margin-bottom: 30px;
}

.fs-90 {
  font-size: 90px;
}

.fs-60 {
  font-size: 60px;
}

.right.content-ts {
  left: inherit;
  right: 0px;
  top: 20px;
}

#sectorsDetails .owl-nav button {
  background-color: var(--primary);
  color: #fff;
}

#sectorsDetails button.owl-next {
  background-color: #fff;
  color: var(--primary);
}

/*================================ way Choose us   =============================*/

.css-14smmh3-MuiDataGrid-root .MuiDataGrid-columnHeaderTitle {
  font-weight: 500 !important;
}

.left-line-banner {
  background-image: url(../../img/Choose-bg.png);
  background-repeat: no-repeat;
}

/*================================ nav-tabs design neumor ui   =============================*/
ul.nav.nav-tabs {
  height: 4rem;
  box-shadow: 0rem 0rem 0.3rem #c8d0e773, 0rem 0rem 0.3rem #ffffff8a;
  border-radius: 1rem;
}

.forYou {
  background-image: url(../../img/bg-ForYou.png);
  background-size: cover;
}

ul.nav.nav-tabs li.nav-item {
  align-self: center;
}

ul.nav.nav-tabs li.nav-item .nav-link {
  height: 3.4rem;
  margin-left: 0.3rem;
  border-radius: 0.8rem;
  font-size: 0.8rem;
  font-weight: bold;
  padding: 0 12px !important;
  color: rgb(0 0 0 / 37%);
  letter-spacing: 0.5px;
}

ul.nav.nav-tabs li.nav-item .nav-link.active {
  box-shadow: inset 0rem 0rem 0.5rem #c8d0e7de, inset 0rem 0rem 0.5rem #ffffff;
  pointer-events: none;
  color: var(--primery);
}

.happy {
  padding: 0px 0 0;
  position: relative;
  margin-top: 60px;
}

.happy .top-counter {
  text-align: center;
  padding: 0 40px;
  position: relative;
  border-right: 1px solid #ffffff4d;
}

.happy .top-counter:last-child {
  border-right: none
}

.happy .flexCounter {
  display: flex;
  align-items: center;
  justify-content: center;
}

.happy .top-counter h3 {
  font-size: 35px;
  font-weight: 600;
  white-space: nowrap;
  letter-spacing: 0.01em;
  color: #fff;
  display: flex;
  align-items: center;
  gap: 2px;
  justify-content: center;
}

.happy .top-counter h3 svg {
  font-size: 28px;
  color: #ffffff;
}

.happy .top-counter h4 {
  color: #fff;
  font-weight: 400;
  margin-top: 15px;
  font-size: 15px;
}

.item :hover {
  box-shadow: 1 3px 25px solid rgb(0 0 0 / 20%);
}

.client-item-logo:hover {
  -webkit-box-shadow: 0 3px 25px 0 rgb(0 0 0 /20%);
  -khtml-box-shadow: 0 3px 25px 0 rgb(0, 0, 0, 0.2);
  -moz-box-shadow: 0 3px 25px 0 rgb(0, 0, 0, 0.2);
  -ms-box-shadow: 0 3px 25px 0 rgb(0, 0, 0, 0.2);
  -o-box-shadow: 0 3px 25px 0 rgb(0, 0, 0, 0.2);
  box-shadow: 0 3px 25px 0 rgb(0 0 0 /20%);
  border-color: transparent;
}

.client-item-logo {
  border: 1px solid #dedede;
  padding: 30px;
  position: relative;
  -webkit-transition: all 300ms linear 0s;
  -khtml-transition: all 300ms linear 0s;
  -moz-transition: all 300ms linear 0s;
  -ms-transition: all 300ms linear 0s;
  -o-transition: all 300ms linear 0s;
  transition: all 300ms linear 0s;
  margin: 0 30px;
  background: white;
}

.client-item-logo:hover {
  -webkit-box-shadow: 0 3px 25px 0 rgb(0 0 0 / 20%);
  -khtml-box-shadow: 0 3px 25px 0 rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 3px 25px 0 rgba(0, 0, 0, 0.2);
  -ms-box-shadow: 0 3px 25px 0 rgba(0, 0, 0, 0.2);
  -o-box-shadow: 0 3px 25px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 0 3px 25px rgb(0 0 0 / 20%);
  border-color: transparent;
}

.client-item-logo:before {
  content: "";
  display: block;
  width: 17px;
  height: 17px;
  background-color: initial;
  position: absolute;
  top: -1px;
  left: -1px;
  border-top: 3px solid var(--bs-orange);
  border-left: 3px solid var(--bs-orange);
  -webkit-transition: all 350ms ease 0s;
  -khtml-transition: all 350ms ease 0s;
  -moz-transition: all 350ms ease 0s;
  -ms-transition: all 350ms ease 0s;
  -o-transition: all 350ms ease 0s;
  transition: all 350ms ease 0s;
  opacity: 0;
}

.client-item-logo:after {
  content: "";
  display: block;
  width: 17px;
  height: 17px;
  background-color: initial;
  position: absolute;
  bottom: -1px;
  right: -1px;
  border-right: 3px solid var(--bs-orange);
  border-bottom: 3px solid var(--bs-orange);
  -webkit-transition: all 350ms ease 0s;
  -khtml-transition: all 350ms ease 0s;
  -moz-transition: all 350ms ease 0s;
  -ms-transition: all 350ms ease 0s;
  -o-transition: all 350ms ease 0s;
  transition: all 350ms ease 0s;
  opacity: 0;
}

.client-item-logo:hover:before,
.client-item-logo:hover:after {
  opacity: 1;
}

.w-500 {
  width: 500px;
}

.box-shadow-0 {
  box-shadow: 0 0 0 #000;
}

.contact_box_image {
  background-image: url(../../img/bg-Get-Quote.png);
  background-size: cover;
  position: relative;
  box-shadow: rgb(0 0 0 / 21%) 0px 0px 10px;
  padding: 50px 30px;
  border-radius: 0.7rem;
}

.pe-4-5 {
  padding-right: 2rem !important;
}

/*================================ table =====================*/
table.dataTable.display>tbody>tr.odd>.sorting_1,
table.dataTable.order-column.stripe>tbody>tr.odd>.sorting_1 {
  box-shadow: inset 0 0 0 0px rgb(0 0 0 / 5%) !important;
}

table.dataTable.order-column>tbody tr>.sorting_1,
table.dataTable.order-column>tbody tr>.sorting_2,
table.dataTable.order-column>tbody tr>.sorting_3,
table.dataTable.display>tbody tr>.sorting_1,
table.dataTable.display>tbody tr>.sorting_2,
table.dataTable.display>tbody tr>.sorting_3 {
  box-shadow: inset 0 0 0 0px rgb(0 0 0 / 2%) !important;
}

table.dataTable.stripe>tbody>tr.odd>*,
table.dataTable.display>tbody>tr.odd>* {
  box-shadow: inset 0 0 0 0px rgb(0 0 0 / 2%);
}

.slick-slide img {
  width: 100%;
}

.slick-next {
  right: 0px;
}

/* login page css */

html {
  height: 100%;
  scroll-padding-top: 150px;
}

body {
  height: 100%;
  margin: 0;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

/* Text Align */
.text-c {
  text-align: center;
}

.text-l {
  text-align: left;
}

.text-r {
  text-align: right;
}

.text-j {
  text-align: justify;
}

/* Text Color */
.text-whitesmoke {
  color: whitesmoke;
}

.text-darkyellow {
  color: rgba(255, 235, 59, 0.432);
}

/* Lines */

.line-b {
  border-bottom: 1px solid #ffeb3b !important;
}

/* Buttons */
.button {
  border-radius: 3px;
}

.form-button {
  background-color: rgba(255, 235, 59, 0.24);
  border-color: rgba(255, 235, 59, 0.24);
  color: #e6e6e6;
}

.form-button:hover,
.form-button:focus,
.form-button:active,
.form-button.active,
.form-button:active:focus,
.form-button:active:hover,
.form-button.active:hover,
.form-button.active:focus {
  background-color: rgba(255, 235, 59, 0.473);
  border-color: rgba(255, 235, 59, 0.473);
  color: #e6e6e6;
}

.button-l {
  width: 100% !important;
}

/* Margins g(global) - l(left) - r(right) - t(top) - b(bottom) */

.margin-g {
  margin: 15px;
}

.margin-g-sm {
  margin: 10px;
}

.margin-g-md {
  margin: 20px;
}

.margin-g-lg {
  margin: 30px;
}

.margin-l {
  margin-left: 15px;
}

.margin-l-sm {
  margin-left: 10px;
}

.margin-l-md {
  margin-left: 20px;
}

.margin-l-lg {
  margin-left: 30px;
}

.margin-r {
  margin-right: 15px;
}

.margin-r-sm {
  margin-right: 10px;
}

.margin-r-md {
  margin-right: 20px;
}

.margin-r-lg {
  margin-right: 30px;
}

.margin-t {
  margin-top: 15px;
}

.margin-t-sm {
  margin-top: 10px;
}

.margin-t-md {
  margin-top: 20px;
}

.margin-t-lg {
  margin-top: 30px;
}

.margin-b {
  margin-bottom: 15px;
}

.margin-b-sm {
  margin-bottom: 10px;
}

.margin-b-md {
  margin-bottom: 20px;
}

.margin-b-lg {
  margin-bottom: 30px;
}

/* Bootstrap Form Control Extension */

.form-control,
.border-line {
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 1px;
  color: inherit;
  display: block;
  padding: 6px 12px;
  transition: border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s;
  width: 100%;
}

.form-control:focus,
.border-line:focus {
  border-color: var(--bs-orange);
  background-color: inset 2px 2px 4px #dadee5;
  color: black;
}

.form-control,
.form-control:focus {
  box-shadow: none;
}

.form-control:focus {
  background-color: inset 2px 2px 4px #dadee5;
}

.form-control::-webkit-input-placeholder {
  color: #bdbdbd;
}

/* Container */

.container-content {
  background-color: #3a3a3aa2;
  color: inherit;
  padding: 15px 20px 20px 20px;
  border-color: #ffeb3b;
  border-image: none;
  border-style: solid solid none;
  border-width: 1px 0;
}

/* Backgrounds */

.main-bg {
  background: #424242;
  background: linear-gradient(#424242, #212121);
}

/* Login & Register Pages*/

.login-container {
  max-width: 400px;
  z-index: 100;
  margin: 0 auto;
  padding-top: 100px;
}

.login.login-container {
  width: 300px;
}

.wrapper.login-container {
  margin-top: 140px;
}

.logo-badge {
  color: #e6e6e6;
  font-size: 80px;
  font-weight: 800;
  letter-spacing: -10px;
  margin-bottom: 0;
}

.slick-prev,
.slick-next {
  display: none;
}

.tab-pane ul li b {
  color: #042847;
  font-size: 17px;
  margin-right: 10px;
}

.accordion-body li {
  font-size: 16px;
}

.accordion-body li:hover {
  cursor: pointer;
  color: #000;
  font-weight: 600;
}

.get_free_trial {
  box-shadow: 0rem 0rem 0.3rem #c8d0e773, 0rem 0rem 0.3rem #ffffff8a;
  border-radius: 1rem;
}

.btn-get-trial {
  padding: 9px 12px !important;
  border-radius: 50px;
  font-weight: bold;
  font-size: 13px !important;
  letter-spacing: 1px;
  text-align: center;
  background-color: var(--primary);
  color: #fff !important;
  width: 57%;
  margin: auto;
}

.btn-get-trial:hover {
  background-color: var(--hover);
  border-color: var(--hover);
  color: #fff !important;
}

.top-heading-cls {
  color: #042847;
}

/* get trial login form css */

.spContainer {
  width: 100%;
  max-width: 380px;
}

.card {
  border-radius: 8px;
  transition: 0.25s;
}

.card:hover {
  transition: 0.25s;
}

.btn-free-trial {
  border-radius: 50px;
  border: none;
  background-color: var(--primary);
  color: #fff;
  box-shadow: 0px 0px 0px 0px rgba(34, 36, 38, 0.15) inset,
    0px -0.4em 0px 0px rgba(34, 36, 38, 0.15) inset;
}

.btn-free-trial:hover,
.btn-free-trial:focus,
.btn-free-trial:active {
  background-color: var(--hover);
  border-color: var(--hover);
  color: #fff !important;
  box-shadow: none;
}

.inpSp {
  background-color: #ebebeb;
  border: none;
  transition: 0.25s;
  padding-left: 25px;
  font-weight: light !important;
  border-radius: 4px;
}

.col_m {
  margin: 92px;
}

.col_m_1 {
  margin: auto;
  width: 70%;
  margin-top: 36px;
}

.regStr {
  transition: 0.25s;
  padding-bottom: 4px;
  border-bottom: 2px solid gray;
  color: #212519;
}

.regStr:hover {
  border-bottom: 2px solid #ff425f;
  padding-bottom: 8px;
  transition: 0.25s;
  text-decoration: none;
  color: #212519;
}

.btn-sm {
  font-weight: 300;
}

.btn-sm:hover {
  font-weight: 900;
}

.refresh-code p {
  background-color: #ebebeb;
  color: #000;
  padding: 3px 0px 3px 12px;
  font-size: 15px;
  font-weight: bolder;
}

.tender-details-container {
  max-width: 1320px;
}

.text-shadow {
  display: flex;
  flex-direction: row !important;
  align-items: center;
  justify-content: center;
}

.dot-list {
  width: 9px;
  height: 9px;
  border-radius: 50%;
  background: var(--gradient-one);
  display: inline-table;
}

.inner-right {
  margin-bottom: 10px;
}

.container .box {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.container .box::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 25px;
  height: 100%;
  background: linear-gradient(#fff, #fff, #e3e3e3);
  filter: blur(1px);
  -webkit-filter: blur(1px);
  z-index: 1;
}

.container .box::after {
  content: "";
  position: absolute;
  top: 1px;
  right: -1px;
  width: 25px;
  height: 100%;
  background: #9d9d9d;
  filter: blur(1px);
  -webkit-filter: blur(1px);
  z-index: 1;
}

.container .box .shadow {
  position: absolute;
  width: 100%;
  height: 100%;
  background: #eee;
}

.container .box .shadow::before {
  content: "";
  position: absolute;
  top: 0;
  left: calc(100% + 96px);
  width: 120%;
  height: 240%;
  background: linear-gradient(rgba(0, 0, 0, 0.075), transparent);
  transform: skewX(45deg);
  -webkit-transform: skewX(45deg);
  -moz-transform: skewX(45deg);
  -ms-transform: skewX(45deg);
  -o-transform: skewX(45deg);
}

.container .box .shadow::after {
  content: "";
  position: absolute;
  bottom: -200%;
  left: calc(100% + 80px);
  width: 100%;
  height: 200%;
  background: linear-gradient(rgba(0, 0, 0, 0.075), transparent);
  transform: skewX(45deg);
  -webkit-transform: skewX(45deg);
  -moz-transform: skewX(45deg);
  -ms-transform: skewX(45deg);
  -o-transform: skewX(45deg);
}

.cover {
  position: absolute;
  top: 0;
  left: -100px;
  width: 100px;
  height: 100px;
  background: #cfd1e1;
  z-index: 10;
}

.content {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  height: 100%;
  background: linear-gradient(#dbdae1, #a3aaba);
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.1), 15px 15px 15px rgba(0, 0, 0, 0.1),
    20px 10px 20px rgba(0, 0, 0, 0.1), 50px 50px 85px rgba(0, 0, 0, 0.25),
    inset 3px 3px 2px #fff;
}

.content .form {
  position: relative;
  width: 260px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 15px;
}

.logo {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70px;
  height: 70px;
  background-color: #444;
  color: #fff;
  font-size: 1.75em;
  margin-bottom: 10px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}

.content .form h2 {
  font-size: 2em;
  color: #444;
}

.sub-box textarea {
  height: auto;
  padding: 15px !important;
}

.content .form .inputBox {
  position: relative;
  width: 100%;
  margin-top: 30px;
}

.content .form .inputBox input {
  position: relative;
  width: 100%;
  padding: 10px 0 10px 25px;
  font-size: 1em;
  background: transparent;
  box-shadow: none;
  border: none;
  outline: none;
  border-bottom: 3px solid #444;
  color: #444;
}

.content .form .inputBox i {
  position: absolute;
  left: 0;
  bottom: 15px;
  font-size: 1em;
  color: #444;
}

.content .form .inputBox span {
  position: absolute;
  left: 0;
  padding: 10px 0 10px 25px;
  font-size: 1em;
  pointer-events: none;
  color: #444;
  transition: 0.5s;
  -webkit-transition: 0.5s;
  -moz-transition: 0.5s;
  -ms-transition: 0.5s;
  -o-transition: 0.5s;
}

.content .form .inputBox input:focus~span,
.content .form .inputBox input:valid~span {
  transform: translateY(-20px);
  -webkit-transform: translateY(-20px);
  -moz-transform: translateY(-20px);
  -ms-transform: translateY(-20px);
  -o-transform: translateY(-20px);
  font-size: 0.9em;
  background-color: #444;
  color: #fff;
  padding: 2px 4px;
}

.content .form .links {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 15px;
}

.content .form .links a {
  color: #444;
  text-decoration: none;
  font-size: 0.9em;
}

.content .form .inputBox input[type="submit"] {
  background-color: #444;
  color: #fff;
  cursor: pointer;
  padding: 10px;
  font-size: 1em;
  transition: 0.5s;
  -webkit-transition: 0.5s;
  -moz-transition: 0.5s;
  -ms-transition: 0.5s;
  -o-transition: 0.5s;
}

.content .form .inputBox input[type="submit"]:hover {
  background-color: #333;
}

.social-links {
  margin: 20px 0;
}

.social-links div {
  width: 40px;
  height: 40px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin: 0 5px;
  border-radius: 50%;
  box-shadow: -5px -5px 10px #fff, 5px 5px 8px #babebc;
  cursor: pointer;
}

.social-links a {
  color: #000;
}

.social-links div:active {
  box-shadow: inset 1px 1px 2px #babebc, inset -1px -1px 2px #fff;
}

.state {
  margin-top: 20px;
}

.white-tab .nav {
  display: flex;
  flex-wrap: nowrap;
  position: relative;
  z-index: 1;
  border: 1px solid #efefef;
  overflow: hidden;
}

.white-tab li {
  width: 100%;
}

.white-tab .nav-item span {
  color: #797979;
  font-weight: 500;
  display: block;
  width: 100%;
  font-size: 16px;
  margin-right: 0;
  text-align: center;
  border: none;
  padding: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  cursor: pointer;
  border-right: 1px solid var(--bodyNew);
  background: white;
}

.white-tab .nav-item span i {
  font-size: 20px;
}

.white-tab .nav-item span:hover {
  background: #f7f7f7;
}

.white-tab .nav-item span.active {
  color: #fff;
  background: var(--primary);
}

.white-tab .border {
  background: #ffffff none repeat scroll 0 0;
  border: 1px solid #a9a9a9 !important;
  position: relative;
  margin-top: -2px;
}

.btn-search {
  background-color: var(--primary);
  color: #fff;
  font-weight: bold;
  font-size: 13px;
  letter-spacing: 1px;
  position: relative;
  z-index: 99;
  padding: 10px 1.5rem;
  white-space: nowrap;
  border: 0px solid transparent;
}

.btn-search:hover {
  background-color: var(--hover);
  color: #fff !important;
}

.from-bg input:focus {
  box-shadow: none;
}

.form-control-homepage:focus,
.form-select:focus {
  border-color: var(--primary) !important;
  box-shadow: none;
}

.dataTable td,
.dataTable td p {
  font: 14px "Open Sans", sans-serif;
  color: black;
  vertical-align: baseline;
  font-style: normal;
  line-height: 23px;
  letter-spacing: 1px;
}

.table-notice {
  position: absolute;
  bottom: 0;
  right: 0;
}

.white-space-nowrap {
  white-space: nowrap;
}

.filters-left .accordion-button {
  padding: 1rem 0px;
}

.filters-left .accordion-button:not(.collapsed) {
  color: #000 !important;
  background-color: transparent;
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.125);
}

.filters-left .accordion-item {
  border: 0px solid #ededed;
}

.filters-left .accordion-body {
  padding: 10px 0rem 30px;
}

.filters-left .accordion-item {
  border-bottom: 2px solid #ededed !important;
}

.btn-nebu {
  margin: auto;
  padding: 0.5rem 1.3rem;
  border: none;
  border-radius: 1rem;
  font-size: 1.1rem;
  transition: 0.3s ease;
  box-shadow: 0.3rem 0.3rem 0.6rem var(--greyLight-2),
    -0.2rem -0.2rem 0.5rem #fff;
  grid-column: 1/2;
  grid-row: 5/6;
  color: var(--primary);
}

.form-buttons {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.form-content {
  display: none;
  flex-direction: column;
  gap: 20px;
}

.form-content.active {
  display: flex;
}

.nebu-tab li span {
  text-decoration: none;
  color: var(--greyDark);
  font-weight: 700;
  font-size: 1.1rem;
  transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  border-radius: 0.4rem;
  padding: 0.3rem 0.7rem !important;
  cursor: pointer;
}

.nebu-tab li span:hover,
.nebu-tab li span.active {
  color: var(--primary);
  box-shadow: inset 0.2rem 0.2rem 0.5rem var(--greyLight-2),
    inset -0.2rem -0.2rem 0.5rem #fff;
}

.custommodal-textarea {
  height: 100px !important;
}

.faq-link-cls {
  color: #0d6efd;
}

.span-hover-cls:hover {
  color: var(--hover) !important;
}

.css-1hv8oq8-MuiStepLabel-label.Mui-active {
  font-weight: 900 !important;
}

.profile-edit-btn {
  padding: 14px 1.5rem;
}

.flex-row {
  gap: 18px;
  display: flex;
  justify-content: center;
}

/* .----------- */
.Technical-Solutions .slick-prev,
.Technical-Solutions .slick-next {
  display: block !important;
  width: 50px !important;
  height: 50px !important;
  top: initial !important;
  bottom: 20px;
  display: flex !important;
  justify-content: center !important;
  line-height: 40px !important;
}

.Technical-Solutions .slick-prev {
  left: auto !important;
  right: 110px !important;
  z-index: 9;
  background: white !important;
  color: var(--primary) !important;
}

.Technical-Solutions .slick-prev span,
.Technical-Solutions .slick-next span {
  font-size: 50px !important;
}

.Technical-Solutions .slick-next {
  right: 50px !important;
  background: var(--primary) !important;
  color: #fff !important;
}

.Technical-Solutions .slick-prev:before,
.Technical-Solutions .slick-next:before {
  display: none !important;
}

.Technical-Solutions .slick-prev:hover span,
.Technical-Solutions .slick-next:hover span {
  color: inherit !important;
}

.topScoll,
.downScoll {
  right: 1.5%;
  position: fixed;
  bottom: 13%;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: var(--bs-orange);
  color: white;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  cursor: pointer;
  transition: 0.3s ease-in-out;
}

.topScoll:hover,
.downScoll:hover {
  opacity: 0.8;
}


.downScoll:hover {
  opacity: 0.8;
}

.city_container {
  overflow: hidden !important;
}

.tab-content,
.tab-content>.active {
  height: 100%;
}

.spinerWrap {
  height: 512px;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* =========== Image Preview ========== */
.fileWapper {
  width: fit-content;
  display: flex;
  align-items: center;
  gap: 40px;
  position: relative;
}

.imageUpload {
  border: 1px solid #cdcff7;
  box-shadow: 0 0 5px #0000003d;
  padding: 10px;
  border-radius: 50%;
  width: 140px;
  height: 130px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #e3e3e3;
}


.fileWapper input {
  opacity: 0;
  position: absolute;
  inset: 0;
  z-index: 1;
  cursor: pointer;
  width: 150px;
  height: 150px;
}

.imageUpload .previewImage {
  position: absolute;
  inset: 0;
  z-index: 0;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.previewImage img {
  width: 100%;
  height: 100%;
  border-radius: 50px;
  object-fit: cover !important;
}

.fileName {
  font-size: 14px;
  padding: 12px 20px;
  background: var(--bs-orange);
  color: white;
  border-radius: 4px;
  text-align: center;
  display: flex;
  justify-content: center;
}

.fileName:hover {
  background: var(--primary);
}

.placeHolder {
  opacity: 0.3;
  object-fit: contain !important;
}

.text-contain-tender {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

/* support image */

.imageUpload_support {
  border: 1px solid #cdcff7;
  box-shadow: 0 0 5px #0000003d;
  padding: 10px;
  border-radius: 15%;
  width: 160px;
  height: 140px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #e3e3e3;
}

.imageUpload_support .previewImage_support {
  position: absolute;
  inset: 0;
  z-index: 0;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.previewImage_support img {
  width: 100%;
  height: 100%;
  border-radius: 15px;
  object-fit: fill !important;
}

/* =========== Partner ========== */

.partner {
  background: var(--bodyNew);
}

/* ========= Modal Css ======= */

.modal-content {
  background: white;
  box-shadow: 0 45px 65px rgba(0, 0, 0, 0.50), 0 35px 22px rgba(0, 0, 0, 0.16);
  z-index: 120;

}

.modalHeader {
  font-size: 18px;
  color: #000;
  font-weight: 500;
  background: var(--bodyNew);
  padding: 20px;
}

.modalBody {
  padding: 30px;
}

.modal-content .labelText {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 15px;
}

.modal-content .mainButton {
  padding: 14px 25px;
  font-size: 15px;
  border-radius: 4px;
}

.modal-content .mainButton:focus {
  outline: 2px solid var(--third);
  outline-offset: 2px;
  background: inherit;
}

.errorMsg {
  font-size: 13px;
  text-align: left;
  font-weight: 500;
  margin-top: 8px;
}

.modal-content .btn-primary.disabled,
.modal-content .btn-primary:disabled {
  color: #fff;
  background-color: #979797;
  border-color: #979797;
}

.selectInput.form-input {
  padding: 0 !important;
  height: auto;
  min-height: 50px;
}

.selectInput .css-13cymwt-control,
.selectInput .css-t3ipsp-control {
  min-height: 50px;
  max-height: 100px !important;
  overflow: auto;
  border-radius: 0;
  border: none;
  background: transparent;
}

.selectInput .css-1hb7zxy-IndicatorsContainer {
  align-items: baseline;
  padding-top: 7px;
}

.selectInput .css-t3ipsp-control {
  box-shadow: none;
  border: none;
}

.selectInput .css-1p3m7a8-multiValue {
  background: white;
}

.selectInput .css-1fdsijx-ValueContainer {
  padding-inline: 12px;
}

.btnSpinner {
  width: 1rem;
  height: 1rem;
  margin-left: 10px;
}

.fullLoader {
  width: 100%;
  display: flex;
  justify-content: center;
}

.fullLoader .btnSpinner {
  width: 2rem;
  height: 2rem;
  margin-left: 0;
}

/* ---- */

.notFoundData {
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  gap: 20px;
  flex-direction: column;
}

.notFoundData img {
  width: 200px;
}

/* ============== */

.slick-dots li button:before {
  font-size: 10px !important;
}

.slick-dots li.slick-active button:before {
  color: var(--primary) !important;
}

.slick-dots li button:hover:before,
.slick-dots li button:focus:before {
  color: var(--primary) !important;
}

.addFont {
  font-size: 30px !important;
}

.lessFont {
  font-size: 10px !important;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

/* ================= Pagination css ================= */

div.pagination {
  margin-top: 30px;
  justify-content: flex-end;
}

div.pagination .page-item.disabled .page-link {
  background-color: #e4e4e4;
}

div.pagination .page-link {
  color: var(--primary);
  background-color: #fff;
  border-color: #e7e7e7;
  padding: 10px 16px;
  font-size: 14px;
  font-weight: 400;
}

div.pagination .page-link:hover {
  background: #6d6489;
  color: white;
  border-color: #6d6489;
}

div.pagination .page-item {
  border: none;
}

div.pagination .page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: var(--third);
  border-color: var(--third);
}

.page-link:focus {
  box-shadow: none;
}

.menu_profile {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 10px;
}

/* ============= */

.chrNAmes {
  color: #fff;
  background-color: #bdbdbd;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 14px;
  min-width: auto;
}

/* 21 apr khushbu */
.footer-hover li:hover {
  cursor: pointer;
  color: #ffffff54;
  opacity: 5;
}

.footer-hover li span:hover {
  border-bottom: 1px solid #ffffff54;
}

.remove-height-cls {
  min-height: 0px !important;
}

.login_popup {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: 35px;
  gap: 25px;
}

.modal_heading {
  font-size: 21px;
  font-weight: 500;
  line-height: 160%;
  color: #000;
}

.modal_det {
  font-size: 16px;
}

.css-izo0hb-MuiBackdrop-root {
  z-index: 9999 !important;
}

.css-18sg6k4-MuiPaper-root-MuiDrawer-paper {
  z-index: 99999 !important;
}

/* -------------- */

.MuiDataGrid-columnHeaders {
  border-bottom: 1px solid #d9d9d9 !important;
}

.MuiDataGrid-columnHeader--sortable {
  background: #efefef !important;
}

.navbar-light .navbar-brand {
  cursor: pointer;
}

.css-10nakn3-MuiModal-root-MuiPopover-root-MuiMenu-root {
  z-index: 99999 !important;
}


.MuiDataGrid-columnHeaders {
  background: #efefef !important;
  border-bottom: 1px solid #d9d9d9 !important;
}

.MuiDataGrid-columnHeader--sortable {
  background: #efefef !important;
}

.navbar-light .navbar-brand {
  cursor: pointer;
}

.MuiPopover-root.MuiMenu-root {
  z-index: 99999 !important;
}

.comp_credit {
  color: #000;
}

.other_popup {
  margin-top: 24px !important;
}

.share_link {
  background: #faf8f8;
  border: none;
  height: 43px;
  /* padding-left: 48px; */
  max-width: 357px !important;
  overflow: auto !important;

}

.inputIcons {
  position: relative;
  padding-right: 50px;
}

.copyIcons {
  position: absolute;
  top: 7px;
  left: 7px;
  color: #a19d9d;
  z-index: 1000;
  rotate: 143deg;
}

.copy_icons {
  position: absolute;
  right: 32px;
  background: var(--bs-orange);
  color: #ffffff;
  cursor: pointer;
  font-size: 14px !important;
  z-index: 1000;
  z-index: 1000;
}

.copy_icons:hover {
  background: var(--primary);
  color: #fff;
}

.social_iconPopp {
  width: 45px;
  height: 45px;
  border: 1px solid var(--greyLight-2);
  text-align: center;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
  margin-right: 10px;
}

.share_link:focus {
  background-color: #faf8f8 !important;
}

/* y */

.socials_media .social-icon-three {
  justify-content: center !important;
}

.social_iconPopp span:hover {
  color: #fff !important;
}

.social_iconPopp span svg {
  font-size: 25px !important;
}

.social_iconPopp span .facebook {
  color: #11a2db;
}

.social_iconPopp span .twitter {
  color: #0f9ad1;
}

.social_iconPopp span .whatsaapp {
  color: #098c7c;
}

.social_iconPopp span .linkedin {
  color: #0072b1;
}

.social_iconPopp span .email {
  color: #EA4335;
}

.MuiDataGrid-menuIconButton {
  display: none !important;
}

.css-13co45f-MuiDataGrid-root .MuiDataGrid-iconSeparator {
  margin-right: 15px !important;
}

.input_social {
  height: 45px !important;
  border: 1px solid var(--primary);
}

.close {
  text-align: right;
  font-size: 18px;
  font-weight: 600;
  cursor: pointer;
}

.close:hover {
  text-align: right;
  color: #ff000098;
  transition: 0.7s;

}

.MuiBackdrop-root.css-izo0hb-MuiBackdrop-root {
  z-index: 999 !important;
}

.model_login_popup .formWarpper {
  padding: 0 !important;
}

.model_login_popup .formWarpper .form__icons {
  margin-block: 10px 10px;
  flex-direction: column;
}

.model_login_popup .formWarpper .orItem {
  width: 60% !important;
}

.model_login_popup .signUpLink {
  font-size: 13px !important;
  text-align: center;
}

.model_login_popup .signUpLink button {
  border: none;
  background: none;
}

.model_login_popup .signUpLink button:hover {
  color: var(--bs-orange);
}

.subscribe-form .theme-btn,
.subscribe-form .form-group input {
  height: 40px;
}

.breadcrumb {
  align-items: center;
  /* gap: 8px; */
  position: sticky;
  top: 114px;
  border: 0;
  z-index: 1000;
  margin-bottom: 0 !important;
}

ul.breadcrumb {
  padding: 12px 30px;
  list-style: none;
  background-color: #fff;
  margin-top: 114px;
}

ul.breadcrumb li {
  display: inline;
  font-size: 14px;
  cursor: pointer;
  font-weight: 500;
  color: #656565;
}

.breadcrumb .active {
  color: var(--primary) !important;
}

ul.breadcrumb .arrow_icons {
  font-size: 14px !important;
}

ul.breadcrumb li a {
  color: #0275d8;
  text-decoration: none;
}

ul.breadcrumb li a:hover {
  color: #01447e;
  text-decoration: underline;
}

.side_cardsss {
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
}

.modeler_fleser {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  line-height: 140%;
}

.add_number_close {
  cursor: pointer;
}

.add_number_close:hover {
  color: var(--bs-orange);
}

.asideWrapper {
  padding: 53px 15px 30px 15px !important;
}

.sleteon_box {
  display: flex;
  justify-content: right;
  align-items: flex-end;
  text-align: right;
}

.eye_position {
  position: relative;
}

.eye_visible {
  position: absolute;
  right: 10px;
  top: 15px;
  cursor: pointer;
}

.dropdown_organization {
  margin-bottom: 22px !important;
}

.clients_header_head .dropdown-item {
  line-height: 140%;
}

.clients_header_head .dropdown-item:focus,
.clients_header_head .dropdown-item:hover {
  background: none !important;
}

.textarea_heading_pop {
  font-size: 13px;
}

.cursor-auto {
  cursor: context-menu !important;
}

.model_header_flex {
  display: flex;
  gap: 10px;
}

.data_foundDiv {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.add_collectionBtn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 25px;
}

.wishlist_tenderCont {
  display: flex !important;
  justify-content: space-around !important;
}

.wishlist_tenderCont .buttonGrp {
  display: flex;
  align-items: center;
  gap: 20px;
  flex: 1 1;
  justify-content: space-around;
}

.wishlist_tenderCont .buttonGrp .MuiFormControl-root {
  width: 150px !important;
}

.css-1b54hrp-MuiDataGrid-root .MuiDataGrid-row:not(.MuiDataGrid-row--dynamicHeight)>.MuiDataGrid-cell {
  white-space: normal !important;
  padding: 10px;
}

.css-1b54hrp-MuiDataGrid-root .MuiDataGrid-row {
  max-height: auto !important;
}

.css-1ndpvdd-MuiTableCell-root {
  text-align: left !important;
  font-family: 'Poppins', sans-serif !important;
}

.blog_commentsSection .MuiOutlinedInput-input {
  border: 1px solid #cccccc !important;
}


.css-l3ln04-MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-input {
  border: none !important;
}

.css-1lambp5 {
  border: none !important;
}

/* ============== */

.videos-from .MuiInputBase-input {
  height: 38px !important;
  border: none !important;
  padding-right: 40px !important;
}

.videos-from .MuiOutlinedInput-input,
.videos-from .css-feqhe6 {
  height: 60px !important;
  padding: 0 10px !important;
}

.form-control-homepage .MuiOutlinedInput-input {
  border: 1px solid #ccc !important;
  padding: 0 !important;
  height: auto !important;
}

.form-control-homepage .MuiInputBase-formControl {
  height: auto !important;
}

.form-control-homepage .MuiOutlinedInput-notchedOutline {
  border: none !important
}

.form-control-homepage .MuiOutlinedInput-root {
  flex-wrap: nowrap !important;
  overflow-x: auto !important;
  max-width: 550px !important;
}

.form-control-homepage .MuiAutocomplete-clearIndicator {
  display: none !important;
}

.videos-from .css-l3ln04-MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-input,
.videos-from .css-1uvydh2 {
  padding: 0 !important;
  padding-right: 63px !important;
}

.videos-from .MuiAutocomplete-hasPopupIcon.css-l3ln04-MuiAutocomplete-root .MuiOutlinedInput-root,
.MuiAutocomplete-hasClearIcon.css-l3ln04-MuiAutocomplete-root .MuiOutlinedInput-root,
.videos-from .css-segi59 {
  padding: 0 !important;
}

.social-icon-three .css-i4bv87-MuiSvgIcon-root,
.social-icon-three .css-vubbuv {
  font-size: 22px !important;
  color: #fff;
}

.social-icon-three .css-i4bv87-MuiSvgIcon-root:hover,
.social-icon-three .css-vubbuv:hover {
  cursor: pointer;
  color: #098c7c;
  opacity: 5;
}

.responsive_size .css-i4bv87-MuiSvgIcon-root {
  font-size: 18px !important;
}

.message {
  height: 7px;
}


.tenderList_wrapper .MuiAutocomplete-endAdornment,
.css-2iz2x6 {
  position: absolute;
  right: 0;
  top: 12px !important
}

.tenderList_wrapper .css-l3ln04-MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-input,
.tenderList_wrapper .css-1uvydh2 {
  padding: 0px 60px 0 15px !important;
}

.tenderList_wrapper fieldset,
.css-igs3ac {
  /* display: none !important; */
}

.css-l3ln04-MuiAutocomplete-root .MuiAutocomplete-inputRoot .MuiAutocomplete-input,
.css-1uvydh2 {
  height: 100%;
}

.clearImage {
  position: absolute;
  top: 0;
  left: 91px;
  background: white;
  z-index: 9;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.clearImage svg {
  height: 18px;
  width: 18px;
}

.dropdown-item.active,
.dropdown-item:active {
  text-decoration: none;
  background-color: none !important;
  color: rgb(62, 60, 60);
}

.css-137ul40-MuiButtonBase-root-MuiIconButton-root {
  margin-left: 0 !important;
  border: 1px solid #e9e9e9 !important;
}

.updated_buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
}

.update_buttont_font {
  font-size: 19px;
  font-weight: 500;
}

.updated_buttons button {
  padding: 15px 18px !important;
}

.drawer_cards {
  font-size: 15px !important;
  font-weight: 500 !important;
}

.history_drawer .card-title {
  padding: 12px;
  margin: 0 !important;
  background: #e3e3e3;
  border-top-left-radius: 8px;
  border-top-right-radius: 8pxs;
}

.history_drawer .card-title h1 {
  font-size: 14px;
  color: #000 !important;
  text-align: center;
  margin-bottom: 0 !important;
}

.history_drawer .drawer_cards {
  font-size: 14px !important;
  font-weight: 500;
  border-bottom: 1px solid #eee;
  margin-bottom: 12px;
  padding-bottom: 12px;
}

.history_drawer .drawer_cards span {
  color: #747272 !important;
  margin-right: 5px;
  display: block;
  margin-bottom: 10px;
}

.history_drawer .another_table br {
  display: none;
}

.history_drawer .drawer_cards b {
  font-weight: 500 !important;
  color: #000 !important;
}

.history_drawer .drawer_cards b.text-change {
  color: var(--bs-orange) !important;
}

.history_drawer .another_table {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 5px;
}

.history_drawer .card-body {
  padding: 1.5rem !important;
}

.history_drawer .current_body {
  background: #f3f3f3;
}

.updated_container h1 {
  font-size: 20px !important;
}

.profile_finall {
  display: flex;
  justify-content: space-between;
  text-align: center;
  align-items: center;
  padding: 5px;
  margin: 0 !important;
  background: #e3e3e3;
  width: 100%;
}

.dropdownpayper {
  width: 250px;
}

.dropdownpayper .form-input {
  height: 45px !important;
}

.dropdownpayper .MuiSelect-select {
  text-align: left;
  padding-left: 20px;
}

.viewPurchased_tender .card-title {
  padding: 12px;
  margin: 0 !important;
  background: #e3e3e3;
}

.tenderDetails_warpper .card-title {
  padding: 16px 20px;
  margin: 0 !important;
  background: #e3e3e3;
}

.Mui-selected {
  background-color: rgb(120 120 120 / 17%) !important;
}

input[type="number"] {
  -moz-appearance: textfield !important;
}

/* For Chrome */
input[type="number"] {
  -webkit-appearance: none;
}

.css-1pe4mpk-MuiButtonBase-root-MuiIconButton-root,
.css-1qgma8u-MuiButtonBase-root-MuiTableSortLabel-root.Mui-active .MuiTableSortLabel-icon,
.css-1vweko9-MuiSvgIcon-root-MuiTableSortLabel-icon {
  display: none !important;
}

/* =========== */

.MuiAutocomplete-popper {
  z-index: 999999 !important;
}

.MuiInputBase-formControl {
  height: 54px !important;
  padding: 0 !important;
}

.favoitte_inpiy {
  padding-right: 0 !important;
  width: 200px !important;
}

.my_fav_input .MuiInputLabel-shrink.Mui-focused,
.my_fav_input .MuiInputLabel-shrink {
  display: none !important;
}

.my_fav_input {
  /* margin-top: 20px; */
}

.collection_div .MuiInputLabel-shrink.Mui-focused,
.collection_div .MuiInputLabel-shrink {
  display: none !important;
}

/* .my_fav_input .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
  padding-right: 5px !important;
} */

.react-datepicker-popper {
  z-index: 9 !important;
}

.tendernav {
  position: sticky;
  top: 120px;
  /* background: white; */
  z-index: 9;
}

/* .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
  padding-right: 146px !important;
} */

/* .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select {
  height: auto;
  min-height: 1.4375em;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  left: 14px;
  margin-left: 10px !important;
} */

.my_fav_input .form-input .MuiSelect-select {
  margin-left: 15px !important;
}

.remove-fixed-cls {
  position: absolute !important;
}

.remove-static-cls {
  position: static !important;
}

.remove-top-cls {
  top: 0px !important;
}

/* ============== */
.MuiButtonBase-root {
  font-family: 'Poppins', sans-serif !important;
}

.terms_policy .terms_policyCont {
  margin-top: 2rem;
  color: #333;
  font-size: 18px;
  font-weight: 600;
  padding: 0 !important;
}

.terms_policy p {
  line-height: 150% !important;
  font-size: 15px !important;
  font-weight: 400 !important;
}

.terms_tableCont ul {
  padding-left: 20px;
}

.terms_tableCont ul li {
  line-height: 150% !important;
  font-size: 15px !important;
  font-weight: 400 !important;
  margin-bottom: 15px;
}

.terms_policy .section-title>* {
  flex: 1 1;
}

.conditons_div .section-title h1 {
  color: var(--primary);
}

.conditons_div .section-title h2 {
  color: var(--primary);
}

.conditons_div h3 {
  color: #312929 !important;
}

.conditons_div a {
  color: var(--bs-orange);
}

.conditons_div a:hover {
  color: var(--primary) !important;
}

.terms_policy a {
  color: var(--bs-orange);
}

.terms_policy a:hover {
  color: var(--primary) !important;
}

.tickets_mainCont {
  display: flex;
  justify-content: space-between;
}

.tickets_mainCont img {
  border-radius: 6px;
  height: 100px;
  object-fit: cover;
}

.supports_bosImg {
  border: none !important;
}

.supports_bosImg:focus {
  outline: none !important;
}

.supports_bosImg img {
  width: 100% !important;
}

.buttonGrp .button_purchased {
  display: flex;
  align-items: center;
  gap: 15px;
}

/* .my_fav_input .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input,
.css-z8fhq1-MuiDataGrid-columnHeaders,
.css-s1v7zr-MuiDataGrid-virtualScrollerRenderZone {
  font-family: "Poppins" !important;
} */

.wishlist_tenderCont .css-qiwgdb.css-qiwgdb.css-qiwgdb {
  padding-right: 32px;
  padding-left: 10px;
}

.MuiOutlinedInput-notchedOutline {
  border: 1px solid #ccc;
}

.css-xps71o-MuiDataGrid-root .MuiDataGrid-columnHeader:focus,
.css-xps71o-MuiDataGrid-root .MuiDataGrid-cell:focus {
  outline: none !important;
}

.MuiDrawer-root.MuiDrawer-docked.filterWrapper.advanceFilterCss.css-gz7wzm-MuiDrawer-docked {}

.profile_wrapper .css-1b54hrp-MuiDataGrid-root .MuiDataGrid-columnHeader:focus,
.css-1b54hrp-MuiDataGrid-root .MuiDataGrid-cell:focus,
.profile_wrapper .css-tzjenb .MuiDataGrid-columnHeader:focus,
.css-tzjenb .MuiDataGrid-cell:focus {
  outline: none !important;
}

.css-1ciwpa8 {
  z-index: 10000 !important;
}

/* ============== */
.disclaimer_wrap {
  padding: 10px;
  background: #eeeeee;
  border-radius: 6px;
}

.disclaimer_wrap .title {
  font-size: 18px;
  line-height: 140%;
  margin-bottom: 15px;
  font-weight: 600;
}

.disclaimer_wrap ul li {
  font-size: 14px;
  color: #383838;
  margin-bottom: 10px;
}

.disclaimer_wrap ul li:last-child {
  margin-bottom: 0;
}

.calculated-amount {
  margin-top: 10px;
  text-align: right;
  color: #000;
  font-weight: 500;
}

.MuiMenuItem-root {
  text-transform: capitalize;
}


.hide--buttons-media {
  display: none;
}

.navbar_close,
.navbar_inner_close {
  display: none;
}

.add-coupons-text {
  display: flex;
  justify-content: flex-end;
  margin-top: 0.5rem;
  font-size: 0.8rem;
}

.add-coupons-text a {
  color: grey !important;
}

.add-coupons-text a:hover {
  color: #cd1f7c !important;
}

.pb__dte_item ul {
  padding-left: 20px;
}

.pb__dte_item ul li {
  list-style: disc;
}

.grid-icon,
.why_tender img,
.queries_mail {
  display: none;
}

.phone-advance-button {
  display: block;
}

.latestTenders .slickItem .client__det {
  font-size: 12px;
}

.latestTenders .slickItem .titleHead {
  margin-block: 15px 14px;
}

.latestTenders .slickItem .client__det .span {
  font-size: 13px;
}

.latestTenders .slickItem .client__det {
  margin-bottom: 25px;
}

.latestTenders .heding__wrap {
  gap: 10px;
}

.publicationCss {
  margin-top: 20px;
}

.header_side_menu {
  display: flex;
  width: 100%;
}

.css-1jy569b-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
  display: none !important;
}

.MuiInputLabel-shrink {
  display: none !important;
}

.MuiAutocomplete-endAdornment {
  top: calc(50% - 12px) !important;
}

.MuiAutocomplete-input {
  padding: 0.5px 4px 7.5px 5px;
}

.MuiDataGrid-columnHeaderTitle {
  font-weight: 500 !important;
}

.css-1eefx3i-MuiDataGrid-root .MuiDataGrid-columnHeaderTitle {
  font-weight: 500 !important;
}

/* checkout page css */
/* mansi 26 oct */

.overlay_checkout {
  position: absolute;
  top: 0;
  background: rgb(0, 0, 0);
  background: rgba(0, 0, 0, 0.5);
  /* Black see-through */
  color: #f1f1f1;
  width: 100%;
  height: 100%;
  transition: .5s ease;
  opacity: 1;
  color: white;
  font-size: 20px;
  padding: 20px;
  text-align: center;
  z-index: 9999;
}

.loader {
  position: relative;
  top: 50%;
  color: #fff;
}

.checkout_container {
  padding-bottom: 30px;
  padding-inline: 100px;
  background: var(--bodyNew);
  height: 100vh;
}

.checkout_container .ant-card-body {
  padding: 30px !important;
}

.all_checkout_conatiner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.checkout_image {
  display: flex;
  justify-content: center;
  padding: 30px 60px 60px 60px;
}

.avialable_checkout_container {
  margin-left: 15px;
}

.available_checkout {
  font-size: 17px;
  font-weight: 600;
  text-transform: capitalize;
  padding: 0 !important;
  display: flex;
  gap: 10px;
}

.available_highlight_amonut {
  font-size: 25px;
  font-weight: 700;
  color: var(--bs-orange);
}

.couponsicon_checkout {
  font-size: 17px;
  font-weight: 600;
  text-transform: capitalize;
  padding: 0 !important;
  margin-top: 15px;
}

.checkout_buttons {
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 25px 16px 0 25px;
}

.checkout_buttons .mainButton {
  background: var(--primary) !important;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  padding: 20px 25px;
  transition: .3s ease-in-out;
  height: 50px;
  display: flex;
  justify-content: center;
  width: 100%;
}

.checkout_buttons .mainButton span {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 15px;
  font-weight: 400;
  color: #fff;
  letter-spacing: .4px;
}

.checkout_buttons .mainButton:hover {
  background: #cd1f7c !important;
  color: #fff !important;
}

.checkout_buttons .ghostButton {
  background: var(--bs-orange) !important;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  padding: 20px 25px;
  transition: .3s ease-in-out;
  height: 50px;
  display: flex;
  justify-content: center;
  width: 100%;
}

.checkout_buttons .ghostButton:hover {
  background: var(--primary) !important;
  color: #fff !important;
}

.checkout_buttons .ghostButton span {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 15px;
  font-weight: 400;
  color: #fff;
  letter-spacing: .4px;
}

.coupons-filter-cards .col-sm-12 {
  padding: 0 !important;
}

.coupons-declaimer p {
  font-size: 13px !important;
}

.coupons-declaimer span {
  font-size: 13px !important;
}

.coupons-declaimer .coupons-code p {
  font-size: 12px;
}

.coupons-declaimer .coupons-code span {
  font-size: 16px;
}

.radio_button_checkout {
  display: flex;
  gap: 8px;
  align-items: center;
}

.radio_button_checkout svg {
  width: 20px;
  color: var(--bs-orange);
}

.checkout_container .boBack_button {
  position: absolute;
  top: 60px;
  left: 100px;
  width: 40px;
  height: 40px;
  background: var(--bs-orange);
  z-index: .9;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.checkout_container .boBack_button svg {
  color: white;
}

.disclaimer_checkout {
  padding: 4px 12px 12px 25px;
}

.add_money_checkout .card {
  display: flex;
  flex-direction: row;
  padding: 30px 20px 5px 20px;
}

.add_money_checkout .card .enrollment_amount_card_checkout {
  display: grid;
  gap: 10px;
}

.add_money_checkout .card .enrollment_amount_card_checkout span {
  font-size: 14px;
  font-weight: 500;
}

.add_money_checkout .card .enrollment_amount_card_checkout p {
  font-size: 20px;
  font-weight: 600;
  margin-top: 10px;
  margin-bottom: 0;
  color: #000;
}

.add_money_checkout .card .enrollment_amount_card_checkout span svg {
  width: 15px;
  cursor: pointer;
}

.enroll_amount_checkout_rs {
  display: grid;
  justify-content: end;
  gap: 10px;
}

.enroll_amount_checkout_rs span {
  font-size: 14px;
  font-weight: 500;
}

.enroll_amount_checkout_rs p {
  font-size: 20px;
  font-weight: 500;
  margin-top: 10px;
  color: #000;
}

.checkout_container .coupons-code {
  align-items: center;
}

.checkout_container .coupons-code p {
  font-size: 12px;
  margin-bottom: 0;
}

.planDetail_checkout .card {
  padding: 20px;
}

.planDetail_checkout {
  margin-top: 49px;
}

.checkout_container .ant-col-14 {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}


.coupoons_scroll {
  height: 315px;
  overflow: auto;
  padding: 1px;
}

.coupoons_scroll::-webkit-scrollbar {
  width: 6px;
}

.coupoons_scroll::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.coupoons_scroll::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

.coupoons_scroll::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.subscription_detail_checkout {
  display: grid;
  gap: 8px;
}

.subscription_name_checkout {
  font-size: 15px;
  font-weight: 500;
  color: var(--bs-orange);
}

.tender_amount_checkout {
  font-size: 14px;
  font-weight: 500;
  color: #000;
  text-transform: capitalize;
}

.planDetail_checkout .pb__dte_item p {
  font-size: 14px;
  line-height: 150%;
  color: #000;
  margin-bottom: 20px;
  margin-top: 15px;
}

.react-datepicker-wrapper {
  width: 100%;
}


/* vinay */

.nav-item {
  position: relative;
}

.child-menu {
  display: none;
  position: absolute;
  top: 100%;
  width: 200px;
  background-color: rgb(255, 255, 255);
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  z-index: 99;
  border-radius: 6px;
}

.has-hover:hover .child-menu {
  display: block;
}

.has-child .mobileBlock {
  display: block;
  position: relative;
  width: 100%;
  background-color: rgb(244, 244, 244);
  box-shadow: none;
}

.has-child .displayBlock {
  display: none;
}

.advance_filter_heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.advance_filter_heading .mainButton {
  padding: 15px 17px;
  font-size: 14px;
}

.advance_filter_heading h1 {
  font-size: 20px !important;
  margin-left: 10px;
}

.advance_filter_heading h2 {
  font-size: 20px !important;
  margin-left: 10px;
}


.santa_img {
  position: absolute;
  left: -700px;
  bottom: -10%;
  z-index: 999;
  width: 700px;
}

.highlight {
  color: #cd1f7c;
  font-weight: bold;
  /* Add any other styles you want for highlighting */
}

/* Add appropriate styling for the disabled state */
.disabled {
  opacity: 0.5;
  /* You can adjust the opacity or add other styles */
  cursor: not-allowed;
}

.seo_content {
  position: relative;
  border: 1px solid #dbdbdb;

  /* width */
  ::-webkit-scrollbar {
    width: 3px;
    height: 3px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px rgb(255, 255, 255, 0);
    border-radius: 10px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: rgb(185, 185, 185);
    border-radius: 10px;
    width: 3px;
    height: 3px;
    transition: all .3s;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: gray;
  }



  &::after {
    content: '';
    width: 100%;
    height: 30px;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 5;
    background: linear-gradient(#ffffff6e, #ffffff);
    filter: blur(2px);
    backdrop-filter: blur(2px);
  }

  h1.seo-h1 {
    font-size: 24px;
    position: absolute;
    background: white;
    z-index: 8;
    top: 0;
    /* text-align: center; */
    left: 0;
    right: 0;
    padding: 15px 30px;
    border-bottom: 1px solid #e8e8e8;
    color: black;
    font-weight: 600;
  }
}


.seo_text {
  /* width: 95%; */
  /* text-align: center; */
  font-size: 16px;
  line-height: 140%;
  padding: 30px;
  padding-top: 60px;
  margin-inline: auto;
  max-height: 320px;
  overflow: auto;

}


.seo_text h1 {
  font-size: 24px;
  color: black;
}

.seo_text h1 strong {
  font-weight: 600;
}

.seo_text h2 {
  font-size: 22px;
  color: black;
}

.seo_text ul {
  padding-left: 20px;
  margin-top: 20px;
}

.seo_text ul li {
  list-style: disc;
  margin-bottom: 5px;
}

.by_country {
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  gap: 30px;
  padding-bottom: 9px;
}

.by_country h4 {
  font-size: 18px;
  color: #ffffff9c;
}

.coutry_vl {
  padding-left: 6px;
  padding-right: 6px;
}

.assits_country {
  padding-bottom: 35px;
}

.countries {
  margin-bottom: 10px;
}

.countries span {
  color: white;
  cursor: pointer;
  line-height: 140%;
}

.dividerr {
  background: hsla(0, 0%, 100%, .2);
  border: none;
  display: block;
  height: 1px;
  margin-bottom: 30px;
}

.by_country a {
  text-decoration: underline;
  color: white;

}

.by_country span {
  color: white;
  text-decoration: underline;
  cursor: pointer;
}

.by_country button {


  height: 28px;
  background: var(--bs-orange);
  color: white;
  border-radius: 0;
  border: none;
  border-left: 0;
  font-weight: 500;
  transition: .2s ease-in-out;

  font-size: small;
}

.by_country button:hover {
  background: var(--third)
}

.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input.Mui-disabled {
  opacity: 1;
  -webkit-text-fill-color: rgb(28 11 11 / 91%) !important;
}

.dropdown_menusHide {
  position: sticky;
  top: 0;
  z-index: 99;
  background: var(--bodyNew);
  padding: 15px;
}

.dropdown_menusHide span {
  font-size: 17px;
  color: #000;
}

.dropdown_header {
  margin: 20px 40px 10px 20px;
  position: relative;
}

.filter_dropdown .css-9l381r-MuiFormControl-root {
  background: #fff !important;
  border-radius: 7px;
}

.dropdown_header .MuiInputBase-formControl {
  height: 40px !important;
}

.dropdown_header .dropdown_headerBtn {
  position: absolute;
  top: 0;
  right: -5px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 7px;
  border-bottom-right-radius: 7px;
  border: none;
  height: 41px;
  background: var(--primary);
  padding: 0 15px;
}

.dropdown_header .dropdown_headerBtn svg {
  color: #fff;
}

.extra_filter_cont {
  display: flex;
  gap: 20px;
  position: relative;
  z-index: 20;
}

.filter_dropdown .MuiInputBase-formControl {
  height: 42px !important;
}

.filter_dropdown .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
  line-height: 100% !important;
}

.css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.Mui-checked,
.css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.MuiCheckbox-indeterminate,
.css-zun73v.Mui-checked {
  color: var(--primary) !important;
  /* padding: 0 5px; */
}

.dropdown_footer {
  position: sticky;
  bottom: 0;
  width: 100%;
  z-index: 99;
  background: var(--bodyNew);
  padding: 15px;
  display: flex;
  justify-content: end;
  align-items: center;
}

.dropdown_footer button {
  padding: 12px 15px !important;
  margin-right: 20px;
  height: 40px !important;
  width: fit-content !important;
}

.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root {
  min-height: 35px !important;
  height: 38px;
}

.sector_autocomplete {
  position: absolute;
  top: auto;
  left: 0;
  width: 470px;
  height: 350px;
  display: none;
  background: #fff;
  overflow-y: scroll;
  z-index: 30;
}

.sectoe_btm {
  padding: 15px;
  height: 45px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 10;
}

.tender_noticeCont {
  display: flex;
  gap: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid var(--greyLight-2);
}

.tender_noticeCont h5 {
  font-size: 17px;
  margin-bottom: 10px;
}

.tender_noticeCont p {
  margin-bottom: 0;
}

.download_apps {
  padding: 10px;
  background: #3c35522e;
  border-radius: 5px;
  display: flex;
  /* justify-content: center; */
  align-items: center;
  gap: 15px;
  cursor: pointer;
}

.download_apps:hover {
  border: 1px solid #ffffff3d;
}

.download_apps img {
  width: 27px;
  object-fit: contain;
}

.download_apps p {
  color: #fff;
  margin-bottom: 5px;
  font-size: 12px;
}

.download_apps span {
  color: #fff;
  font-size: 19px;
  font-weight: 500;
}

.report_div {
  background-color: #fff1f2;
  border-radius: 4px;
  padding: 10px 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.report_div .report_heading {
  color: #e83a3a;
  display: flex;
  justify-content: center;
  align-items: center;
}

.report_div .report_button button {
  background: none;
  border: none;
  color: #2222f5;
  text-decoration: underline;
}

/* 18june */
.advance_position {
  position: relative;
  display: flex;
  align-items: center;
  gap: 20px;
  /* margin-top: 15px; */
}

.advance_position .two_btnAdavces {
  display: flex;
  align-items: center;
  gap: 10px;
}

.advanced_filters .mainButton {
  width: fit-content !important;
  padding: 14px 25px !important;
}

.advance_position .add_templateBtn {
  border: none;
  background: none;
  width: 175px;
  border: 1px solid var(--primary);
  border-radius: 7px;
  padding: 8px;
}

.advance_position .add_templateBtn:hover {
  color: var(--bs-orange);
  border: 1px solid var(--bs-orange);
}

.advance_position .add_templateBtn:hover svg {
  color: var(--bs-orange);
}

.advance_filetrHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 0px 2px rgba(0, 0, 0, 0.15), 0 2px 10px rgba(0, 0, 0, 0.20);
  padding: 10px 20px;
}

.advanced_filters .mainButton span {
  background: var(--primary);
  padding: 7px;
  font-size: 12px;
  border: none;
  border-radius: 50px;
}

.advanced_filters .mainButton:hover span {
  background: var(--bs-orange);
}

.advanced_filters h1 {
  font-size: 22px;
  /* padding: 0 15px; */
  margin: 0;
}

.advanced_filters hr {
  flex-grow: 1;
  width: 50%;
  height: 2px;
}

.advancedshow_container {
  padding: 20px 25px 20px 20px;
  /* margin-bottom: 20px; */
  height: 73vh;
  overflow-y: scroll;
}

.advancedshow_container button {
  padding: 0;
  margin-bottom: 5px;
}

.advanced_filters .advancedshow_container .MuiOutlinedInput-input,
.css-feqhe6 {
  border: none !important;
  height: 42px !important;
  font-size: 14px !important;
}

.advanced_filters .MuiInputBase-formControl {
  height: 42px !important;
  background: #fff !important;
}

.advancedshow_container .MuiAutocomplete-hasPopupIcon {
  width: 100% !important;

}

.advanced_filters .advancedshow_container .css-1tw9w9k-MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-input {
  padding: 0px 0px 0px 7px;
}

.advanced_filters .advancedshow_container .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  top: -6px;
  border-color: none;
}

.advanced_filters .advancedshow_container .textfeild_advance {
  width: 100%;
}

.fixed_independenceDay {
  height: calc(81vh - 114px) !important;
  margin-top: 117px;
  position: sticky;
  top: 116px;
  width: 100%;
}

.fixed_independenceDay video {
  width: 100%;
}

.fixed_independenceDay_phone .indepence_phnVideo {
  display: none;
}

@media (min-width:1024px) and (max-width: 1025px) {
  .fixed_independenceDay {
    height: calc(71vh - 114px) !important;
  }
}

@media (min-width:768px) and (max-width:785px) {
  .fixed_independenceDay {
    height: calc(73vh - 114px) !important;
  }
}

.silder-videos {
  position: sticky;
}

.backgorund_color {
  background: #fff !important;
}

.advanced_filters .advancedshow_container .form-input label {
  line-height: 120%s;
}

.advanced_filters .advancedshow_container .form-input {
  position: relative;
  height: 38px !important;
}

.advanced_filters .advancedshow_container .KeyboardIcon {
  position: absolute;
  right: 22px;
  color: #828282;
}

.advanced_filters .MuiAutocomplete-endAdornment,
.css-2iz2x6 {
  top: 10px !important;
}

.advanced_filters .advancedshow_container .css-1tw9w9k-MuiAutocomplete-root .MuiAutocomplete-inputRoot {
  background: #fff;
  padding: 0 10px !important;
}

.select_dialog .dialog_content {
  padding: 15px 25px;
  padding-top: 15px !important;
}

.select_dialog .css-bdhsul-MuiTypography-root-MuiDialogTitle-root {
  text-align: left !important;
  padding: 15px !important;
  background: #f2f2f2;
}

.select_dialog .mainButton {
  color: #fff !important;
  height: 40px;
}

.select_dialog .dialog_actions {
  background: #f2f2f2;
  padding: 15px;
}

.select_dialog .dialog_actions .dialog_buttonAction {
  display: flex;
  justify-content: space-between;
  gap: 15px;
}

.select_dialog .dialog_actions .dialog_buttonAction .clearButton {
  border: 1px solid #000;
  padding: 10px 15px;
  color: #000 !important;
  border-radius: 7px;
  height: 40px;
}

.select_dialog .dialog_content .serach_dialogbox {
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  position: relative;
}

.select_dialog .dialog_content .serach_dialogbox input {
  width: 100%;
  padding: 10px;
  border: 1px solid var(--greyBg);
  border-radius: 5px;
}

.select_dialog .dialog_content .serach_dialogbox button {
  position: absolute;
  background: var(--bs-orange);
  color: #fff !important;
  padding: 10px 12px;
  right: 0;
  border: none;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.advancedshow_container .css-1j0qgw8-MuiAutocomplete-root {
  width: 100%;
}

.advancedshow_container .css-1j0qgw8-MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-input {
  padding: 0 0 0 20px !important;
  font-size: 14px;
  height: 42px !important;
}

.advancedshow_container .form-input {
  border: 1px solid rgba(0, 0, 0, 0.23);
  border-radius: 5px !important;
}

.select_dialog .dialog_content .dialog_subHeading {
  margin-bottom: 10px;
  font-size: 17px;
  display: flex;
  align-items: center;
}

.select_dialog .dialog_content .dialog_subHeading .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root {
  padding: 0 10px 0 0;
  margin-left: -2px;
}

.advancedshow_container .css-1nrlq1o-MuiFormControl-root {
  background: #fff;
  text-align: left;
  border-radius: 5px;
}

.advancedshow_container .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
  text-transform: capitalize;
  line-height: 73%;
}

.advancedshow_container .tender_valueHedaing {
  margin-top: 7px;
  padding: 10px 15px;
  BACKGROUND: #FFF;
  border: 1px solid rgba(0, 0, 0, 0.23);
  border-radius: 5px;
}

.advancedshow_container .style_tender {
  margin-right: 20px;
}

.advancedshow_container .tender_value {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.advancedshow_container .css-ltlhnc-MuiSlider-root,
.MuiSlider-marked {
  color: var(--primary) !important;
  margin-bottom: 10px;
}

/* .advancedshow_container .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 0;
} */

.advancedshow_container .css-hzp7sc-MuiSlider-mark {
  left: 100% !important;
}

.advancedshow_container .labelText {
  font-size: 13px;
  font-weight: 400;
  color: #828282;
  margin-left: 5px;
  margin-bottom: 1px;
  margin-top: 0px;
}

.MuiSlider-markLabel[data-index="1"] {
  left: auto !important;
  right: 0;
  transform: translateX(0);
}

.labelText_radio .radioFlex {
  display: grid !important;
  grid-template-columns: repeat(2, 1fr);
}

.labelText_radio .css-1nrlq1o-MuiFormControl-root {
  background: #fff;
  text-align: left;
  border-radius: 5px;
  padding: 10px !important;
}

.advancedshow_container .label_margin {
  margin-top: -16px;
}

.textfiled_remove {
  border: 1px solid var(--greyDark);
  border-radius: 5px;
  background: #fff;
  display: flex;
  justify-content: space-between;
  padding: 12px;
  gap: 10px;
  height: 39px;
  cursor: pointer;
  align-items: center;
  position: relative;
  margin-bottom: 10px;
}

.textfiled_remove:hover {
  border: 1px solid #000;
}

.textfiled_remove .slects_chipsCont {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 7px;
  border: none;
  border-radius: 20px;
  background: var(--bodyNew);
  width: fit-content;
  gap: 10px;
}

.textfiled_remove .slects_chipsCont svg {
  font-size: 16px;
}

.textfiled_remove .slects_chipsCont .selected_chips {
  color: #000;
  font-size: 12px;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  color: #000;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  width: 100%;
}

.textfiled_remove p {
  margin: 0;
}

.textfiled_remove .more_chips {
  padding: 7px 6px;
  border: none;
  border-radius: 20px;
  background: var(--primary);
  font-size: 13px;
  color: #fff;
  position: absolute;
  right: 42px;
}

.report_drawerOpne .css-1i1myyz {
  display: flex;
  gap: 20px;
  align-items: center;
}

.report_drawerOpne .css-1i1myyz svg {
  opacity: 0.6;
  cursor: pointer;
}

.report_drawerOpne .css-1i1myyz h2 {
  margin: 0;
  font-size: 18px;
}

.report_drawerOpne .tender_containerDiv {
  padding: 20px 15px;
}

.report_drawerOpne .report_formed {
  height: 80vh;
}

.report_drawerOpne .report_footers {
  display: flex;
  justify-content: end;
  align-items: center;
  gap: 18px;
}

.textfiled_remove_disabled {
  border: 1px solid var(--greyDark);
  border-radius: 5px;
  background: #ddd;
  display: flex;
  justify-content: space-between;
  padding: 12px;
  gap: 0;
  height: 42px;
  cursor: no-drop;
  align-items: center;
  position: relative;
  opacity: 0.8;
  margin-bottom: 5px;
}

.textfiled_remove_disabled p {
  margin-bottom: 0;
}

.model_headerTempl {
  background: var(--bodyNew);
  padding: 12px;
  color: #000;
  font-weight: 500;
  font-size: 14px;
}

.model_headerTempl h2 {
  line-height: 100%;
}

.model_headerTempl .mainButton {
  padding: 16px 22px;
}

.template_footer {
  display: flex;
  justify-content: end;
  gap: 15px;
  align-items: center;
  background: var(--bodyNew);
  padding: 12px;
}

.add_templatedModel {
  height: 600px;
  overflow-y: scroll;
}

.add_templatedModel .serach_dialogbox {
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  position: relative;
}

.add_templatedModel .serach_dialogbox input {
  width: 100%;
  padding: 10px;
  border: 1px solid var(--greyBg);
  border-radius: 5px;
}

.add_templatedModel .serach_dialogbox button {
  position: absolute;
  background: var(--primary);
  color: #fff !important;
  padding: 10px 12px;
  right: 0;
  border: none;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.add_templatedModel .templetes_chips {
  display: flex;
  align-items: center;
  gap: 12px;
  flex-wrap: wrap;
  margin-top: 12px;
}

.add_templatedModel .templetes_chips .chip {
  background: #FCFCFC;
  padding-left: 16px;
  padding-right: 16px;
  padding-block: 8px;
  border: 1px solid #F2F2F2;
  border-radius: 50px;
  margin: 0;
  cursor: grabbing;
  transition: 0.3s ease-in-out;
  font-size: 12px;
  font-weight: 400;
  display: flex;
  align-items: center;
  gap: 10px;
}

.add_templatedModel .templetes_chips .chip svg {
  font-size: 16px;
}

.saved_drawer .save_headerDrawer {
  padding: 24px;
  background: var(--bodyNew);
}

.saved_drawer .save_headerDrawer p {
  margin: 0;
  font-size: 20px;
  font-weight: 500;
  color: #000;
}

.saved_drawer .save_footerDrawer {
  padding: 21px;
  background: var(--bodyNew);
  display: flex;
  gap: 15px;
  align-items: center;
  justify-content: end;
}

.save_footerDrawer .closeBtn {
  color: #000;
  background: #fff;
  border-radius: 8px;
  padding: 14px 25px !important;
  border: 1px solid var(--greyBg);
  font-size: 14px;
  font-weight: 500;
  line-height: 100%;
  position: relative;
  transition: 0.3s ease-in-out;
  display: flex;
  align-items: center;
  gap: 10px;
  font-family: "Poppins", sans-serif !important;
}

.save_footerDrawer .deleteBtn {
  border-radius: 8px;
  background: #FF4343;
  padding: 14px 25px !important;
  border: none;
  font-size: 14px;
  font-weight: 500;
  line-height: 100%;
  color: white;
  position: relative;
  transition: 0.3s ease-in-out;
  display: flex;
  align-items: center;
  gap: 10px;
  font-family: "Poppins", sans-serif !important;
}

.save_footerDrawer .editBtn {
  border-radius: 8px;
  background: rgb(69, 69, 144);
  padding: 14px 25px !important;
  border: none;
  color: #FFF;
  font-size: 14px;
  font-weight: 500;
  line-height: 100%;
  transition: 0.3s ease-in-out;
  font-family: "Poppins", sans-serif !important;
  display: flex;
  align-items: center;
  gap: 10px;
  height: auto !important;
}

.save_footerDrawer .applyBtn {
  border-radius: 8px;
  background: var(--bs-orange);
  padding: 14px 25px !important;
  border: none;
  color: #FFF;
  font-size: 14px;
  font-weight: 500;
  line-height: 100%;
  transition: 0.3s ease-in-out;
  font-family: "Poppins", sans-serif !important;
  display: flex;
  align-items: center;
  gap: 10px;
  height: auto !important;
}

.save_content {
  padding: 20px;
  height: 79vh;
}

.save_content .save_contentAccordion .css-ahj2mt-MuiTypography-root {
  margin: 0 !important;
}

.save_content .save_contentAccordion .css-l0fmuu-MuiPaper-root-MuiAccordion-root {
  box-shadow: 0px 0px 1px -1px rgba(0, 0, 0, 0.2), 0px 0px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 1px 0px rgba(0, 0, 0, 0.1);
}

.save_content .save_contentAccordion .save_tenderName {
  display: flex;
  flex-direction: column;
  gap: 7px;
  justify-content: center;
}

.save_content .save_contentAccordion .save_sectors {
  background: var(--bodyNew);
  padding: 7px;
  border-radius: 5px;
}

.save_content .save_contentAccordion .css-eqpfi5-MuiAccordionSummary-content {
  margin: 0 !important;
}

.phone-advance-button .buttonGrp .mainButton {
  width: fit-content;
}

.kaeybord_table .features-tab .tab-content {
  padding: 0px 0 0 !important;
}

.table_keywword .MuiTableCell-body:first-child {
  border-right: 0 !important;
}

.table_keywword .MuiTable-root td {
  text-align: left;
}

.table_keywword .MuiTableRow-head {
  background: #e9e9e9;
}

.features-tab p {
  margin-bottom: 0 !important;
}

.table_keywword .MuiIconButton-sizeMedium svg {
  color: var(--bs-orange);
}

.login_papi_modal {
  z-index: 99999999999999999999999;
}

.active_tabs .css-heg063-MuiTabs-flexContainer {
  justify-content: start !important;
  gap: 25px !important;
}

.active_tabs .css-1gsv261 {
  border-bottom: 1px solid !important;
}

.active_tabs .css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected {
  color: var(--bs-orange) !important;
  border-bottom: 2px solid;
  width: 200px !important;
}

.active_tabs .css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
  width: 200px;
}

.bg_chanegs,
.why_tender {
  background: #fafafa !important;
  position: relative;
}

.international-phone-input {
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
  z-index: 99;
}

.MuiTextField-root {
  width: 100%;
}

.pl-110 {
  padding-left: 110px !important;
}

.sec-hide {
  background-color: red !important;
}

.tree-node[data-id="sector"] .checkbox {
  display: none;
}



.translate-btn {
  position: fixed;
  z-index: 1031;
  bottom: 1.5%;
  right: 1.5%;
  display: flex;
  align-items: center;
  gap: 10px;
  padding-inline: 10px;
  background-color: white;
  border-radius: 8px;
}


/* add templete css */
.chip-container {
  overflow: hidden;
}

.chip-container .filter_item:not(:last-child) {
  margin-bottom: 30px;
  padding-bottom: 30px;
  border-bottom: 1px solid #c9c9c9;
}

.chip-container .filter_item {
  font-size: 15px;
  color: #000;
}

.chip-container .filter_item .chip_flex {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 12px;
  margin-top: 16px;
}

.chip-container .filter_item .chipItem {
  max-width: calc(100% - 6px);
  color: rgb(58, 58, 58);
  font-size: 14px;
  background-color: rgba(0, 0, 0, 0.08);
  border-radius: 16px;
  white-space: nowrap;
  padding: 6px 12px;
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  transition: .2s ease-in-out;
}

.chip-container .filter_item .chipItem svg {
  color: rgba(0, 0, 0, 0.26);
}

.chip-container .filter_item .chipItem:hover {
  opacity: .7;
}


@media (max-width: 767px) {
  .advancedFilter_wrao {
    flex-wrap: wrap;
    gap: 10px !important;
    margin-bottom: 20px;
  }

  .advancedFilter_wrao .mb-3 {
    margin-bottom: 0 !important;
  }

  header.fixed-top {
    height: 64px;
  }

  .navbar.navbar-expand-lg.navbar-light {
    height: 100%;
  }

  .message {
    height: auto;
    font-size: 12px;
    margin-top: 8px;
  }
}

.advFilterModal .dialog-detail-page .MuiInputBase-formControl {
  margin-top: 0 !important;
}

.lines-4 {
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.highcharts-credits {
  display: none !important;
}

.webwhatsapp {
  position: fixed;
  bottom: 15px;
  left: 20px;
  z-index: 9999999;
}

.webwhatsapp img {
  width: 50px;
  height: 50px;
}

.latest_value {
  margin-top: 2px;
  font-size: 15px;
  font-weight: 500;
}

.innContent table tbody {
  width: 100%;
}

.innContent .empyty_boxIMg {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
}