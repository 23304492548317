.detail {
    .profile_wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 50px;

    }

    .user-names {
        .input-box {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 15px;

            input {
                border: none;
                border-bottom: 1px solid #000;
                width: 100%;
                padding-bottom: 4px;
            }
        }

        .edit_icons {
            display: flex;
            align-items: center;
            justify-content: center;

            h2 {
                margin-bottom: 10px;
                font-size: 24px;
            }

            button {
                padding-left: 15px;
                padding-bottom: 7px;
            }
        }

        p {
            text-align: center;
            padding-top: 10px;
        }
    }

    .group-info {
        margin-bottom: 15px;
        margin-top: 80px;

        .group-member-list {
            display: flex;
            align-items: center;
            padding: 15px 0;
            margin: 10px 0;

            img {
                width: 40px;
                height: 40px;
                object-fit: cover;
                border-radius: 50%;
            }

            .user-details {
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 100%;

                .user-heading {
                    margin-left: 15px;
                    font-size: 18px;
                    padding-bottom: 0;
                }

                span {
                    border: 1px solid #44a8a8;
                    padding: 4px;
                    font-size: 11px;
                    border-radius: 5px;
                    color: #44a8a8;
                }
            }
        }
    }

}