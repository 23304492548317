@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');
// @import './_variables.scss';
@import '../css/variables.scss';
@import '../css/mixins.scss';

@media print {
    body * {
        visibility: hidden !important;
        margin: 0 !important;
        padding: 0 !important;
    }

    .tableBody_wrap * {
        visibility: visible !important;
    }

    .tableBody_wrap {
        position: fixed;
        inset: 0;
    }
}

// rajat
.your-custom-class {
    background: red;
}

.header-box-flex-bd {
    display: flex;
    align-items: center;
    width: 100%;

    .header-box-flex-bd:hover .bd-header-list {
        display: block;
    }
}

.dashed-btn {
    &:hover {
        color: $primary-400 !important;
        border-color: $primary-400 !important;
    }
}


.submenu-h {
    .ant-form-item-control-input {
        min-height: 0px !important;
    }
}

.checlist_accord {
    .ant-collapse {
        border: none;
        background: transparent;
        border-radius: 10px;
    }

    .ant-collapse-header {
        border: 2px solid #e7e7e7;
        border-left: 0;
        padding-block: 15px;
        padding-inline: 20px;
        border-radius: 10px !important;
        border-bottom-left-radius: 0 !important;
        // // border-top-left-radius: 0;
        // background: linear-gradient(180deg, hsl(251deg 64% 68% / 8%), hsl(251deg 64% 58% / 0%));
        // padding: 15px 15px;
        // box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
        background: var(--white, #FFF);
        font-size: 17px;
        font-weight: 400;
        align-items: center !important;

        .collapse_flex {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
    }

    .ant-collapse-content {
        background: none;
        border: none;

        .ant-collapse-content-box {
            padding: 0;
        }
    }

    .ant-collapse-expand-icon {
        height: auto !important;
        padding-inline-end: 25px !important;
    }

    .iconBox {
        width: 34px;
        height: 34px;
        border-radius: 50%;
        border: 1px solid $secondary-200;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .ant-collapse-item-active {
        .iconBox {
            border: 1px solid $primary-500;
            background: $primary-100;

            svg>* {
                stroke: $primary-500;
            }
        }
    }

    .addMore_btn {
        color: white !important;
        font-size: 17px !important;
        font-weight: 500 !important;
        width: fit-content;
    }
}

.submenu-btn {
    position: absolute;
    bottom: -16px;
    left: 40px;
    border: none;
    font-size: 18px;
    box-shadow: none;
    background: none;
    font-weight: 500;
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 0;

    &:hover {
        color: $secondary !important;
        background: none !important;
    }
}

.checklist_wrapper {
    position: relative;
    // width: 100%;
    border-left: 2px solid #e7e7e7;
    padding-bottom: 40px;
    margin-bottom: 40px;
    border-radius: 10px !important;

    &:not(:first-child) {
        margin-top: 60px;
    }

    &::before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 5px;
        width: 40px;
        height: 2px;
        background: #e7e7e7;
    }

    .checklist_wrapper {
        margin-left: 40px;

        &:after {
            content: '';
            position: absolute;
            top: 0;
            left: -42px;
            width: 50px;
            height: 2px;
            background: #e7e7e7;
        }
    }

    .addOption_btn {
        position: absolute;
        bottom: 0;
        left: 0;
    }

    .ch_status {
        border-radius: 100px;
        padding: 6px 14px;
        font-size: 14px;
        height: auto;

        &.pending {
            background: #f3fcff;
            border: 1px solid #c9eff9;
            color: #2da1c0;
            display: flex;
            align-items: center;
            gap: 8px;

            span {
                width: 10px;
                height: 10px;
                border-radius: 50%;
                background: #2da1c0;
            }
        }

        &.progress {
            background: $warning-100;
            border: 1px solid $warning-300;
            color: $warning-500;
            display: flex;
            align-items: center;
            gap: 8px;

            span {
                width: 10px;
                height: 10px;
                border-radius: 50%;
                background: $warning-500;
            }
        }

        &.complete {
            background: $success-100;
            border: 1px solid $success-300;
            color: $success-500;
            display: flex;
            align-items: center;
            gap: 8px;

            span {
                width: 10px;
                height: 10px;
                border-radius: 50%;
                background: $success-500;
            }
        }
    }

    .upload_wrap {
        margin-bottom: 20px;

        label {
            display: block;
            margin-bottom: 15px;
            font-size: 16px;
        }

        .uploadItem {
            padding: 12px 25px;
            border-radius: 8px;
            border: 1px solid #e1e1e1;
            display: flex;
            align-items: center;
            gap: 15px;
            font-size: 16px;
            cursor: pointer;
            color: $secondary-700;
            transition: .2s ease-in-out;

            img {
                width: 35px;
            }

            &:hover {
                border-color: $warning-500;
            }
        }
    }

}

.checklist-card {
    border: none;
    border-radius: 0;
    background: none;


    .ant-card-body {
        padding: 30px 20px 15px;
        box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
        background: white;
        margin: 20px;
        margin-right: 0;
        border-radius: 10px;
        // padding-bottom: 20px;
    }
}

.scroll_wrap {
    overflow: auto;
}

.dragOrder_list {
    display: flex;
    align-items: center;
    gap: 20px;
    padding: 20px;
    background: $background;
    border-radius: 6px;
    width: max-content;

    .dragItem_block {
        border-radius: 6px;
        border: 1px solid $border;
        width: 120px;
        height: 120px;
        background: white;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;

        button {
            border: none;
            position: absolute;
            top: 10px;
            right: 10px;
            border-radius: 4px;
            padding: 4px 4px;
            cursor: grab;
        }

        .closeItem {
            right: auto;
            left: 10px;
            top: 5px;
            padding: 0;
            cursor: pointer;
            background: none;
            padding: 0;
        }
    }
}

.checklist-progress {
    .ant-progress-bg {
        background-color: $primary-400;
    }
}

.addMenu-btn {
    background: $primary-gradient !important;
    padding: 9px 20px;
    height: auto;
}

body.chat-grid-module {
    background-color: $background;
    position: relative;
    box-sizing: border-box;
    // font-family: "Open Sans", sans-serif !important;
    font-family: 'Poppins', sans-serif !important;
    font-weight: 500;
    font-size: 16px;
    line-height: 100%;
    letter-spacing: 0;

    & * {
        font-family: 'Poppins', sans-serif !important;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    p {
        font-family: 'Poppins', sans-serif !important;
        margin: 0;
    }

    .ant-tooltip-inner {
        font-family: Poppins;
        font-size: 13px;
        font-weight: 400;
    }

    @import '../css/style/new/header.scss';

    // Footer css
    .bd_footerSection {
        // position: fixed;
        // bottom: 0;
        // left: 0;
        // right: 0;
        padding: 15px;
        font-size: 14px;
        color: #4a4a4a;
        background: rgb(230 230 230 / 30%);
        backdrop-filter: blur(10px);
        font-weight: 500;
        text-align: center;
        z-index: 991;

        a {
            color: $primary;
        }
    }

    .Toastify__toast {
        margin-bottom: 50px !important;
    }
}


.chats_bid {
    position: fixed;
    bottom: 5%;
    right: 1%;
    z-index: 999;

    .visible {
        display: block;
    }

    .hidden {
        display: none;
    }

    button {
        width: 50px;
        height: 50px;
        border: none;
        border-radius: 50%;
        background: $primary;
        color: white;
        align-items: center;
        justify-content: center;
        z-index: 999;
        cursor: pointer;
        transition: 0.3s ease-in-out;

        &:hover {
            opacity: 0.9;
        }
    }
}

.bd_topScoll {
    position: fixed;
    bottom: 5%;
    left: 1%;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: $primary !important;
    color: #fff;
    align-items: center;
    justify-content: center;
    z-index: 999;
    cursor: pointer;
    transition: 0.3s ease-in-out;

    &:hover {
        opacity: 0.8;
    }
}

.bd_downScoll {
    position: fixed;
    bottom: 5%;
    left: 1%;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: $primary !important;
    color: #fff;
    align-items: center;
    justify-content: center;
    z-index: 999;
    cursor: pointer;
    transition: 0.3s ease-in-out;

    &:hover {
        opacity: 0.8;
    }
}

.chatOverlay {
    position: fixed;
    inset: 0;
    background: #0000002c;
    z-index: 999;
}

.botIcons_div {
    position: fixed;
    bottom: 10%;
    right: 1%;
    z-index: 999;
    overflow: auto;
    border-radius: 10px;

    .botIcon {
        width: 385px;
        height: 73vh;
        overflow: hidden;
        background-color: $background;
        backdrop-filter: blur(5px);
        border-radius: 10px;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        box-shadow: 2px 2px 10px 0px rgba(0, 0, 0, 0.05);
        position: absolute;
        z-index: 9;
        top: 0px;

        .chat-title {
            -webkit-box-flex: 0;
            -ms-flex: 0 1 45px;
            flex: 0 1 45px;
            position: relative;
            z-index: 2;
            background: $dark-border;
            color: #fff;
            text-align: left;
            padding: 10px 10px 10px 20px;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .heading_spaned {
                text-align: center;

                h1 {
                    font-weight: 600;
                    font-size: 13px;
                    margin: 0;
                    padding: 0;
                    color: $grey;
                    cursor: pointer;
                    text-transform: uppercase;
                }

                span {
                    font-weight: 400;
                    font-size: 12px;
                    margin: 0;
                    padding: 0;
                    color: green;
                    text-align: center;
                }

            }

            .avatar {
                position: absolute;
                z-index: 1;
                top: 8px;
                left: 9px;
                border-radius: 30px;
                width: 30px;
                height: 30px;
                overflow: hidden;
                margin: 0;
                padding: 0;
                border: 2px solid $border;
                display: flex;
                justify-content: center;
                align-items: center;

                svg {
                    width: 16px;
                    height: auto;
                    color: $Text-dark;
                }
            }

            .botChat_cross {
                margin-right: 8px;
                display: flex;
                gap: 12px;

                svg {
                    color: $Text-dark;
                    width: 16px;
                    cursor: pointer;
                    opacity: 0.8;

                    &:hover {
                        color: $primary;
                    }
                }
            }

            .botChat_arrow {
                svg {
                    color: $Text-dark;
                    width: 16px;
                    cursor: pointer;
                    opacity: 0.8;

                    &:hover {
                        color: $primary;
                    }
                }
            }
        }

        .messages {
            position: relative;
            padding: 13px;
            height: calc(73vh - 100px);
            overflow: auto;

            .date_frameChat {
                position: absolute;
                margin: auto;
                background: #e8e8e8;
                width: fit-content;
                padding: 5px;
                border: none;
                border-radius: 10px;
                right: 40%;

                span {
                    font-size: 12px;
                    font-weight: 400;
                    color: #000;
                }
            }

            .messages-content {
                margin-top: 15px;

                .message_botIcon {
                    display: flex;
                    gap: 10px;
                    align-items: center;
                    margin-bottom: 10px;

                    .message_bot_logo {
                        width: 30px;
                        height: 30px;
                        padding: 5px;
                        border: 2px solid $border;
                        border-radius: 20px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }

                    .message_botIcons_div {
                        font-size: 12px;
                        font-weight: 500;
                        background: rgb(240 235 235 / 30%);
                        padding: 10px;
                        border-radius: 12px;
                        border-top-left-radius: 0;
                        line-height: 140%;
                        color: $grey;
                        width: fit-content;
                        max-width: 270px;
                        // flex-grow: 1;

                        svg {
                            width: 19px;
                            opacity: 0.9;
                        }
                    }

                    .time {
                        font-size: 10px;
                        font-weight: 400;
                    }
                }

                .message_user {
                    display: flex;
                    flex-direction: row-reverse;
                    gap: 10px;
                    margin-bottom: 10px;
                    width: 100%;

                    .message_bot_logo {
                        width: 30px;
                        height: 30px;
                        border: 2px solid $border;
                        border-radius: 20px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }

                    .message_botIcons_div {
                        font-size: 12px;
                        font-weight: 500;
                        background: #f8e5df;
                        padding: 10px;
                        border-radius: 12px;
                        border-top-right-radius: 0;
                        line-height: 140%;
                        color: $grey;
                        // margin-left: 30px;
                        width: fit-content;
                        max-width: 270px;
                        // flex-grow: 1;
                    }

                    .time {
                        font-size: 10px;
                        font-weight: 400;
                    }
                }

                .uploaded-image {
                    width: 70%;
                    max-width: 300px;
                    display: flex;
                    padding: 6px 12px 6px 6px;
                    border-radius: 16px;
                    background-color: white;
                    border-top-left-radius: 0px;
                    gap: 20px;
                    align-items: center;

                    position: relative;

                    .doc-img {
                        border-radius: 10px;
                        width: 100%;
                        padding: 10px;
                        background-color: rgba(255, 255, 255, 0.5);
                        display: flex;
                        justify-content: space-between;

                        img {
                            width: 15px;
                        }

                        span {
                            font-size: 11px;
                            font-weight: 400;
                            color: #000;
                        }
                    }

                    .file-details_image {
                        position: absolute;
                        top: 10px;
                        right: 10px;

                        button {
                            border: none;
                            background: #eee;
                            border-radius: 20px;
                            padding: 5px;
                            cursor: pointer;

                            svg {
                                font-size: 14px;
                                opacity: 0.7;
                            }
                        }
                    }

                    .file-details {
                        button {
                            border: none;
                            background: #eee;
                            border-radius: 20px;
                            padding: 5px;
                            cursor: pointer;

                            svg {
                                font-size: 14px;
                                opacity: 0.7;
                            }
                        }
                    }

                    .imgGroup {

                        img {
                            height: 100px;
                            object-fit: cover;
                        }

                        .overlap {
                            width: 100%;
                            height: 100%;
                            background-color: rgba(0, 0, 0, 0.5);
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            position: absolute;
                            left: 0;
                            top: 0;
                            z-index: 1;
                            border-radius: 10px;

                            p {
                                font-size: 28px;
                                color: white;
                            }
                        }
                    }

                    .file-details {
                        // margin: 8px 0px;
                        // padding: 13px;
                        // border-radius: 10px;
                        // background-color: rgb(124 124 124 / 10%);
                        // color: #0000006b;
                    }

                    .image-des {
                        color: black;
                        padding: 10px 6px;
                        font-weight: 400;
                        line-height: 130%;
                    }
                }
            }
        }

        .message-box {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            background: $dark-border;
            padding: 10px;
            position: relative;

            .message-input {
                background: none;
                border: none;
                outline: none !important;
                resize: none;
                color: #303030;
                font-size: 13px;
                height: 17px;
                margin: 0;
                padding-right: 0px;
                width: 310px;
            }

            .message-submit {
                border: none;
                font-size: 10px;

                svg {
                    color: $grey;

                    &:hover {
                        color: $primary;
                    }
                }
            }

            .uploadFileIcon {
                border: none;
                background: transparent;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
                cursor: pointer;

                img {
                    width: 15px;
                }
            }
        }


    }

    .chat_profile {
        width: 385px;
        height: 73vh;
        overflow: hidden;
        background-color: #fcfcfc;
        backdrop-filter: blur(5px);
        border-radius: 20px;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        box-shadow: 2px 2px 10px 0px rgba(0, 0, 0, 0.05);

        .chat-title {
            // -webkit-box-flex: 0;
            // -ms-flex: 0 1 45px;
            // flex: 0 1 45px;
            position: sticky;
            top: 0px;
            left: 0px;
            width: 100%;
            z-index: 2;
            // background: $dark-border;
            border-bottom: 1px solid #dbdbdb;
            color: #fff;
            text-transform: uppercase;
            text-align: left;
            padding: 16px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 10px;

            .simple-avator {
                width: 40px;
                height: 40px;
                border-radius: 50%;
                object-fit: cover;
            }

            h1 {
                font-weight: 600;
                font-size: 20px;
                margin: 0;
                padding: 0;
                color: $grey;
                cursor: pointer;
            }

            .botChat_cross {
                margin-right: 8px;
                display: flex;
                gap: 12px;

                svg {
                    color: $Text-dark;
                    width: 32px;
                    cursor: pointer;
                    opacity: 0.8;

                    &:hover {
                        color: $primary;
                    }
                }
            }
        }

        .chat_profile_search {
            padding: 20px 15px;

            .ant-tabs .ant-tabs-tab {
                color: #a5a5a5;
            }

            .ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
                color: #000000;
            }

            .ant-tabs .ant-tabs-tab-btn:active {
                color: #000000;
            }

            .ant-tabs .ant-tabs-tab:hover {
                color: #000000;
            }

            .ant-tabs .ant-tabs-ink-bar {
                background: #000000;
            }

            .chat_groupsCont {
                height: 49vh;
                overflow: scroll;

                .chat_bot_msg {
                    display: flex;
                    gap: 15px;
                    padding-bottom: 5px;
                    border-bottom: 1px solid $border;
                    cursor: pointer;
                    margin-top: 10px;

                    .chatProfile_image {
                        position: relative;

                        img {
                            width: 35px;
                            height: 35px;
                            border-radius: 50px;
                        }

                        .pointOnline {
                            position: absolute;
                            width: 10px;
                            height: 10px;
                            border-radius: 50px;
                            background: green;
                            right: 1px;
                            top: 25px;
                        }
                    }

                    .chat_name_msg {
                        width: 100%;

                        .chat_msg_time_cont {
                            display: flex;
                            justify-content: space-between;
                            align-items: center;

                            span {
                                font-size: 13px;
                                font-weight: 500;
                                color: $light-color;
                            }

                            .chat_time_msg {
                                span {
                                    font-size: 10px;
                                    font-weight: 400;
                                    color: $grey;
                                }
                            }
                        }
                    }



                    .chat_para_mas_count {
                        display: flex;
                        justify-content: space-between;
                        margin-top: 8px;

                        p {
                            font-size: 12px;
                            font-weight: 400;
                            color: $grey;
                            width: 85%;
                            margin-bottom: 0;
                        }

                        .count_number_msg {
                            span {
                                font-size: 11px;
                                font-weight: 500;
                                background: #ff7043;
                                padding: 5px 9px 3px;
                                text-align: center;
                                border: none;
                                color: #fff;
                                border-radius: 50px;
                            }
                        }
                    }
                }
            }

            .tableHead_wrap {
                display: flex;
                justify-content: center;
                align-items: center;
                margin-bottom: 12px;
            }

            .ant-input-wrapper {
                flex-direction: row-reverse;
                display: flex;
                align-items: center;
                padding-left: 10px;
                border-radius: 8px;
                overflow: hidden;
                border: 1px solid #d4d4d4;
                background: var(--background, #f2f2f2);
                height: 38px;

                .ant-input-affix-wrapper {
                    border: none;
                    border-radius: 0;
                    box-shadow: none;
                    height: 50px;
                    padding-left: 35px;
                    background: transparent;

                }

                .ant-input-affix-wrapper input {
                    height: 0px;
                }

                input {
                    font-size: 13px;
                    background: transparent !important;
                    border: none !important;
                    height: auto;
                    padding: 0rem !important;
                    font-family: 'Poppins', sans-serif !important;
                    height: 100% !important;
                    color: $Text-dark;
                }

                .ant-input-group-addon {
                    background: transparent;

                    button {
                        border: none;
                        border-radius: 0;
                        box-shadow: none;
                        background: transparent;
                    }

                    svg {
                        font-size: 16px;
                        fill: #8c8c8c;
                    }
                }
            }

            :where(.css-dev-only-do-not-override-yp8pcc).ant-input-group .ant-input-group-addon,
            :where(.css-dev-only-do-not-override-yp8pcc).ant-input-group .ant-input-group-wrap {
                width: auto;
            }

            .expandIcon {
                width: 30px;
                height: 30px;
                border-radius: 6px !important;
                border: 2px solid #f1f3f6;
                cursor: pointer;
                @include d-flex;
                @include align-center;
                @include justify-center;

                span {
                    height: 16px;
                }
            }

            // .chat_meassges_spn {
            //     margin-top: 15px;

            //     span {
            //         font-size: 14px;
            //         font-weight: 500;
            //         color: $Text-dark
            //     }
            // }

            .chat_message_div {
                display: flex;
                flex-direction: column;
                gap: 10px;
                height: 47vh;
                overflow: scroll;

                .chat_bot_msg {
                    display: flex;
                    gap: 15px;
                    padding-bottom: 10px;
                    border-bottom: 1px solid $border;
                    cursor: pointer;

                    .chatProfile_image {
                        position: relative;

                        img {
                            width: 35px;
                            height: 35px;
                            border-radius: 50px;
                        }

                        .pointOnline {
                            position: absolute;
                            width: 10px;
                            height: 10px;
                            border-radius: 50px;
                            background: green;
                            right: 1px;
                            top: 25px;
                        }
                    }

                    .chat_name_msg {
                        width: 100%;

                        .chat_msg_time_cont {
                            display: flex;
                            justify-content: space-between;
                            align-items: center;

                            span {
                                font-size: 13px;
                                font-weight: 500;
                                color: $light-color;
                            }

                            .chat_time_msg {
                                span {
                                    font-size: 10px;
                                    font-weight: 400;
                                    color: $grey;
                                }
                            }
                        }
                    }



                    .chat_para_mas_count {
                        display: flex;
                        justify-content: space-between;
                        margin-top: 2px;

                        p {
                            font-size: 12px;
                            font-weight: 400;
                            color: $grey;
                            width: 85%;
                            margin-bottom: 0;
                        }

                        .count_number_msg {
                            span {
                                font-size: 11px;
                                font-weight: 500;
                                font-size: 11px;
                                font-weight: 500;
                                background: #ff7043;
                                padding: 5px 9px 3px;
                                text-align: center;
                                border: none;
                                color: #fff;
                                border-radius: 50px;
                            }
                        }

                    }


                }
            }
        }
    }
}

.chat-grid-module {
    @import '../css/style/profile.scss';
    // @import './styles/dashboard.scss';
    // @import './styles/task.scss';
    @import '../css/style/personal.scss';
    // @import './styles/workDistribution.scss';
    // @import './styles/Calendar.scss';
    // @import './styles/addtender.scss';
    // @import './styles/newTender.scss';
    // @import './styles/documentshare.scss';
    // @import './styles/reminder.scss';
    // @import './styles/wishlist.scss';
    // @import './styles/personal.scss';
    @import '../css/style/logo.scss';
    // @import './styles/master.scss';
    // @import './styles/tenderDetails.scss';
    // @import './styles/todo.scss';
    // @import './styles/fileshare.scss';
    // @import './styles/configuration.scss';
    // @import './styles/employeeList.scss';
    @import '../css/style/settings.scss';
    // @import './styles/todoDetails.scss';
    // @import './styles/ddreqDrw.scss';
    @import '../css/style/notification.scss';
    // @import './styles/prospectiveDetail.scss';
}

.chat-grid-module {

    // .main_button {
    //     padding: 20px 30px;
    //     background: $primary;
    //     color: $white;
    //     border-radius: 8px;
    //     transition: .3s ease-in-out;
    //     width: fit-content;
    //     cursor: pointer;
    //     border-radius: 8px;
    //     box-shadow: 0px 1px 0px 0px #E3E3E3 inset, 0px -2px 0px 0px #E3E3E3 inset, 1px 0px 0px 0px #E3E3E3 inset, -1px 0px 0px 0px #E3E3E3 inset, 0px -1px 0px 0px #B5B5B5 inset;

    //     &:hover {
    //         background: $secondary;
    //         opacity: .7;
    //     }
    // }

    .reset_button {
        padding: 16px 25px;
        background: $white;
        color: $black;
        border-radius: 8px;
        transition: .3s ease-in-out;
        width: fit-content;
        cursor: pointer;
        background: var(--white, #FFF);
        box-shadow: 0px 1px 0px 0px #E3E3E3 inset, 0px -2px 0px 0px #E3E3E3 inset, 1px 0px 0px 0px #E3E3E3 inset, -1px 0px 0px 0px #E3E3E3 inset, 0px -1px 0px 0px #B5B5B5 inset;

        &:hover {
            background: $secondary;
            color: $white;
            opacity: .7;
        }
    }

    .BG_ghostButton {
        border-radius: 8px;
        background: var(--white, #FFF);
        box-shadow: 0px 1px 0px 0px #E3E3E3 inset, 0px -2px 0px 0px #E3E3E3 inset, 1px 0px 0px 0px #E3E3E3 inset, -1px 0px 0px 0px #E3E3E3 inset, 0px -1px 0px 0px #B5B5B5 inset;
        padding: 16px 25px;
        border: none;
        color: var(--text-grey, #636363);
        font-size: 14px;
        font-weight: 500;
        line-height: 100%;
        transition: .3s ease-in-out;
        font-family: 'Poppins', sans-serif !important;
        display: flex;
        align-items: center;
        gap: 10px;
        height: auto !important;

        &:hover {
            background: linear-gradient(180deg, $secondary-100, $secondary-200) !important;
            color: #000;

            span {
                color: #000;
            }
        }
    }

    .BG_mainButton {
        border-radius: 8px;
        background: linear-gradient(180deg, $primary-400, $primary-500);
        padding: 16px 25px;
        border: none;
        color: var(--text-grey, #636363);
        font-size: 14px;
        font-weight: 400;
        line-height: 100%;
        color: white;
        position: relative;
        transition: .3s ease-in-out;
        display: flex;
        align-items: center;
        gap: 10px;
        height: auto !important;
        font-family: 'Poppins', sans-serif !important;

        &::after {
            content: '';
            position: absolute;
            left: 0.5px;
            top: 0.5px;
            border-radius: 7.5px;
            background: var(--hack-do-not-implement, rgba(0, 0, 0, 0.00));

            /* shadow-button/primary */
            box-shadow: 0px 1px 0px 0px rgba(255, 255, 255, 0.48) inset, -1px 0px 0px 0px rgba(255, 255, 255, 0.20) inset, 1px 0px 0px 0px rgba(255, 255, 255, 0.20) inset, 0px -1.5px 0px 0px rgba(0, 0, 0, 0.25) inset;
            right: 0;
            bottom: 0;
        }

        &:hover {
            background: linear-gradient(180deg, $warning-400, $warning-500) !important;
        }
    }

    .ant-table-measure-row {
        display: none !important;
    }

    .BG_deleteButton {
        border-radius: 8px;
        background: var(--red, #FF4343);
        padding: 20px 30px;
        border: none;
        color: var(--text-grey, #636363);
        font-size: 15px;
        font-weight: 500;
        line-height: 100%;
        color: white;
        position: relative;
        transition: .3s ease-in-out;
        display: flex;
        align-items: center;
        gap: 10px;
        font-family: 'Poppins', sans-serif !important;

        &::after {
            content: '';
            position: absolute;
            left: 0.5px;
            top: 0.5px;
            border-radius: 7.5px;
            background: var(--hack-do-not-implement, rgba(0, 0, 0, 0.00));

            /* shadow-button/primary */
            box-shadow: 0px 1px 0px 0px rgba(255, 255, 255, 0.48) inset, -1px 0px 0px 0px rgba(255, 255, 255, 0.20) inset, 1px 0px 0px 0px rgba(255, 255, 255, 0.20) inset, 0px -1.5px 0px 0px rgba(0, 0, 0, 0.25) inset;
            right: 0;
            bottom: 0;
        }

        &:hover {
            opacity: .7;
        }
    }

    .table_wrap {
        margin-top: 30px;



        .ant-input-wrapper {
            flex-direction: row-reverse;
            display: flex;
            align-items: center;
            padding-left: 10px;
            border-radius: 8px;
            overflow: hidden;
            border: 1px solid $secondary-300;
            background: white;
            height: 50px;

            .ant-input-affix-wrapper {
                border: none;
                border-radius: 0;
                box-shadow: none;
                height: 50px;
                padding-left: 35px;
                background: transparent;
            }

            input {
                font-size: 15px;
                background: transparent !important;
                border: none !important;
                height: auto;
                padding: 0rem !important;
                font-family: 'Poppins', sans-serif !important;
                height: 100% !important;
            }

            .ant-input-group-addon {
                background: transparent;

                button {
                    border: none;
                    border-radius: 0;
                    box-shadow: none;
                    background: transparent;
                }

                svg {
                    font-size: 18px;
                    fill: #8c8c8c;
                }
            }
        }

        :where(.css-dev-only-do-not-override-yp8pcc).ant-input-group .ant-input-group-addon,
        :where(.css-dev-only-do-not-override-yp8pcc).ant-input-group .ant-input-group-wrap {
            width: auto;
        }

        .expandIcon {
            width: 30px;
            height: 30px;
            border-radius: 6px !important;
            border: 2px solid #f1f3f6;
            cursor: pointer;
            @include d-flex;
            @include align-center;
            @include justify-center;

            span {
                height: 16px;
            }
        }

        // .ant-table-wrapper::before {
        //     padding-bottom: 25px;
        // }

        .ant-spin-container {
            background: #edebeb !important;
            backdrop-filter: blur(4px);
            border-radius: 6px !important;
        }

        .ant-pagination.ant-table-pagination.ant-table-pagination-right {
            display: flex;
            padding: 16px 30px;
            gap: 0;
            margin: 0;
            justify-content: flex-start;

            .ant-pagination-total-text {
                font-size: 14px;
                color: var(--text-grey, #636363);
                font-weight: 500;
                font-family: 'Poppins', sans-serif !important;
            }

            .ant-pagination-prev {
                margin-left: auto;
            }

            .ant-pagination-item-link {
                border-radius: 8px;
                border: 1px solid var(--border, #DADADA);
                background: var(--white, #FFF);
                display: flex;
                align-items: center;
                justify-content: center;
            }

            .ant-pagination-item-container {
                width: 25px;
            }



            .ant-pagination-item-active {
                background: $background;
                border-color: $secondary;
            }
        }

        .ant-table-thead {
            background: $secondary-200 !important;
            padding: 15px 30px;
            gap: 30px;
        }

    }

    .tableHead_wrap {
        padding: 15px 15px;
        @include d-flex;
        @include align-center;
        @include justify-space-between;
        border-radius: 8px;
        // border: 2px solid $border;
        box-shadow: $box-shadow;
        background: var(--white, #FFF);

        .showPrPage {
            @include d-flex;
            @include align-center;
            gap: 15px;

            :where(.css-dev-only-do-not-override-yp8pcc).ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
                height: 4rem !important;
            }

            span {
                color: #626262;
                font-size: 15px;
                font-family: 'Poppins', sans-serif !important;
            }

            b {
                font-weight: 600;
            }

            .ant-select-selector {
                height: auto !important;
                padding: 2px 15px !important;
            }

            .ant-select-arrow {
                height: 13px;

                svg path {
                    stroke: #c8c8c8;
                }
            }

            .ant-form-item {
                margin-bottom: 0 !important;
            }

            .export {
                margin-right: 20px;

                input {
                    height: 100% !important;
                }

                .ant-select {
                    height: 100% !important;
                }

                .ant-select-selector {
                    background: white !important;
                    border-radius: 8px;
                    border: none !important;
                    background: var(--white, #FFF);
                    box-shadow: 0px 1px 0px 0px #E3E3E3 inset, 0px -2px 0px 0px #E3E3E3 inset, 1px 0px 0px 0px #E3E3E3 inset, -1px 0px 0px 0px #E3E3E3 inset, 0px -1px 0px 0px #B5B5B5 inset;
                }

                .ant-select-selection-item,
                .ant-select-selection-placeholder {
                    font-family: 'Poppins', sans-serif !important;
                    padding: 6px 0;
                }
            }
        }

        // select
        :where(.css-dev-only-do-not-override-yp8pcc).ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
            height: 40px;
            border: 1px solid #e4e7ea;
            background: #f8f9fb;
            padding: 0 10px !important;
        }

        :where(.css-dev-only-do-not-override-yp8pcc).ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
            height: 40px;
        }

        :where(.css-dev-only-do-not-override-yp8pcc).ant-select-single .ant-select-selector .ant-select-selection-item,
        :where(.css-dev-only-do-not-override-yp8pcc).ant-select-single .ant-select-selector .ant-select-selection-placeholder {
            line-height: 40px;
        }

        :where(.css-dev-only-do-not-override-yp8pcc).ant-select .ant-select-arrow {
            margin-top: -3px
        }
    }


    // status css
    .ant-select.pending {
        width: 100%;

        .ant-select-selector {
            border-color: $warning-500 !important;
            background: $warning-100 !important;

            .ant-select-selection-item {
                color: $warning-500;
            }
        }

        .ant-select-arrow path {
            stroke: $warning-500;
        }
    }

    .ant-select.submitted {
        width: 100%;

        .ant-select-selector {
            border-color: $primary-500 !important;
            background: $primary-100 !important;

            .ant-select-selection-item {
                color: $primary-500;
            }
        }

        .ant-select-arrow path {
            stroke: $primary-500;
        }
    }

    .ant-select.approved {
        width: 100%;

        .ant-select-selector {
            border-color: $success-500 !important;
            background: $success-100 !important;

            .ant-select-selection-item {
                color: $success-500;
            }
        }

        .ant-select-arrow path {
            stroke: $success-500;
        }
    }


    // ===========
    .empFlex {
        display: flex;
        align-items: center;
        gap: 10px;

        img {
            width: 30px;
            height: 30px;
            border-radius: 50%;
            object-fit: cover;
        }
    }

    // <---------------ant column css ------------ Pramod----19Oct--------------->

    .tableBody_wrap {
        margin-top: 15px;

        ::-webkit-scrollbar {
            height: 8px;
            width: 4px;
            background: gray;
        }

        /* Track */
        ::-webkit-scrollbar-track {
            background: #f1f1f1;
        }

        /* Handle */
        ::-webkit-scrollbar-thumb {
            background: #888;
        }

        /* Handle on hover */
        ::-webkit-scrollbar-thumb:hover {
            background: #b9b9b9;
        }

        ::-webkit-scrollbar-thumb:horizontal {
            background: #a5a5a5;
            border-radius: 100px;
        }
    }

    .ant-table-column-sorter {
        // display: none;
        position: absolute;
        right: 10px;

        .ant-table-column-sorter-inner {
            span {
                font-size: 9px;
                color: rgb(0 0 0 / 16%);
            }
        }
    }

    .ant-table-column-title {
        >div {
            font-weight: 600;
            font-size: 14px;
            color: $secondary-700;
            white-space: nowrap;
        }

        .flexBtn {
            display: flex;
            align-items: center;
            flex-direction: column;
            margin-top: -6px;

            span {
                height: 10px;
            }
        }
    }

    .ant-table-column-title {
        font-weight: 500;
        font-size: 14px;
        color: #464F60;
        white-space: nowrap;
    }

    .ant-table-cell {
        font-weight: 400;
        font-size: 14px
    }

    .ant-table-cell {
        font-size: 14px !important;
    }


    .ant-table-content>table>thead>tr {
        background: #edebeb;

        th,
        td {
            // font-size: 16px !important;
            text-transform: capitalize !important;
            padding: 16px 20px !important;
            background: transparent !important;
            font-weight: 500;
        }
    }

    .ant-table-wrapper .ant-table-tbody>tr>td {
        padding: 18px 20px !important;
        // height: 70px !important;
        font-size: 15px;

        a {
            color: $secondary-900;
        }

        // &:last-child{
        //     text-align: center;
        // }
    }

    .custom_dropdown_menu {
        width: 250px;
        height: 220px;
    }

    .bd_dataTable_edit {
        width: 300px;
        height: 50px;
    }

    .bd_dataTable_delete {
        width: 300px;
        height: 50px;
    }

    .exportButton {
        display: flex;
        align-items: center;
        gap: 10px;
        font-size: 16px;
        padding: 10px 20px;
        border: 2px solid $border;
        border-radius: 8px;
        cursor: pointer;
        transition: .3s ease-in-out;

        &:hover {
            background: $background;
        }
    }


    .exportButton {
        display: flex;
        align-items: center;
        gap: 10px;
        font-size: 16px;
        padding: 10px 20px;
        border: 2px solid $border;
        border-radius: 8px;
        cursor: pointer;
        transition: .3s ease-in-out;

        &:hover {
            background: $background;
        }
    }

    .doc_arrow_div {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        color: #41A6EC;


        .doc_arrow_div_icon {
            padding: 2px;
            border: 2px solid #41A6EC;
            border-radius: 100%;
            cursor: pointer;

        }

        p {
            cursor: pointer;
            color: #41A6EC;
        }
    }

    // action button
    .bd_tableAction {
        padding: 0 !important;
        min-width: 180px;
        border-radius: 8px;
        border: 1px solid $secondary-300;
        // background: var(--background, #FCFCFC);
        box-shadow: 2px 2px 10px 0px rgba(0, 0, 0, 0.05);

        .ant-dropdown-menu-item {
            padding: 12px !important;
            border-bottom: 1px solid $secondary-300;
        }

        .bd_view_btn,
        .bd_delete_btn {

            span {
                display: flex;
                align-items: center;
                gap: 10px;
            }
        }
    }


    // -----------------
    .bd_delete_model {
        .ant-modal-content {
            padding: 0;
        }

        .ant-modal-body {
            padding: 20px;
        }
    }

    .bd_delete_container {
        padding: 20px;

        .bd_delete_spn {
            font-size: 20px;
            font-weight: 500;
            line-height: 20px;
            color: #303030;
            margin-bottom: 15px;
        }

        p {
            margin-top: 10px;
        }
    }


    .ant-select.ant-select-in-form-item {
        height: 50px !important;
    }

    .ant-select-multiple {
        height: auto !important;
        min-height: 50px;

        .ant-select-selector {
            padding: 0 5px !important;

            .ant-select-selection-wrap {
                height: 50px;
            }
        }
    }

    .ant-select-selector {
        padding: 15px !important;
        border-radius: 8px;
        border: 1px solid $secondary-300 !important;
        background: $white !important;
        overflow: hidden;
    }

    .ant-form-item {
        margin-bottom: 25px
    }

    .ant-col {
        input {
            height: 50px;
            background: $white !important;
            // border: 1px solid $secondary-300;
        }

        textarea {
            background: $white !important;
            font-family: 'Poppins', sans-serif !important;
            border: 1px solid $secondary-300;
        }

        .i-icon-down {
            svg path {
                stroke: #c8c8c8;
            }
        }

        .ant-picker {
            width: 100%;
            height: 47px !important;
            border: 1px solid $secondary-300;
            background: $white;
            overflow: hidden;

            input {
                border: none;
            }
        }

        .ant-input-number {
            width: 100%;
            border: none;
        }

        .ant-input-outlined[disabled] {
            background-color: rgba(0, 0, 0, 0.04) !important;
            border-color: #d9d9d9 !important;
            color: #6e6e6e !important;
        }
    }

    .ant-form-item-label {
        label {
            font-size: 15px;
            font-weight: 400;
            color: $secondary-600 !important;
        }
    }

    .ant-input-group-addon {
        border-left: none !important;
        border-radius: 0px !important;
    }

    .ant-drawer-title {
        color: var(--Text-dark, #303030);
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 100%;
        font-family: "Poppins", sans-serif !important;
    }

    .ant-drawer {
        z-index: 1040 !important;
    }

    .ant-list {
        width: 100%;
    }


    // Editor
    .bd_editor {
        >* {
            font-family: 'Poppins', sans-serif !important;
        }

        .bd_editor_heading {
            font-size: 16px;
            margin-bottom: 16px;
            font-weight: 400;
            color: black;
        }

        .tox-editor-header {
            background: $dark-border !important;
            padding: 10px !important;
            box-shadow: none !important;

            .tox-toolbar__primary {
                background: $dark-border !important;
            }
        }

        body {
            font-family: 'Poppins', sans-serif !important;
        }

        .tox-statusbar {
            display: none !important;
        }

    }

    .ant-modal-content {
        .ant-modal-body {
            margin-top: 25px;
        }
    }

    .ant-form-item-control-input {
        .ant-form-item-control-input-content {
            display: flex;
            justify-content: flex-end;

            .ant-btn {
                background: var(--hack-do-not-implement, #1677ff);
                color: white;
                padding: 16px 25px;
                border-radius: 7.5px;
                border: none;
                font-size: 14px;
                font-weight: 500;
                line-height: 100%;
                transition: 0.3s ease-in-out;
                font-family: "Poppins", sans-serif !important;
                gap: 10px;
                height: auto !important;
                cursor: pointer;
            }
        }
    }

    .bd_model_main {

        .ant-modal-content {
            margin-top: 50px !important;
            padding: 0px;
        }


        div#\:r0\: {
            background-color: #E3E3E3;
            padding: 20px 30px;
            border-radius: 8px;
        }




        // #validateOnly_name {
        //     padding: 15px 20px;
        //     margin-top: 20px;

        // }

        // form#validateOnly {
        //     padding: 30px 30px 0px 30px;
        // }

        #validateOnly_Comment {
            margin-top: 20px;
        }

        .ant-upload.ant-upload-select {
            margin: 40px 20px;

        }

        .bd_model_upload {
            width: 100px;
            height: 32px;
            padding: 6px, 15px, 6px, 15px;
            border-radius: 5px;
            gap: 10px;
            background-color: #41A6EC;
            color: #fff;
            border: none;

        }

        .bd_model_footer_button {
            width: 100%;
            text-align: right !important;
        }

        .bd_model_footer_button button {
            background-color: #FF5834;
            width: 102px;
            height: 52px;
            padding: 18px, 30px, 18px, 30px;
            border-radius: 8px;
            border: none;
            color: #fff;
            margin: 0px 50px 20px 0px;
        }

        // for remove default footer buttons of ant design modal pageName competitor
        .ant-modal-footer {
            display: none;
        }


    }

    // ======== Modal css
    .ant-modal {
        width: 700px;

        .ant-modal-content {
            >* {
                font-family: 'Poppins', sans-serif !important;
            }

            .ant-modal-close {
                top: 22px;
            }

            .ant-modal-header {
                border-bottom: 1px solid var(--border-border, #E3E3E3);
                background: var(--background-bg-secondary, #F3F3F3);
                padding: 20px;
                margin-bottom: 0;

                .ant-modal-title {
                    font-weight: 500;
                }
            }

            .ant-modal-footer {
                display: flex;
                align-items: center;
                justify-content: flex-end;
                gap: 20px;
                padding: 20px;
                border-top: 1px solid $border;
            }
        }

        .btn_flex {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            gap: 20px;
        }
    }


    .ant-dropdown-menu {
        // padding: 8px 4px !important;
        background-clip: padding-box;
    }

    .bd_delete_btn {
        color: rgb(214 59 15) !important;
        font-weight: 500;
    }

    span.anticon.anticon-edit {
        padding-right: 6px !important;
        font-size: 15px;
    }

    span.anticon.anticon-delete {
        padding-right: 6px;
    }

    .ql-toolbar {
        background: #F2F2F2 !important;
        padding: 15px !important;
        box-shadow: none !important;
        overflow: visible !important;
    }

    .ql-container {
        border-bottom: 2px solid #eee;
        border-bottom-right-radius: 10px;
        border-bottom-left-radius: 10px;
        box-shadow: none;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
        overflow: hidden;
        position: relative;
        visibility: inherit !important;

    }

    .ql-toolbar {

        border-top: 2px solid #eee;
        border-bottom: none;
        border-top-right-radius: 10px;
        border-top-left-radius: 10px;
        box-shadow: none;
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
        overflow: hidden;
        position: relative;
        visibility: inherit !important;
    }


    // vinay
    .bd-advanceFilter {
        .bd_customOption {
            margin-top: 20px;

            .ant-form-item-label {
                margin-bottom: 5px;

                label {
                    font-size: 14px;
                }
            }
        }

        .ant-radio-checked .ant-radio-inner {
            border-color: orange !important;
            background-color: orange;
        }

        .ant-radio-wrapper {
            span {
                font-family: 'Poppins', sans-serif !important;
            }
        }

        .ant-form-item-label {
            margin-bottom: 15px;
        }
    }

    .ant-table-cell:nth-last-child(1),
    .ant-table-cell:nth-last-child(2) {
        font-weight: 500;
        font-size: 14px;
        color: #464F60;
        white-space: nowrap;
    }

    .expire_date_doc {
        font-weight: 400;
        font-size: 14px;
        color: red;
    }

    .doc_copy_arrow_div {
        font-size: 22px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: rgb(135, 135, 229);
        cursor: pointer;
    }

    .pending {
        color: red;
    }

    .approval {
        color: green;
    }

    .submitted {
        color: blue;
    }

}

.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
    z-index: 9;
}

.ant-pagination-options {
    display: none !important;
}

.star {
    color: red;
}

.disabledBtn {
    background-color: gray;
    cursor: not-allowed;
    border-radius: 8px;
    // background: var(--secondary,#FF5834);
    padding: 16px 25px;
    border: none;
    color: var(--text-grey, #636363);
    font-size: 15px;
    font-weight: 500;
    line-height: 100%;
    color: white;
    position: relative;
    transition: .3s ease-in-out;
    display: flex;
    align-items: center;
    gap: 10px;
    height: auto !important;
    font-family: 'Poppins', sans-serif !important;

}



// -------------

.sectorChip {
    background: $primary-100;
    border: 1px solid $primary-400;
    color: $primary-500;
    border-radius: 4px;
    padding: 3px 8px;
    font-size: 13px;
    width: fit-content;
    margin-top: 10px;
}

.tenderNameText {
    font-weight: 500;
    color: $black;
}

.tenderLocation {
    margin-bottom: 12px;
    display: flex;
    align-items: center;
    line-height: 100%;
    color: $danger-400;

    svg {
        margin-right: 8px;
    }
}

.briefcase__modal {
    .ant-modal-body {
        padding: 0;
    }
}

// .no-grid-data {
//     .ant-table-row-level-1 {
//         td {
//             border-bottom: 0px !important;
//             border-top: 0px solid #f0f0f0 !important;
//         }

//     }

//     .ant-table-row-level-1:last-child {
//         td {
//             border-bottom: 1px solid black !important;
//             border-top: 0 !important;
//         }
//     }

// }


.addMore_item {
    position: relative;

    .addMore_btn {
        position: absolute;
        right: 10px;
        top: 0;
        border: none;
        background: none;
    }
}