.chat-grid-module {
    @import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap');

    @import '../css/variables.scss';
    @import '../css/mixins.scss';
    @import '../css/style/Detail.scss';


    /* width */
    ::-webkit-scrollbar {
        width: 5px;
        height: 5px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        border-radius: 10px;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: rgb(191, 191, 191);
        border-radius: 10px;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: rgb(159, 159, 159);
    }


    .buutton_flex {
        display: flex;
        align-items: center;
        gap: 20px;
    }

    .d-flex {
        @include d-flex;
        @include align-center;
    }

    .align-center {
        @include align-center;
    }

    .w-100 {
        width: 100%;
    }

    .h-100 {
        height: 100%;
    }

    .grid {
        grid-gap: 0px !important;
        gap: 0 !important;
    }

    .unstyled-button {
        border: none;
        background-color: transparent;
        outline: none;
        color: #494949;
        border-radius: 100%;
        width: 40px;
        height: 40px;

        &:hover {
            color: #000;
            background-color: #e9e9e9;
        }
    }


    .user-type-tab {
        .ant-tabs-nav {
            background-color: white;
            z-index: 10;
            position: sticky;
            top: 0px;
            padding: 0px 18px;
            border-bottom: 1px solid #dbdbdb;

            .ant-tabs-ink-bar {
                background-color: #000 !important;
            }

            .ant-tabs-tab {
                padding: 16px 0px;

                .ant-tabs-tab-btn {
                    color: #6b6b6b;
                    // dfd 
                }

                &:hover,
                &.ant-tabs-tab-active {
                    .ant-tabs-tab-btn {
                        color: #000;
                    }

                }
            }

        }
    }

    .main_wrapper {
        padding-bottom: 0;
        margin: 0px 0 0 0;
        height: calc(100vh - 80px);

        .ant-btn-submit.ant-btn-icon-only {
            z-index: 99 !important;
        }

        .grid {
            overflow: hidden;
            display: flex;
        }

        .user-list-section {
            width: 400px;
        }

        .userProfile {
            // display: flex;
            // align-items: center;
            // gap: 20px;
            // flex-direction: column;
            // padding: 0px 20px;
            transition: all .3s;
            background: $background;
            border-bottom: 1px solid #dbdbdb;

            .search_bar {
                flex: 1;
                // border: 1px solid #eee !important;
                border-radius: 5px;
            }

            .profile {
                display: flex;

                img {
                    width: 45px;
                    height: 45px;
                    border-radius: 50%;
                    border: 3px solid #fff;
                    object-fit: cover;
                    cursor: pointer;
                }
            }

            .profile_heading {
                h4 {
                    font-size: 17px;
                    font-weight: 500;
                    color: $Text-dark;
                    margin-bottom: 0;
                }

                span {
                    font-size: 13px;
                    font-weight: 400;
                    color: $grey;
                }
            }

            .groupChat_btn {
                .mainButton {
                    padding: 13px;
                    font-size: 12px;
                }
            }

            .action_btn {
                display: flex;
                align-items: center;
                gap: 20px;
                position: relative;

                img {
                    width: 20px;
                    opacity: .5;
                    cursor: pointer;
                    transition: .2s ease-in-out;

                    &:hover {
                        opacity: .7;
                    }
                }

                .dropdown-items {
                    box-shadow: 0 3px 8px rgb(0 0 0 / 7%);
                    width: 200px;
                    background: white;
                    position: absolute;
                    right: 0;
                    top: 30px;
                    z-index: 99;
                    border-radius: 4px;
                    padding: .5px 0;

                    .dropdown__link {
                        padding: 15px 20px;
                        font-size: 14px;
                        font-weight: 500;
                        color: #494949;
                        cursor: pointer;

                        &:hover {
                            background: $background;
                        }
                    }
                }

                .isVisible {
                    visibility: visible;
                }

                .isHidden {
                    visibility: hidden;
                }
            }
        }

        .chat_sideBar {
            background: $white;
            height: 100%;
            position: relative;
            border-right: 1px solid #dbdbdb;

            .search_bar {
                position: relative;
                // border-bottom: 1px solid $border;
                // background: $background;
                display: flex;
                align-items: center;
                padding-right: 20px;
                margin: 0;
                // border-radius: 0;
                overflow: hidden;

                input {
                    width: 100%;
                    position: relative;
                    height: 52px;
                    border: none;
                    font-size: 14px;
                    padding-inline: 20px;
                    background: $background;
                }
            }
        }

        .chat__wrapper {
            // background: #ededed;
            height: 100%;
            position: relative;
            overflow: hidden;

            .userProfile {
                // border-left: 1px solid #fff;
                border-bottom: 1px solid #dbdbdb;
                padding: 0px 20px;
                display: flex;
                align-items: center;
                // justify-content: space-between;
                height: 64px;

                .profile {
                    align-items: center;
                    gap: 20px;

                    .name {
                        font-size: 15px;
                        font-weight: 500;
                        color: $Text-dark;

                        .text_align_left {
                            color: green !important;
                            font-weight: 400 !important;
                            margin-top: 5px !important;
                        }
                    }
                }
            }

            .mesg__typer {
                background: rgb(255 255 255 / 50%);
                backdrop-filter: blur(5px);
                display: flex;
                align-items: center;
                gap: 20px;
                padding: 10px 20px;
                position: sticky;
                bottom: 20px;
                left: 30px;
                width: calc(100% - 60px);
                border-radius: 50rem;

                .uploadFileIcon {
                    border: none;
                    // background: none;
                    width: 40px;
                    height: 40px;
                    display: flex;
                    background-color: transparent;
                    color: #4d4d4d;
                    align-items: center;
                    justify-content: center;
                    border-radius: 50%;
                    cursor: pointer;
                }

                .show_file_box-2 {
                    .select-doc-container {
                        width: max-content;
                    }

                    .select-doc-card {
                        background-color: white;
                        // box-shadow: 1px 1px 8px 0px #dbdbdb;
                        border-radius: 10px;
                        overflow: hidden;
                        position: relative;
                        width: 112px;
                        padding: 6px;

                        .close-icon {
                            position: absolute;
                            right: 1px;
                            top: 6px;
                            color: white;
                            background-color: rgba(0, 0, 0, 0.5);
                            padding: 4px;
                            border-radius: 100px;
                        }

                        img {
                            width: 120px;
                            height: 120px;
                            object-fit: cover;
                            opacity: 1 !important;
                            border-radius: 6px;
                            // margin-bottom: 6px;
                        }

                        p {
                            border: none !important;
                            padding: 0 !important;
                            font-size: 12px;
                        }
                    }
                }

                .show_file_box {
                    width: 100%;
                    height: 170px;
                    position: absolute;
                    bottom: 60px !important;
                    background: #fff;
                    transition: 0.3s all;
                    color: #000;
                    left: 0px;
                    padding: 20px;
                    z-index: 10;

                    // display: flex;
                    // gap: 20px;
                    .select-doc-container {
                        width: max-content;
                    }

                    .select-doc-card {
                        background-color: white;
                        box-shadow: 1px 1px 8px 0px #dbdbdb;
                        border-radius: 10px;
                        overflow: hidden;
                        position: relative;
                        width: 112px;
                        padding: 6px;

                        .close-icon {
                            position: absolute;
                            right: 4px;
                            top: 4px;
                            color: white;
                            background-color: rgba(0, 0, 0, 0.5);
                            padding: 4px;
                            border-radius: 100px;
                        }

                        img {
                            width: 100px;
                            height: 100px;
                            object-fit: cover;
                            opacity: 1 !important;
                            border-radius: 6px;
                            margin-bottom: 6px;
                        }

                        p {
                            border: none !important;
                            padding: 0 !important;
                            font-size: 12px;
                        }
                    }

                    // display: flex;
                    // gap: 20px;
                    p {
                        border: 2px solid #494949;
                        border-radius: 10px;
                        padding: 4px 10px;
                    }

                    button.ant-btn.css-dev-only-do-not-override-nllxry.ant-btn-submit.ant-btn-icon-only {
                        position: absolute;
                        bottom: 0;
                        right: 50px;
                    }
                }

                .uploadFile_wrapper {
                    position: absolute;
                    height: 140px;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    background: rgb(255, 255, 255);
                    padding: 20px;
                    transition: .4s ease-in-out;

                    .closeUploadModal {
                        position: absolute;
                        right: 20px;
                        top: 20px;
                        background: #00000017;
                        width: 35px;
                        height: 35px;
                        border-radius: 50%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        font-size: 14px;
                        color: #686868;
                        cursor: pointer;
                        transition: .2s ease-in-out;

                        &:hover {
                            opacity: .5;
                        }
                    }

                    .ant-upload-list-item-container,
                    .ant-upload {
                        width: 160px;
                        height: 160px;
                    }

                    .ant-upload-list-item-thumbnail {
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        span.anticon {
                            font-size: 50px;
                            margin-top: -20px;
                        }
                    }

                    .ant-upload-list-item {
                        width: auto;
                    }
                }

                img {
                    width: 20px;
                    opacity: .5;
                    transition: .2s ease-in-out;
                    cursor: pointer;

                    &:hover {
                        opacity: .8;
                    }
                }

                input {
                    width: 100%;
                    // height: 4rem;
                    border: none;
                    background: transparent;
                    font-size: 14px;
                    padding-left: 20px 10px;
                }

                .send_btn {
                    width: 45px;
                    height: 45px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;

                    button {
                        border: none;
                        background: none;
                    }

                    img {
                        opacity: 1;
                    }
                }
            }
        }
    }

    @import '../css/style/userList.scss';
    @import '../css/style/chats.scss';
    @import '../css/style/profile.scss';

    // ======= Drawer =======

    .sidebar-overlay {
        background: rgba(0, 0, 0, 0.5);
        height: 100%;
        top: 0;
        left: -100%;
        position: absolute;
        width: 100%;
        z-index: 99;
    }

    .drawer_box {
        background: #ffffff;
        height: 100vh;
        transform: translate(0, 0);
        left: -100%;
        bottom: 0;
        margin: 0;
        padding: 4rem;
        position: absolute;
        width: 100%;
        z-index: 991;
        // Transition
        transition: .3s ease-in-out;

        .btn__close {
            position: absolute;
            top: -1px;
            right: -1px;
            padding: 30px;
            border: 1px solid $border;
            background: white;
            font-size: 1.3rem;
            text-transform: uppercase;
            cursor: pointer;
            transition: .2s ease-in-out;

            &:hover {
                background: $background;
            }
        }

    }

    .profile-section-overlay {
        position: relative;
        overflow: hidden;
        background: #ffffff;
        transition: all .3s;
        border-left: 1px solid #dbdbdb;
        // height: 100vh;
    }

    .profile-section {
        width: 360px;
        padding: 12px 22px;
        position: relative;
        transition: .3s ease-in-out;
    }

    // <-------------------------CSS------------------------->

    // drawer

    #drawer {
        right: -100%;
        left: auto;
    }
}

.bd_chat_module {
    overflow: hidden !important;

    .bd_footerSection {
        display: none;
    }
}