.container {
  position: relative;
}


.container,
.container-fluid,
.grid_container {
  width: 100%;
  padding-right: var(1.5rem, .75rem);
  padding-left: var(1.5rem, .75rem);
  margin-right: auto;
  margin-left: auto
}

@media (min-width:576px) {

  .container,
  .grid_container {
    max-width: 540px
  }
}

@media (min-width:768px) {

  .container,
  .grid_container {
    max-width: 720px
  }
}

@media (min-width:992px) {

  .container,
  .grid_container {
    max-width: 960px
  }
}

@media (min-width:1200px) {

  .container,
  .grid_container {
    max-width: 1140px
  }
}

@media (min-width:1440px) {

  .container,
  .grid_container {
    max-width: 1300px
  }
}

@media (min-width:1600px) {

  .container,
  .grid_container {
    max-width: 1440px
  }
}

@media (min-width:1920px) {

  .container,
  .grid_container {
    max-width: 1580px
  }
}



$columns: 12;
$gap: 30px;
$breakpoints: (
  xs: 480px,
  sm: 768px,
  md: 960px,
  lg: 1170px,
  xl: 1280px
);

@mixin create-selectors($breakpoint: null) {
  $infix: if($breakpoint ==null, '', '-#{$breakpoint}');

  @for $i from 1 through $columns {
    .col#{$infix}-#{$i} {
      grid-column-end: span $i;
    }

    .col-offset#{$infix}-#{$i} {
      grid-column-start: $i + 1;
    }

    .row#{$infix}-#{$i} {
      grid-row-end: span $i;
    }

    .row-offset#{$infix}-#{$i} {
      grid-row-start: $i + 1;
    }
  }
}

.grid {
  display: grid;
  grid-template-columns: repeat($columns, 1fr);
  grid-gap: $gap;
  gap: clamp(15px, 2vw, $gap);
}

@include create-selectors;

@each $breakpoint,
$width in $breakpoints {
  @media (min-width: $width) {
    @include create-selectors($breakpoint);
  }
}

// ===================

@mixin d-flex() {
  display: flex;
}

@mixin align-center() {
  align-items: center;
}

@mixin justify-center() {
  justify-content: center;
}

@mixin justify-space-between() {
  justify-content: space-between;
}
