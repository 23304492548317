/* ------- Responsive css --------- */
@media only screen and (min-width: 1550px) and (max-width: 1600px) {
    .Technical-Solutions .slick-prev {
        left: 46.1% !important;
    }

    .Technical-Solutions .slick-next {
        right: 46.1% !important;
    }

}

@media only screen and (min-width: 1601px) and (max-width: 1700px) {
    .Technical-Solutions .slick-prev {
        left: 46.4% !important;
    }

    .Technical-Solutions .slick-next {
        right: 46.4% !important;
    }
}

@media (max-width: 1500px) {
    .section_para {
        margin-top: 50px !important;
    }
}

@media (max-width: 1440px) {
    .section_para {
        margin-top: 0 !important;
    }

    .Technical-Solutions .slick-prev {
        left: 45.7% !important;
    }

    .Technical-Solutions .slick-next {
        right: 45.7% !important;
    }
}

@media (max-width: 1280px) {
    .content-ts {
        position: relative !important;
    }

    .content-ts .pt-5 {
        padding-top: 0 !important;
    }

    .t-Solutions {
        top: 0;
    }

    .Technical-Solutions .slick-prev {
        left: 45.3% !important;
    }

    .Technical-Solutions .slick-next {
        right: 45.3% !important;
    }

    .contact-item span {
        display: block !important;
    }
}

@media (max-width: 1025px) {
    .Technical-Solutions .slick-prev {
        left: 44.1% !important;
    }

    .Technical-Solutions .slick-next {
        right: 44.1% !important;
    }

    .contact-item span {
        display: block !important;
    }

    .signup_body .form__icons {
        flex-direction: column !important;
    }

    .companyList_wrapper .flexGrid {
        display: contents !important;
    }

    .comList {
        grid-template-columns: 1fr !important;
    }
}

@media only screen and (min-width: 1240px) and (max-width: 1400px) {
    .tender-details-container {
        max-width: 1320px !important;
        padding-left: 25px !important;
        padding-right: 25px !important;
    }
}

@media only screen and (min-width: 1200) and (max-width: 1300px) {
    .contact-item span {
        display: flex !important;
        flex-direction: column !important;
    }
}

@media only screen and (min-width: 1212px) and (max-width: 1239px) {
    .tender-details-container {
        max-width: 1320px !important;
    }
}

@media only screen and (min-width: 1200px) and (max-width: 1211px) {
    .media-xl-right-cls {
        width: 69.666667% !important;
    }

    .media-xl-left-cls {
        width: 30.333333% !important;
    }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
    ul.nav.nav-tabs li.nav-item .nav-link {
        margin-left: 1rem !important;
    }

    .flex-row {
        flex-direction: column !important;
        align-items: center !important;
    }

    .MuiTableCell-sizeMedium {
        font-size: 0.800rem !important;
    }
}

@media only screen and (min-width: 768px) and (max-width: 1199px) {
    .btn-get-trial {
        width: 28% !important;
    }

    .module_header .nav-item {
        padding: 20px 7px !important;
    }
}

@media only screen and (min-width: 816px) and (max-width: 992px) {
    .tender-details-container {
        max-width: 1200px !important;
    }
}

@media only screen and (min-width: 500px) and (max-width: 816px) {
    ul.nav.nav-tabs {
        overflow-x: auto !important;
        max-width: 177px !important;
    }

    .tab-content {
        /* margin-left: 20px; */
        margin-top: 44px !important;
    }

    .media-md-right-cls {
        display: flex !important;
    }
}

@media (max-width: 991px) {

    .header_side_menu {
        display: block !important;
    }

    .silder-videos {
        padding-block: 150px 60px !important;
        margin-top: auto !important;
    }

    .ebCard_flex {
        grid-template-columns: repeat(2, 1fr) !important;
    }

    .authority_wrapper_container .ebCard_flex .stateItem .d-flex {
        font-size: 12px !important;
    }

    .authority_wrapper_container .ebCard_flex .stateItem .tenderCount {
        font-size: 11px !important;
    }

    .authority_wrapper_container .ebCard_flex .stateItem {
        padding: 20px !important;
        gap: 11px !important;
    }

    .content-carousel h2.alternate {
        font-size: 60px !important;
    }

    .carousel-inner {
        bottom: 0 !important;
    }

    .subscribe-form .theme-btn {
        font-size: 11px !important;
    }

    .btn-one {
        padding: 14px 0.5rem !important;
    }

    .hide--desktop {
        display: none !important;
    }

    .navbar-nav {
        gap: 20px !important;
    }

    .header .mainButton {
        padding: 18px 10px !important;
    }

    .contact-item {
        flex-direction: column !important;
        gap: 10px !important;
    }

    .contact-item a {
        color: var(--primary) !important;
    }

    .asideWrapper {
        padding: 30px 15px 30px 15px !important;
    }

    #sidebar {
        position: absolute !important;
        top: 0px !important;
        left: -100% !important;
        height: 100% !important;
        width: 312px !important;
        transition: all 300ms linear !important;
        z-index: 1000 !important;
    }

    #sidebar.active {
        left: 0 !important;
    }

    .company_sidebar_cont .flexGrid #sidebar {
        top: auto !important;
        overflow: auto !important;
        bottom: -97px !important;
    }

    .headTitle {
        padding-top: 20px !important;
    }

    .hide--mobile {
        display: none !important;
    }

    .header .innerMenu .navbar-brand {
        position: relative !important;
    }

    .header .innerMenu {
        /* padding: 10px !important; */
    }

    .innerMenu {
        flex-direction: row;
        padding: 0 !important;
    }

    .module_header_main {
        overflow: auto !important;
        right: 0 !important;
        position: fixed !important;
        transition: right 0.4s ease-in !important;
        bottom: 0 !important;
        height: 100% !important;
        background-color: #ffffff !important;
        z-index: 999 !important;
        top: 0 !important;
        width: 100% !important;
    }

    .module_header .nav-item>span {
        font-size: 12px !important;
    }

    .module_header_drawer {
        height: 100% !important;
        width: 350px !important;
        position: fixed !important;
        z-index: 1 !important;
        top: 0px !important;
        right: -350px !important;
        overflow-x: hidden !important;
        transition: 0.5s !important;
        padding-top: 60px !important;
        transition: right 0.3s ease-in-out !important;
    }

    .grid-icon {
        display: none !important;

    }

    .navbar-nav-phone {
        display: flex !important;
        justify-content: center !important;
        align-items: center !important;

    }

    .navbar-nav-phone .nav-item {
        background: white !important;
        height: 75px !important;
        display: flex !important;
        justify-content: center !important;
        align-items: center !important;
    }

    .noVisiblemenu {
        display: none !important;
    }

    .modal-content .mainButton {
        padding: 12px !important;
        font-size: 12px !important;
    }

    .modalBody {
        padding: 20px !important;
    }

    .social_iconPopp {
        width: 30px !important;
        height: 30px !important;
    }

    .nosubMenu {
        display: none !important;
    }

    .Get-Quote p {
        font-size: 11px !important;
    }

    .share_link {
        height: 40px !important;
        padding-left: 35px !important;
        font-size: 12px !important;
    }

    .navbar_close {
        width: 15px !important;
        height: 15px !important;
        display: block !important;
        margin-right: 10px !important;
    }

    .navbar_inner_close {
        display: block !important;
    }

    .innerMenu .navbar-brand {
        width: 100% !important;
        display: flex !important;
        justify-content: space-between !important;
        align-items: center !important;
    }

    .module_header .innerMenu {
        width: 100% !important;
        overflow: auto !important;
    }

    .innerMenu {
        display: block !important;
    }

    .mainMenuNew {

        display: block !important;
    }

    .innerMenu .navbar-brand img {
        width: 110px !important;
    }

    .module_header .main-menu {
        flex-direction: column !important;
    }

    .navbar-light .navbar-toggler {
        background: white !important;
    }

    .navbar-expand-lg .navbar-collapse {
        border-left: none !important;
        margin-left: 0 !important;
        padding-left: 0 !important;
        border-top: none !important;
        margin-top: 15px !important;
        padding-top: 15px !important;
    }

    .top-menu {
        padding: 0 0 20px !important;
    }

    .navbar-nav {
        align-items: flex-start !important;
        gap: 0 !important;
        height: auto !important;
        padding-top: 0 !important;
    }

    .btn_getTrail {
        display: none !important;
    }

    .formWarpper .orItem:after {
        top: 11px !important;
    }

    .nav-item {
        width: 100% !important;
        display: block !important;
        border-radius: 4px !important;
    }

    ul.main-menu>li>span {
        padding: 15px !important;
        background: white !important;
    }

    ul.main-menu>li>.dropdown-menu,
    .main-menu-right .dropdown-menu {
        background: var(--bodyNew) !important;
    }

    ul.main-menu>li .dropdown-menu {
        /* display: block !important; */
        /* position: fixed !important; */
        top: 0 !important;
        right: -100% !important;
        left: auto !important;
        width: 100% !important;
    }

    ul.main-menu>li:hover>.dropdown-menu,
    .main-menu-right .dropdown:hover .dropdown-menu {
        display: none !important;
    }

    ul.main-menu>li .dropdown-menu.visbleSubMenu {
        display: block !important;
        right: 0 !important;
    }



    .subMenu_grid {
        display: flex !important;
        flex-direction: column !important;
    }

    .display-none-cont {
        display: none !important;
    }

    .contact-item span {
        margin-bottom: 6px !important;
    }

    .contact-item span:last-child {
        margin-bottom: 0 !important;
    }

    .main-menu-right {
        margin-top: 0 !important;
        flex-direction: column !important;
        border-left: none !important;
        padding-left: 0 !important;
        gap: 20px !important;
    }

    .menuNavigation,
    .navbar-expand-lg .navbar-collapse {
        display: none !important;
    }

    .top-menu {
        display: none !important;
        border-bottom: 0 !important;
    }

    .header .innerMenu .navbar-brand {
        left: 0 !important;
        top: 3px !important;
        height: auto !important;
        padding: 10px 15px !important;
    }

    .navbar-expand-lg .navbar-toggler {
        display: block !important;
    }

    .hide--buttons-media {
        display: block !important;
    }

    .main-header-responsive {
        display: flex !important;
        justify-content: center !important;
        align-items: center !important;
        gap: 5px !important;
    }

    .phone-media-respo {
        display: flex !important;
        align-items: center !important;
        justify-content: space-between !important;
        padding: 15px 20px !important;
        background: #f1f1f1 !important;
    }

    .navbar_inner_close {
        width: 14px !important;
        opacity: .7 !important;
    }

    .submenu-close {
        display: flex !important;
        gap: 20px !important;
        align-items: center !important;
        text-transform: capitalize !important;
        font-size: 17px !important;
        /* justify-content: flex-end; */

    }

    .hero_banner {
        height: 100% !important;
    }

    .hero_banner img {
        height: 100% !important;
    }

    .social_subscribe {
        display: flex !important;
        gap: 10px !important;
        margin-left: 0 !important;
        width: auto !important;
        margin-left: 0 !important;
    }

    .profile_fullname {
        display: flex !important;
        align-items: center !important;
        text-align: center !important;
        font-family: 'Poppins', sans-serif !important;
        margin: 0 !important;
        font-weight: 500 !important;
        color: #000 !important;
    }

    .css-ahj2mt-MuiTypography-root {
        font-size: 12px !important;
    }

    .radioFlex .css-1hbvpl3-MuiSvgIcon-root {
        width: 20px !important;
    }

    .css-vqmohf-MuiButtonBase-root-MuiRadio-root.Mui-checked svg {
        left: -1px !important;
    }

    .module_header {
        padding-inline: 0 !important;
        height: 100%;
    }

    .model_login_popup .signUpLink {
        font-size: 9px !important;
    }

    .signUpLink {
        font-size: 12px !important;
    }

    .boBack_button {
        top: 9px !important;
        left: 11px !important;
    }

    .formWarpper {
        padding: 12px 20px !important;
    }

    .coming-soon {
        font-size: 6px;
        margin-bottom: 0 !important;
    }

    .breadcrumb {
        top: 57px !important;
    }

    .planWrapper {
        margin-top: 0px !important;
        padding: 20px;
    }

    .planWrapper .section-title {
        margin-bottom: 30px;
    }

    .contact_para,
    .contactInformatin .faq-link-cls {
        font-size: 13px;
    }

    .ContactWrapper .innerContent p {
        font-size: 13px;
    }

    .contactInformatin .about-content-two h1 {
        font-size: 20px;
    }

    .pay_what_wrapper h6 {
        font-size: 14px !important;
        width: 100% !important;
    }

    .pay_what_wrapper h3 {
        font-size: calc(1rem + .4vw) !important;
    }

    .pb__dte_item ul li {
        font-size: 13px !important;
    }

    ul.breadcrumb {
        margin-top: 62px !important;
    }

    .white-tab .nav {
        overflow: scroll !important;
    }

    .white-tab .nav-item span {
        font-size: 13px !important;
        padding: 14px !important;
        width: 200px !important;
    }

    .css-18sg6k4-MuiPaper-root-MuiDrawer-paper,
    .css-1ciwpa8 {
        width: 100% !important;
    }

    /* .phone-advance-button {
        width: 100% !important;
    } */

    .phone-advance-button .buttonGrp {
        width: 100% !important;
        gap: 10px !important;
    }

    .phone-advance-button .buttonGrp>* {
        flex: 1 !important;
    }

    .css-1ndpvdd-MuiTableCell-root {
        padding: 8px !important;
    }

    .MuiTable-root td {
        padding: 10px !important;
    }

    .otpBox * input {
        width: 30px !important;
        height: 30px !important;
    }

    .otpBox {
        margin-bottom: 8px !important;
    }

    .css-bdhsul-MuiTypography-root-MuiDialogTitle-root {
        font-size: 12px !important;
    }

    .errore_poppp {
        font-size: 10px !important;
        margin-top: 0 !important;
    }

    .bodyModal span,
    button {
        font-size: 11px !important;
    }

    .module_header_main .social_subscribe {
        display: block !important;
    }

    .MuiMenu-list .MuiSvgIcon-fontSizeSmall {
        width: 23px !important;
        height: 23px !important;
    }

    .profileInformation__wrap .profileinfoitem {
        display: flex !important;
        gap: 10px !important;
        align-items: center !important;
        margin-bottom: 0 !important;
    }

    .profile_finall {
        padding: 0px !important;
    }

    .profile_wrapper .mb-4 {
        margin-top: 25px !important;
    }

    .detContent .nameUser,
    .css-1hv8oq8-MuiStepLabel-label.Mui-active {
        font-size: 13px !important;
    }

    .modalHeader .MuiSvgIcon-fontSizeMedium {
        width: 23px !important;
        height: 23px !important;
        font-weight: 400 !important;
    }

    .fileName {
        font-size: 12px !important;
        padding: 10px 10px !important;
    }

    .walletWrapper {
        padding: 35px 0 !important;
    }

    .profile_finall {
        flex-direction: column !important;
    }

    .walletWrapper .mainButton {
        padding: 13px 10px !important;
    }

    .paymentMethods .title,
    .paymentHistory .title {
        margin-top: 29px !important;
    }

    .containerWrapper .section-title h1 {
        margin-top: 20px !important;
    }

    .features-tab .nav-tabs a {
        height: 40px !important;
        font-size: 13px !important;
    }

    .settingWapper .filterWrapper .col-md-4 {
        width: 100% !important;
    }

    .activePlan_wrapper .section-title h2 {
        font-size: 18px !important;
        text-align: center !important;
    }

    .basicPlanCard .card-title {
        font-size: 14px !important;
    }

    .basicPlanCard .card-body ul li {
        font-size: 13px !important;
    }

    .planDescription .titleTitle {
        font-size: 15px !important;
    }

    .tab-pane ul li b {
        font-size: 14px !important;
    }

    .clients_name {
        padding: 10px !important;
        font-size: 12px !important;
    }

    .serachWrapper .mainButton {
        padding: 18px 20px !important;
        height: 48px !important;
        align-items: center !important;
        /* width: 100% !important; */
    }

    .serachWrapper .mainButton svg {
        width: 16px !important;
    }

    .search_tags .tagItem .css-i4bv87-MuiSvgIcon-root {
        width: 14px !important;
        height: 14px !important;
    }

    .serachWrapper input {
        height: 46px !important;
        font-size: 10px !important;
    }

    .serachWrapper .search_icon .mainButton {
        height: 46px !important;
        padding: 10px 20px !important;
    }

    /* .sorting_button {
        width: 100%;
    } */

    .sorting_button .MuiButton-contained {
        padding: 12px;
        width: 100% !important;
    }

    .wishlist_tenderCont .buttonGrp {
        flex-direction: column;
    }

    .wishlist_tenderCont .buttonGrp .from_year {
        width: 100% !important;
    }

    .sorting_button .MuiButton-endIcon {
        margin-left: auto !important;
    }

    .MuiMenuItem-root {
        font-size: 12px !important;
        min-height: 42px !important;
    }

    .sort_item svg {
        width: 16px;
    }

    .search_tags .tagItem {
        padding: 4px 13px !important;
    }

    .search_tags .tagItem .tTex .chips_name {
        font-size: 10px;
        line-height: 130%;
    }

    .css-1b54hrp-MuiDataGrid-root .MuiDataGrid-row:not(.MuiDataGrid-row--dynamicHeight)>.MuiDataGrid-cell {
        font-size: 12px !important;
    }

    .collectionManage .collectioName {
        font-size: 13px !important;
        margin-bottom: 10px !important;
        height: auto !important;
    }

    .features-tab .nav-tabs a {
        height: 40px !important;
        font-size: 13px !important;
    }

    .settingWapper .features-tab .nav-tabs a {
        padding: 20px !important;
        line-height: 45% !important;
        font-size: 14px !important;
    }

    .settingWapper .filterWrapper .col {
        flex: 100% !important;
        margin-bottom: 18px !important;
    }

    .activePlan_wrapper .section-title h2 {
        font-size: 18px !important;
    }

    .basicPlanCard .card-title {
        font-size: 14px !important;
    }

    .settingWapper .labelText {
        font-size: 14px !important;
    }

    .settingWapper .card {
        padding: 15px !important;
        height: auto !important;
    }

    .modal_heading {
        font-size: 13px !important;
    }

    .tender_button .mainButton {
        padding: 13px !important;
        font-size: 11px !important;
    }

    .tenderDetails_warpper {
        padding-bottom: 30px !important;
    }

    .tenderDetails_warpper .client__name {
        font-size: 13px !important;
    }


    .flex_head .daysLeft .card-title {
        display: none !important;
    }

    .profile-foreground .tc-white span {
        font-size: 13px !important;
    }

    .profile-foreground .d-flex {
        justify-content: flex-start !important;
        flex-wrap: wrap !important;
    }

    .tenderDetails_warpper .daysLeft h2 {
        font-size: 16px !important;
    }

    .tenderDetails_warpper .tc-white {
        font-size: 14px !important;
        margin-block: 24px 10px !important;
    }

    .navbars_tender .MuiTabs-root {
        display: none !important;
    }

    .modalHeader {
        font-size: 15px !important;
    }

    /* .showOptions {
        top: 100px !important;
    } */

    .updated_buttons button {
        padding: 9px 12px !important;
    }

    .history_drawer .drawer_cards {
        font-size: 11px !important;
    }

    /* .inner_container .justify-content-center {
        gap: 15px !important;
    } */

    .tenderDetails_warpper .innContent {
        overflow: auto !important;
    }

    .innContent table {
        overflow-x: auto !important;
        width: 1000px !important;
    }

    .actionButtons {
        margin-top: 0 !important;
        padding-right: 0 !important;
    }

    .videos-from .container .banner_content h3 {
        font-size: 26px !important;
        margin-bottom: 12px !important;
    }

    .videos-from .container .banner_content h2 {
        font-size: 18px !important;
    }

    .profile-foreground .acItemRound svg,
    .acItem svg {
        width: 18px !important;
    }

    .tenderDetails_warpper .innContent h4 span {
        width: 50% !important;
    }

    .history_drawer .another_table {
        display: grid !important;
        grid-template-columns: 1fr !important;
        gap: 5px !important;
    }

    .planWrapper .slick-track {
        display: block !important;
    }

    .serachWrapper .form-input {
        height: 47px !important;
    }

    .tender_wishlist .serachWrapper {
        gap: 15px !important;
    }

    .formWarpper .socailLogin {
        padding: 15px 22px !important;
        font-size: 13px !important;
    }

    .formWarpper .form__icons {
        display: block !important;
    }

    .forgotPassword {
        font-size: 11px !important;
    }

    .signup_body .formWarpper .inputGrp {
        flex-direction: row !important;
    }

    .signup_body .formWarpper .inputGrp .mb-4 {
        margin-bottom: 0.7rem !important;
        padding-right: 0 !important;
        padding-left: 0 !important;
    }

    .module_header .nav-item {
        padding: 10px !important;
    }

    .navbar_img {
        width: 30px !important;
    }

    .happy .top-counter:last-child {
        grid-column: 1 / span 2 !important;
    }

    .happy .top-counter {
        border-right: 0 !important;
        padding: 0 0 !important;
    }

    .happy .flexCounter {
        display: grid !important;
        grid-template-columns: 1fr 1fr !important;
        gap: 22px !important;
    }

    .videos-from {
        bottom: 10% !important;
    }

    .latestTenders .slick-prev,
    .latestTenders .slick-next {
        width: 40px !important;
        height: 40px !important;
    }

    .latestTenders .slick-next {
        right: 40% !important;
    }

    .latestTenders .slick-prev {
        left: 32% !important;
    }

    .latestTenders .slick-prev,
    .latestTenders .slick-next {
        bottom: -20% !important;
    }

    .latestTenders .slick-prev span,
    .latestTenders .slick-next span {
        line-height: 39px !important;
    }

    .footer-style-two .upper-box {
        padding: 0 !important;
    }

    .footer-style-two .upper-box ul li {
        padding: 20px !important;
        border-right: 0px !important;
    }

    .phone-view-footer {
        width: 50% !important;
    }

    .footer-style-two .lower-box {
        padding: 20px !important;
    }

    .footer-style-two .subscriber-column h3 {
        font-size: 13px !important;
        margin-top: 10px !important;
    }

    .social-column .titleHead {
        margin-bottom: 10px !important;
    }

    .subscribe-form .form-group input {
        font-size: 11px !important;
    }

    .topScoll,
    .downScoll {
        width: 38px !important;
        height: 38px !important;
        z-index: 9999 !important;
    }

    .topScoll svg,
    .downScoll svg {
        width: 19px !important;
        height: 19px !important;
    }

    .MuiTable-root {
        width: 370% !important;
        min-width: 340px !important;
    }

    .table_wrapper .listNumber {
        font-size: 12px !important;
    }

    .css-18nc3u2 .MuiOutlinedInput-root .MuiAutocomplete-input {
        padding: 7.5px 55px 7.5px 5px;
    }

    .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root,
    .css-p0rm37 {
        font-size: 13px !important;
    }

    .date-margin-phn {
        margin-top: 15px !important;
    }

    .filterWrapper .grp_wrap {
        margin-top: 17px !important;
    }

    .bodyModal {
        padding: 20px 12px !important;
    }

    .cName {
        font-size: 12px !important;
        /* height: 36px; */
    }

    .latestTenders .slickItem {
        height: 380px !important;
    }

    .MuiSpeedDialIcon-root svg {
        width: 23px !important;
        height: 23px !important;
    }

    .footer-style-two .upper-box ul li .textHead {
        font-size: 13px !important;
    }

    .footer-style-two .upper-box ul li .address,
    .footer-style-two .upper-box ul li .location {
        font-size: 12px !important;
    }

    .social-icon-three .css-i4bv87-MuiSvgIcon-root,
    .social-icon-three .css-vubbuv {
        font-size: 19px !important;
    }

    .footer-style-two .copyright {
        font-size: 11px !important;
    }

    .profile-wrapper-editbtn {
        flex-direction: column-reverse !important;
    }

    .profile-foreground {
        padding: 30px 0 !important;
        padding-bottom: 110px !important;
    }

    .profileInformation__wrap .lable {
        width: 30% !important;
    }

    .footer-style-two .widgets-section {
        padding: 5px !important;
    }

    .dropdownpayper {
        width: 100% !important;
    }

    .dropdownpayper .MuiSelect-select {
        font-size: 12px !important;
        align-items: center !important;
        display: flex !important;
    }

    .paymentMethods {
        display: none !important;
    }

    .eye_visible {
        width: 19px !important;
    }

    .ProfileEdit_modal .modal-body {
        padding: 20px !important;
    }

    .radioFlex {
        grid-template-columns: 1fr !important;
    }

    .publicationCss {
        margin-top: 20px !important;
    }

    .advanceFilterCss .radioFlex {
        grid-template-columns: 1fr 1fr !important;
    }

    /* .filterWrapper .footer_wrap {
        flex-direction: column !important;
    } */

    .tenders_cont {
        display: grid !important;
        grid-template-columns: 1fr !important;
    }

    .reultItemDetails .actionButtons {
        gap: 15px !important;
        flex-direction: column !important;
    }

    .overview_display {
        flex-direction: row !important;
        flex-wrap: nowrap !important;
    }

    .resultDetails_wrapper .tabWrapper {
        padding: 0 !important;
    }

    .resultDetails_wrapper .nav-pills .nav-link {
        margin-bottom: 0 !important;
    }

    .resultDetails_wrapper .nav-pills .nav-link.active,
    .resultDetails_wrapper .nav-pills .show>.nav-link,
    .resultDetails_wrapper .nav-pills .nav-link {
        max-height: 55px !important;
        text-align: center !important;
    }

    .resultDetails_wrapper .nav-pills .nav-link.active:after {
        position: absolute !important;
        content: "" !important;
        width: 15px !important;
        height: 15px !important;
        background-color: var(--bs-orange) !important;
        right: -6px !important;
        top: 0px !important;
        margin: auto !important;
        bottom: -55px !important;
        left: -13px !important;
        transform: rotate(47deg) !important;
    }

    .loginBanner img {
        display: none !important;
    }

    .login_body {
        grid-template-columns: 1fr !important;
    }

    .login_body_forget {
        grid-template-columns: 1fr !important;
        height: 60vh;
    }

    .subMenu_grid .sb_menu {
        font-size: 11px !important;
    }

    /* ========== */
    .module_header_main .module_header {
        background: white !important;
    }

    .header_side_menu .nav-item {
        border-bottom: 1px solid #e6e6e6 !important;
        border-radius: 0 !important;
        padding: 0 !important;
    }

    .module_header .nav-item>span {
        padding: 25px 20px !important;
    }

    .module_header .mega-menu {
        padding: 0 !important;
        background: white !important;
    }

    .submenu-close {
        padding: 25px 20px !important;
        background: #f1f1f1 !important;
    }

    .subMenu_grid {
        gap: 0;
    }

    .subMenu_grid .sb_menu {
        padding: 20px !important;
        font-size: 13px !important;
    }

    .mobile_view_all span {
        display: flex;
        align-items: center;
        gap: 10px !important;
        color: var(--bs-orange) !important;
    }

    .navbar-light .navbar-nav .nav-link {
        padding: 10px !important;
    }

    .sorting_button .MuiButton-contained {
        width: 100% !important;
    }

    .Support_wrapper {
        padding: 20px !important;
    }

    .Support_wrapper .title {
        font-size: 20px;
    }

    .Support_wrapper p {
        font-size: 13px;
    }

    .fileWapper {
        flex-direction: column;
    }

    .tickets_mainCont {
        flex-direction: column;
        align-items: left;
    }

    .image_support {
        margin-top: 10px;
    }

    .ticketCard .t_name {
        margin-bottom: 10px;
    }

    .tender_wishlist {
        padding: 0 20px 40px;
    }

    .sorting_button .MuiButton-contained {
        width: 100%;
    }

    .checkout_container {
        padding-inline: 20px;
    }

    .checkout_container .ant-card-body {
        padding: 10px !important;
    }

    .my_fav_input {
        width: 100% !important;
    }

    .serachWrapper .form-input {
        width: 100% !important;
    }

    section {
        padding: 35px 0 !important;
    }
}

@media screen and (max-width: 1500px) {
    .Technical-Solutions .inner-right .item-title {
        font-size: 22px !important;
    }

    .Technical-Solutions .inner-right .item-description {
        font-size: 14px !important;
    }

    .all-min-100 {
        min-height: auto !important;
        height: auto !important;
        max-height: 100% !important;
    }

    .hero_banner,
    .hero_banner img {
        height: 100% !important;
    }
}

@media screen and (max-width: 1200px) {
    ul.main-menu>li>span {
        font-size: 12px !important;
    }

    .features-tab .nav-tabs a {
        height: 79px !important;
    }

    .content-carousel .mainButton {
        margin-top: 10px !important;
    }
}

@media (min-width:2000px) {

    .topHeader {
        padding: 16px 30px !important;
    }

    .content-carousel h2.alternate {
        font-size: 70px !important;
    }

    .happy .top-counter h4 {
        font-size: 24px !important;
    }

    .section-title h1 {
        font-size: 32px !important;
    }

    .section-title p {
        font-size: 17px !important;
    }

    .dt-sc-ico-content.type12 h4 {
        font-size: 20px !important;
    }

    /* .mainButton {
        padding: 20px 33px !important;
    } */

    .footer-style-two .widgets-section .about-widget .text {
        font-size: 14px !important;
    }

    .serachWrapper .buttonGrp>div {
        flex: 1 1 !important;
    }

    .bidDate span:last-child {
        font-size: 14px !important;
    }

    .comCard .d-flex>* {
        font-size: 14px !important;
    }

    .comCard .locationTex {
        font-size: 15px !important;
    }

    .comCard .clientName {
        font-size: 17px !important;
    }

    .companyDetails_wrapper .cardFiltHead .text-dark {
        font-size: 20px !important;
    }

    .headTitle {
        font-size: 22px !important;
    }

    .features-tab .nav-tabs a {
        font-size: 17px !important;
    }

    .companyDetails_wrapper .countsNumber {
        font-size: 20px !important;
        line-height: 20px;
    }

    .companyDetails_wrapper .countsNumber span {
        font-size: 16px !important;
    }

    .companyDetails_wrapper .countsNumber span {
        font-size: 18px !important;
    }

}

@media (min-width:992px) and (max-width:1300px) {
    .responsive_size {
        display: flex !important;
    }
}

@media (max-width:1280px) {
    ul.main-menu>li>span {
        font-size: 13px !important;
        color: #505050 !important;
    }

    .all-min-100 {
        min-height: auto !important;
        max-height: auto !important;
        height: auto !important;
    }

    .contact-item {
        font-size: 10px !important;
    }

    .css-1lojo64-MuiTypography-root {
        font-size: 11px !important;
    }

    .happy .top-counter h3 {
        font-size: 25px !important;
    }

    .ebCard_flex .stateItem img {
        width: 80px !important;
    }

    .ebCard_flex .stateItem .d-flex div {
        font-size: 12px !important;
    }

    .latestTenders .slickItem .titleHead {
        font-size: 11px !important;
    }

    .section-title h1 {
        font-size: 17px !important;
    }

    .section-title p {
        font-size: 12px !important;
        width: 100% !important;
    }

    .topCategories p {
        font-size: 11px !important;
        width: 65% !important;
    }

    .from-bg {
        padding: 15px !important;
    }

    .from-bg input,
    .from-bg select {
        font-size: 13px !important;
        height: 45px !important;
    }

    .videos-from .MuiOutlinedInput-input {
        height: 45px !important;
    }

    .from-bg .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root,
    .css-p0rm37 {
        margin-top: -5px !important;
    }

    .videos-from .MuiInputBase-input {
        height: 25px !important;
    }

    .from-bg .mainButton {
        height: 46px !important;
    }

    .header .mainButton {
        padding: 15px 15px !important;
    }

    .latestTenders .slickItem .d-flex .text span,
    .latestTenders .slickItem .d-flex .text,
    .daysToGo,
    .tid {
        font-size: 12px !important;
    }

    .css-ptiqhd-MuiSvgIcon-root {
        width: 15px !important;
        height: 15px !important;
    }

    .footer-style-two .widgets-section h2 {
        font-size: 14px !important;
    }

    .footer-style-two .widgets-section .about-widget .text {
        font-size: 12px !important;
    }

    .footer_wrap {
        justify-content: center !important;
        align-items: center !important;
    }

    .footer-style-two .nav-list li {
        font-size: 12px !important;
    }

    ul.main-menu>li>.dropdown-menu,
    .main-menu-right .dropdown-menu {
        min-width: auto !important;
        overflow-y: auto !important;
        height: 100% !important;
    }

    .mianMenu_full span {
        gap: 1px !important;
    }

    .mianMenu_full {
        font-size: 12px !important;
    }

    .dropdown-item {
        font-size: 10px !important;
    }

    .mega-menu .menuHeading {
        font-size: 12px !important;
    }

    .loginBanner img {
        height: auto !important;
    }

    .signup_body .loginBanner img {
        height: 124vh !important;
    }

    section {
        /* padding: 70px 0 !important; */
    }

    .topCategories .mainButton {
        margin-top: 50px !important;
    }

    .mainButton {
        padding: 10px 20px !important;
        font-size: 12px !important;
    }

    .css-1ndpvdd-MuiTableCell-root,
    .css-1gjqzp5-MuiTableCell-root {
        font-size: 0.7rem !important;
    }

    .table_wrapper .text-contain-tender,
    .table_wrapper .nameText,
    .table_wrapper .locationTe,
    .tenderDetails_warpper p,
    .tenderDetails_warpper h4,
    .ebCard_flex .stateItem,
    .viewMore_button,
    ul.breadcrumb li,
    .planWrapper .planBody .pbItem p,
    .form-input {
        font-size: 11px !important;
    }

    .table_wrapper .locationTe svg {
        font-size: 16px !important;
    }

    .search_tags .tagItem .tTex,
    .css-1gjqzp5-MuiTableCell-root,
    .days_left,
    .table_wrapper .text-primary,
    .hashTag {
        font-size: 10px !important;
    }

    .white-tab .nav-item a,
    .planWrapper .planHead h1 {
        font-size: 15px !important;
    }

    .tenderList_wrapper .filterWarp .card-title {
        font-size: 18px !important;
    }

    .tenderList_wrapper .filterWarp .accordion-button,
    .tenderDetails_warpper .daysLeft h1,
    .tenderDetails_warpper .card-title h1,
    .tenderDetails_warpper h5,
    .profileInformation__wrap .lable,
    .modal-content .labelText {
        font-size: 12px !important;
    }

    /* .tc-white {
        font-size: 20px !important;
    } */

    .tenderDetails_warpper .client__name {
        font-size: 18px;
    }

    .locationTex {
        font-size: 12px !important;
        gap: 0 !important;
    }

    .navbars_tender {
        padding: 10px 0 !important;
    }

    .css-1h9z7r5-MuiButtonBase-root-MuiTab-root>div,
    .tenderDetails_warpper .css-1q2h7u5>div {
        font-size: 12px !important;
        padding: 10px 13px !important;
    }

    .states_margins {
        margin-top: 0 !important;
    }

    .companyList_wrapper .flexGrid {
        margin-top: 0px !important;
    }

    .planWrapper .planHead .priceText {
        font-size: 25px !important;
    }

    .planWrapper .planBody .pbItem .title {
        font-size: 14px !important;
        margin-bottom: 11px !important;
    }

    .m-image {
        min-height: 500px !important;
    }

    .planWrapper .planFooter .mainButton,
    .profile_wrapper .profile-foreground .ghostButton {
        padding: 10px 19px !important;
    }

    .avatar-lg {
        width: 70px !important;
        height: 70px !important;
    }

    .detContent .nameUser,
    .css-1hv8oq8-MuiStepLabel-label.Mui-active {
        font-weight: 500 !important;
        font-size: 15px !important;
    }

    .profileInformation__wrap .text-muted {
        font-size: 12px !important;
        font-weight: 500 !important;
    }

    .dropdownpayper .form-input {
        height: 31px !important;
    }

    .profile_finall {
        padding: 9px !important;
    }

    .ebCard_flex .stateItem .eb__content div,
    .ebCard_flex .stateItem .eb__content .tenderCount {
        font-size: 13px !important;
    }

}

@media only screen and (min-width:500px) and (max-width: 1050px) {

    .content-carousel h2.alternate {
        font-size: 28px !important;
    }

    .happy .top-counter h3 {
        font-size: 23px !important;
    }

    .section-title h1 {
        font-size: 22px !important;
    }

    .section-title p {
        font-size: 12px !important;
    }

    .videos-from {
        bottom: 7% !important;
    }
}

@media only screen and (min-width: 1050px) and (max-width: 1280px) {
    .latestTenders .slick-prev {
        left: -30px !important;
    }

    .latestTenders .slick-next {
        right: -25px !important;
    }

    .content-carousel h2.alternate {
        font-size: 30px !important;
    }

    .happy .top-counter h3 {
        font-size: 28px !important;
    }

    .social-icon-three .css-i4bv87-MuiSvgIcon-root,
    .social-icon-three .css-vubbuv {
        font-size: 16px !important;
    }

    .responsive_size .css-i4bv87-MuiSvgIcon-root {
        font-size: 14px !important;
    }

    .contact-item {
        font-size: 12px !important;
    }

    .css-1lojo64-MuiTypography-root {
        font-size: 12px !important;
    }

}

@media only screen and (min-width: 1250px) and (max-width: 1480px) {
    .css-1lojo64-MuiTypography-root {
        font-size: 14px !important;
    }

    .latestTenders .slick-prev {
        left: -40px !important;
    }

    .latestTenders .slick-next {
        right: -45px !important;
    }
}


@media only screen and (min-device-pixel-ratio: 2),
only screen and (min-resolution: 192dpi) {
    /* .videos-from {
        bottom: 7% !important;
    } */
}

@media (max-width:1074px) {
    .heding__wrap strong {
        font-size: 13px !important;
    }

    .ebCard_flex .stateItem .eb__content div {
        font-size: 12px !important;
    }

    .ebCard_flex .stateItem .eb__content .tenderCount {
        font-size: 12px !important;
    }

    .why_tender img {
        top: 0 !important;
    }
}


@media only screen and (max-width: 768px) {
    .container_client {
        margin-top: 15% !important;
        width: 100% !important;
    }

    .main_panel_client {
        margin: auto !important;
        width: 96% !important;
    }

    .client_image {
        display: flex !important;
        flex-direction: column !important;
        justify-content: center !important;
        align-items: center !important;
    }

    .client_image img {
        height: 88px !important;
        width: 97% !important;
        margin: 22px !important;
    }

    .all-min-100 {
        min-height: 120vh !important;
        max-height: 120vh !important;
        height: 100vh !important;
    }

    .why_tender .list__points {
        display: grid !important;
        grid-template-columns: 1fr !important;
        gap: 10px !important;
    }

    .why_tender .list__points li:first-child {
        padding: 40px !important;
    }

    .topCategories .section-title {
        flex-direction: column !important;
        text-align: center !important;
        gap: 20px !important;
    }
}

@media only screen and (max-width: 600px) {
    .container_client {
        margin-top: 20% !important;
        width: 100% !important;
    }

    .main_panel_client {
        margin: auto !important;
        width: 96% !important;
    }

    .client_image {
        display: flex !important;
        flex-direction: column !important;
        justify-content: center !important;
        align-items: center !important;
    }

    .main_panel_client_content {
        border: 2px solid #2d2b57 !important;
        margin-left: 9px !important;
    }

    .fixed_independenceDay .indepence_video {
        display: none;
    }

    .fixed_independenceDay {
        display: none;
    }

    .fixed_independenceDay_phone {
        position: sticky;
        top: 15px;
    }

    .fixed_independenceDay_phone .indepence_phnVideo {
        display: block;
        height: 100%;
        width: 100%;
    }
}

@media only screen and (max-width: 390px) {
    .container_client {
        margin-top: 36% !important;
        width: 100% !important;
    }

    .fixed_independenceDay {
        margin-top: 0px;
        top: 55px;
        height: calc(40vh - 55px);
    }

    .client_image {
        display: flex !important;
        flex-direction: column !important;
        justify-content: center !important;
        align-items: center !important;
    }

    .client_image img {
        height: 82px !important;
        width: 76% !important;
        margin: 22px !important;
    }

    .profile_fullname {
        display: none !important;
    }
}

@media (min-width:650px) and (max-width:1050px) {
    .companyDetails_wrapper .endFlex {
        flex-direction: column !important;
        align-items: start !important;
        gap: 10px !important;
    }

    .fixed_independenceDay {
        margin-top: 0px;
        top: 55px;
        height: calc(69vh - 55px);
    }

    .company_details .card-title {
        display: grid !important;
        flex-direction: column !important;
        align-items: start !important;
    }
}

@media screen and (max-width:717px) {
    .companyDetails_wrapper .cardFiltHead {
        flex-direction: column !important;
    }

    .companyDetails_wrapper .endFlex {
        flex-direction: column !important;
    }

    .company-details-tab {
        padding-inline: 20px !important;
    }

    .companyDetails_wrapper .filText {
        margin-bottom: 10px !important;
    }

    .nav-justified {
        display: contents !important;
    }

    .features-tab .nav-tabs a.active:after {
        display: none !important;
    }

    .heading_flex {
        flex-direction: column !important;
    }
}

@media only screen and (min-width: 768px) and (max-width: 1500px) {
    .company_radioflex {
        grid-template-columns: repeat(1, 1fr) !important;
    }
}

@media (min-width:1500px) and (max-width:2650px) {

    .tenderInner_wrapper .ebCard_flex {
        grid-template-columns: repeat(4, 1fr) !important;
    }
}

@media screen and (max-width:997px) {
    .col-md-4.search-bar-buttons-section {
        display: grid !important;
        grid-template-columns: 1fr 1fr !important;
        gap: 0 !important;
    }

    .btn.btn-one.search {
        margin-bottom: 5px !important;
    }

    .btn.btn-outline-primary.advanceSearch {
        margin-left: 0 !important;
    }
}

@media screen and (max-width:770px) {
    .col-md-4.search-bar-buttons-section {
        display: flex !important;
        gap: 10px !important;
    }

    .btn.btn-outline-primary.advanceSearch {
        margin-left: 0.5em !important;
        width: 100% !important;
    }

    .btn.btn-one.search {
        margin-bottom: 0 !important;
        width: 100% !important;
    }
}

@media screen and (max-width:650px) {
    /* .css-70qvj9 {
        flex-direction: column !important;
        gap: 10px !important;
    } */

    .rating_strts {
        flex-direction: column !important;
    }

    .feedbackWrapper .feedback_cards_cont {
        gap: 25px !important;
    }

    .feedbackimg {
        width: 250px !important;
    }

    .companyList_wrapper .flexGrid {
        display: contents !important;
    }

    .listActionButtons {
        flex-direction: column !important;
        gap: 10px !important;
    }

    .comCard {
        padding-right: 15px !important;
    }

    .btnLock {
        right: 5px !important;
        top: 12px !important;
    }

    .companyList_wrapper .section-title h1 {
        font-size: 13px !important;
    }

    .comCard .d-flex {
        flex-direction: column !important;
        gap: 15px !important;
    }

    .our-Values .rowFlex {
        flex-direction: column !important;
    }

    .signUpLink {
        margin: 0 !important;
    }

    .Constructiomimg {
        width: 250px !important;
    }

    .tender_wishlist .serachWrapper .inner_flex {
        grid-template-columns: 1fr !important;
    }

    .wishlist_select {
        width: 230px !important;
    }

    .download_img {
        width: 250px !important;
    }

    .Media_heading p {
        line-height: 20px !important;
    }

    .download_btn {
        flex-direction: column !important;
    }

    .tenderDetails_warpper .another_table {
        grid-template-columns: 1fr !important;
    }

    .tenderDetails_warpper .innContent h4 {
        gap: 10px !important;
    }

    .latestTenders {
        padding-bottom: 44px !important;
    }

    .itemDocument {
        flex-direction: column !important;
        align-items: start !important
    }

    .innContent table {
        overflow-x: auto !important;
    }

    .innContent table th {
        width: 71px !important;
    }

    .innContent table td,
    .innContent table th {
        width: 71px !important;
    }

    .profile-foreground .bg-gradient-anim img {
        display: none !important;
    }

    .css-1ssy6aq-MuiDrawer-docked .MuiDrawer-paper {
        /* width: 340px !important; */
    }

    .topCard {
        flex-direction: column !important;
        align-items: start !important;
    }

    .stats_wrapper .statCards {
        grid-template-columns: 1fr !important;
    }

    .stats_wrapper .statCards .comCard {
        border-right: 0 !important;
    }

    .wrong_msg {
        font-size: 16px !important;
    }

    .ebCard_flex {
        grid-template-columns: 1fr !important;
    }

    .planWrapper .slick-prev {
        left: -40px !important;
    }

    .planWrapper .slick-prev,
    .planWrapper .slick-next {
        bottom: 49% !important;
    }

    .planWrapper .slick-next {
        right: -25px !important;
    }

    .tenders_cont {
        display: grid !important;
        grid-template-columns: 1fr !important;
    }

    .resultDetails_wrapper .tabWrapper {
        padding: 0 0px 0 0px !important;
    }

    .overview_display {
        flex-direction: column !important;
        flex-wrap: wrap !important;
    }

    .list-style-three li {
        margin-right: 0 !important;
    }

    .category_listed {
        display: grid;
        grid-template-columns: repeat(1fr);
        border-bottom: 1px solid #dee2e6 !important;
    }

    .section-title p {
        width: 100% !important;
    }

    .footer-style-two .upper-box ul {
        display: block !important;
    }

    .contact-item {
        font-size: 11px !important;
        color: #5f6168 !important;
        font-weight: 400 !important;
        display: flex !important;
        flex-direction: column !important;
    }

    .contact-item a {
        color: var(--primary) !important;
    }

    .footer-style-two .widgets-section .footer-widget {
        padding-right: 0px !important;
    }

    .features-tab .innerTabContent {
        padding-right: 0px !important;
    }

    .Technical-Solutions .rowFlex {
        display: block !important;
    }

    .t-Solutions {
        display: grid !important;
    }

    .Technical-Solutions .slick-prev {
        left: 10px !important;
    }

    .Technical-Solutions .slick-prev,
    .Technical-Solutions .slick-next {
        top: 5% !important;
    }

    .Technical-Solutions .slick-next {
        right: 10px !important;
    }

    .Technical-Solutions .rowFlex {
        height: 825px !important;
    }

    .nav-justified {
        height: 110px !important;
    }

    .Technical-Solutions .slick-slide img {
        display: none !important;
    }

    .content-carousel h2.alternate {
        font-size: 35px !important;
    }

    .content-carousel {
        margin-top: 0 !important;
    }

    .search_tender {
        font-size: 24px !important;
    }

    .country_name {
        font-size: 1rem !important;
    }

    .btn-one {
        padding: 14px 0.2rem !important;
    }

    .Technical-Solutions .slick-prev {
        left: 69.7% !important;
    }

    .password_change {
        flex-direction: column !important;
    }

    div.pagination .page-item.disabled .page-link {
        font-size: 12px !important;
    }


    #sidebar {
        position: absolute !important;
        top: 0px !important;
        left: -100% !important;
        height: 100% !important;
        width: 312px !important;
        transition: all 300ms linear !important;
        z-index: 1000 !important;
    }

    #sidebar.active {
        left: 0 !important;
    }

    .company_sidebar_cont .flexGrid #sidebar {
        top: auto !important;
        overflow: auto !important;
        bottom: -97px !important;
    }

    .serachWrapper {
        flex-direction: column !important;
    }

    .asideWrapper {
        padding: 30px 15px 30px 15px !important;
    }
}

@media only screen and (min-width:457px) and (max-width:991px) {

    .complete-profile {
        margin-top: 45px !important;
    }

}

@media (min-width:712px) and (max-width:1180px) {
    .latestTenders .slick-next {
        right: 45% !important;
    }

    .latestTenders .slick-prev,
    .latestTenders .slick-next {
        bottom: -19% !important;
    }

    .latestTenders .slick-prev {
        left: 40% !important;
    }

    .footer-style-two .subscriber-column h3,
    .subscribe-form .theme-btn {
        font-size: 12px !important;
    }

    .subscribe-form .form-group input {
        font-size: 13px !important;
    }

    .social-icon-three .css-i4bv87-MuiSvgIcon-root,
    .social-icon-three .css-vubbuv {
        font-size: 19px !important;
        color: #fff !important;
    }

    .planWrapper .slick-next {
        right: -40px !important;
    }

    .planWrapper .slick-prev {
        left: -37px !important;
    }
}

@media screen and (max-width:767px) {
    .tenders_cont {
        display: grid !important;
        grid-template-columns: 1fr !important;
    }

    .resultDetails_wrapper .tabWrapper {
        padding: 0 0px 0 0px !important;
    }

    .overview_display {
        flex-direction: column !important;
        flex-wrap: wrap !important;
    }

    .resultDetails_wrapper .nav-pills .nav-link.active:after {
        display: none !important;
    }

    .containerWrapper .section-title h1 {
        margin-top: 20px !important;
    }

    .features-tab .nav-tabs a {
        height: 40px !important;
        font-size: 13px !important;
    }

    .settingWapper .filterWrapper .col-md-4 {
        width: 100% !important;
    }

    .activePlan_wrapper .section-title h2 {
        font-size: 18px !important;
    }

    .basicPlanCard .card-title {
        font-size: 14px !important;
    }
}

@media (min-width:650px) and (max-width:950px) {
    .hashTag_flex {
        flex-direction: column !important;
        align-items: flex-start;
    }

    .hashTag {
        border-radius: 10px !important;
    }

    .table_wrapper .listNumber {
        width: 9% !important;
        min-width: 12% !important;
        padding-right: 0 !important;
    }

    .serachWrapper .d-flex {
        justify-content: space-between;
    }

    .companyList_wrapper .serachWrapper input {
        width: 84% !important;
    }

    .asideWrapper {
        width: 100% !important;
    }
}

/* mansi */
@media (max-width:350px) {
    .otpBox * input {
        width: 35px !important;
        height: 35px !important;
    }

    .signUpLink,
    .forgotPassword,
    .errore_poppp,
    .happy .top-counter h4,
    .ebCard_flex .stateItem .eb__content div {
        font-size: 11px !important;
    }

    .subscribe-form .form-group .input-group {
        flex: 3 1 !important;
    }

    .form__input {
        height: 45px !important;
        font-size: 12px !important;
    }

    .css-i4bv87-MuiSvgIcon-root {
        width: 16px !important;
        height: 16px !important;
    }

    .all-min-100 {
        max-height: 140vh !important;
        height: 140vh !important;
    }

    .videos-from .container {
        display: flex !important;
        flex-direction: column !important;
        gap: 10px !important;
    }

    .videos-from .container .banner_content h2 {
        font-size: 1.7rem !important;
    }

    .videos-from .container .from-bg h4 {
        font-size: 1rem !important;
    }

    .why_tender {
        padding-inline: 30px !important;
    }

    .why_tender .list__points {
        display: flex !important;
        flex-direction: column !important;
        gap: 15px !important;
    }

    .why_tender img {
        display: none !important;
    }

    .why_tender .list__points li {
        padding: 15px !important;
    }

    .why_tender .list__points li h5 {
        font-size: 15px !important;
    }

    .topCategories {
        padding-inline: 40px !important;
    }

    .ebCard_flex {
        display: flex !important;
        flex-direction: column !important;
    }

    .topCategories .section-title {
        flex-direction: column !important;
        gap: 20px !important;
    }

    .heding__wrap strong {
        font-size: 12px !important;
        text-align: center !important;
    }

    .topCategories p {
        width: 100% !important;
        text-align: center !important;
    }

    .latestTenders {
        padding-inline: 0 !important;
    }

    .latestTenders .heding__wrap {
        flex-direction: column !important;
    }

    .latestTenders .mainButton {
        display: none !important;
    }

    .latestTenders .slickItem .client__det {
        font-size: 11px !important;
        margin-bottom: 13px !important;
    }

    .latestTenders .slickItem .client__det .span {
        font-size: 11px !important;
        margin-bottom: 5px !important;
    }

    .latestTenders .slickItem {
        height: 380px !important;
    }

    .latestTenders .slick-prev {
        left: 31% !important;
    }

    .slideWrapper {
        margin-top: 25px !important;
    }

    .latestTenders .slick-prev,
    .latestTenders .slick-next {
        bottom: -25% !important;
    }

    .why_tender .list__points li {
        gap: 14px !important;
    }

    .why_tender .list__points li svg {
        width: 20px !important;
    }
}

@media(max-width:767px) {
    .inner_wrapper {
        overflow: auto;
    }

    .serachWrapper {
        width: 900px;
    }
}

@media (max-width:769px) {
    .all-min-100 {
        max-height: 125vh !important;
        height: 125vh !important;
    }

    .serachWrapper .search_icon .mainButton {
        padding: 15px 20px !important;
    }

    .videos-from .container {
        display: flex !important;
        flex-direction: column !important;
        gap: 25px !important;
    }

    .videos-from .container .banner_content h2 {
        font-size: 1.7rem !important;
    }

    .videos-from .container .from-bg h4 {
        font-size: 1rem !important;
    }

    .happy .top-counter h4 {
        font-size: 12px !important;
    }

    .why_tender {
        padding-inline: 30px !important;
    }

    .why_tender .list__points {
        display: flex !important;
        flex-direction: column !important;
        gap: 15px !important;
    }

    .why_tender img {
        display: none !important;
    }

    .why_tender .list__points li {
        padding: 15px !important;
    }

    .why_tender .list__points li h5 {
        font-size: 15px !important;
    }

    .topCategories {
        padding-inline: 40px !important;
    }

    .ebCard_flex {
        display: flex !important;
        flex-direction: column !important;
    }

    .topCategories .section-title {
        flex-direction: column !important;
        gap: 20px !important;
    }

    .heding__wrap strong {
        font-size: 12px !important;
        text-align: center !important;
    }

    .topCategories p {
        width: 100% !important;
        text-align: center !important;
    }

    .latestTenders {
        padding-inline: 0 !important;
    }

    .latestTenders .heding__wrap {
        flex-direction: column !important;
    }

    .latestTenders .mainButton {
        display: none !important;
    }

    .latestTenders .slickItem .client__det {
        font-size: 11px !important;
        margin-bottom: 13px !important;
    }

    .latestTenders .slickItem .client__det .span {
        font-size: 11px !important;
        margin-bottom: 5px !important;
    }

    /* .latestTenders .slickItem {
        height: 380px !important;
    } */

    /* .latestTenders .slick-prev {
        left: 31% !important;
    } */

    .slideWrapper {
        margin-top: 25px !important;
    }

    /* 
    .latestTenders .slick-prev,
    .latestTenders .slick-next {
        bottom: -23%;
    } */

    .why_tender .list__points li {
        gap: 14px !important;
    }

    .why_tender .list__points li svg {
        width: 20px !important;
    }

    .serachWrapper .d-flex {
        width: 100% !important;
    }

    .inner_flex {
        gap: 12px !important;
        /* flex-direction: column !important; */
    }

    .client--name {
        font-size: 10px !important;
    }

    .css-1ndpvdd-MuiTableCell-root,
    .css-1ygcj2i-MuiTableCell-root {
        font-size: 0.7rem !important;
    }

    .tenderList_wrapper .asideWrapper {
        padding: 0px !important;
    }

    /* ul.breadcrumb {
        margin-top: 95px;
    } */

    .table_wrapper .value_ruppe {
        font-size: 11px !important;
    }



    .serachWrapper .mainButton {
        font-size: 12px !important;
    }
}

.currencyIMg {
    width: 20px !important;
}

@media (max-width:385px) {
    .innerMenu .navbar-brand img {
        width: 100px !important;
    }

    .navbar-light .navbar-nav .nav-link {
        padding: 4px !important;
    }

    ul.main-menu>li>span {
        font-size: 10px !important;
    }

    .why_tender {
        padding-inline: 5px !important;
    }

    section {
        padding-top: 40px !important;
    }

    .topCategories {
        padding-inline: 15px !important;
    }

    .latestTenders .slickItem {
        height: 350px !important;
    }

    .latestTenders .slick-prev,
    .latestTenders .slick-next {
        bottom: -18% !important;
    }

    ul.breadcrumb {
        padding: 12px 14px !important;
    }

    .formWarpper .socailLogin {
        font-size: 10px !important;
    }

    .model_login_popup .signUpLink {
        font-size: 6px !important;
    }

    .formWarpper .orItem .iier {
        font-size: 9px !important;
    }

    .planWrapper {
        margin-top: 0 !important;
        padding: 10px !important;
    }

    .planWrapper .section-title {
        margin-bottom: 20px !important;
    }

    .ContactWrapper .innerContent p {
        font-size: 10px !important;
        margin-bottom: 12px !important;
    }

    .about-content-two h1 {
        font-size: 16px !important;
    }

    .contactInformatin .about-content-two h1 {
        font-size: 15px !important;
    }

    .contact_para {
        font-size: 9px !important;
    }

    .contact_box {
        padding: 17px !important;
    }

    .itmBlock h6 {
        font-size: 12px !important;
    }

    .contactInformatin .faq-link-cls {
        font-size: 9px !important;
    }

    .planWrapper .slick-next {
        width: 40px !important;
        height: 40px !important;
        right: 0 !important;
        bottom: 50%;
        line-height: 38px !important;
    }

    .planWrapper .slick-prev {
        left: -2px !important;
        width: 40px !important;
        height: 40px !important;
        bottom: 50%;
        line-height: 38px !important;
    }

    .pay_what_wrapper h3 {
        font-size: calc(1rem + -0.6vw) !important;
    }

    .pay_what_wrapper h6 {
        font-size: 9px !important;
    }

    .footer-style-two .upper-box ul li .address,
    .footer-style-two .upper-box ul li .location {
        font-size: 10px !important;
    }

    .videos-from .container .banner_content h3 {
        font-size: 22px !important;
    }

    .videos-from .container .banner_content h2 {
        font-size: 13px !important;
    }

    .videos-from .container .from-bg h4 {
        font-size: 0.8rem !important;
    }

    .from-bg input,
    .from-bg select {
        font-size: 10px !important;
    }

    .latestTenders .slickItem .d-flex .text span,
    .latestTenders .slickItem .d-flex .text,
    .daysToGo,
    .tid {
        font-size: 7px !important;
    }

    .subject {
        padding: 5px !important;
    }

    .MuiFab-primary {
        min-height: 30px !important;
        width: 30px !important;
        height: 30px !important;
        line-height: 2 !important;
    }

    .breadcrumb {
        top: 60px !important;
    }

    .tenderDetails_warpper b {
        font-size: 10px !important;
    }

    .subscribe-form .form-group .input-group {
        flex: 3 1 !important;
    }

    .bodyModal span,
    button {
        font-size: 10px !important;
    }

    .profile_fullname {
        display: none !important;
    }

    .header .innerMenu .navbar-brand {
        padding: 8px 10px;
        top: 0;
    }

    .css-18sg6k4-MuiPaper-root-MuiDrawer-paper,
    .css-1ciwpa8 {
        width: 100% !important;
    }

    .css-18sg6k4-MuiPaper-root-MuiDrawer-paper,
    .css-1ciwpa8 {
        width: 100%;
    }

    .css-ahj2mt-MuiTypography-root,
    .css-9l3uo3 {
        font-size: 12px;
    }

    .phone-advance-button .buttonGrp {
        flex-wrap: wrap;
    }

    ul.breadcrumb {
        margin-top: 57px !important;
    }

    /* .sorting_button .MuiButton-contained {
        width: 100% !important;
    } */
}

@media (min-width:700px) and (max-width:800px) {
    .ebCard_flex {
        display: grid !important;
        grid-template-columns: 1fr 1fr !important;
    }

    .latestTenders .slick-prev,
    .latestTenders .slick-next {
        display: none !important;
    }
}